import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.component.html',
})
export class SessionExpiredModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private userService: UserService) {}

  ngOnInit(): void {}

  confirm(): void {
    this.activeModal.close();
    this.userService.signOut();
  }
}
