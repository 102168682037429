import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LoginInputTypes, LoginFormView } from '../../core/models/login-step.model';
import { LoginSteps } from 'src/app/core/enums/login-steps.enum';

@Pipe({
  name: 'isTheStepValid',
})
export class IsTheStepValidPipe implements PipeTransform {
  transform(
    button,
    loginForm: UntypedFormGroup,
    currentStep: LoginFormView,
    restoreCode: string,
    confirmCode: string
  ): boolean {
    if (!button.needsValidation) {
      return true;
    }
    switch (currentStep.inputType) {
      case LoginInputTypes.CODE:
        return (
          loginForm.get(LoginInputTypes.PASSWORD).valid &&
          (restoreCode?.length === 6 || confirmCode?.length === 6)
        );
      case LoginInputTypes.PASSWORD:
        if (currentStep.stepName === LoginSteps.PASSWORD) {
          return (
            loginForm.get(LoginInputTypes.PASSWORD).valid &&
            loginForm.get(LoginInputTypes.EMAIL).valid
          );
        }
        return loginForm.get(LoginInputTypes.PASSWORD).valid;
      default:
        return loginForm.get(currentStep.stepName).valid;
    }
  }
}
