<div class="d-flex align-items-center w-100">
    <td class="x-icon" (click)="hideConfirmDeleteProduct()">
        <svg>
            <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-x" />
        </svg>
    </td>
    <button class="delete-buttom" (click)="removeProductFromCart(product)">
        {{  'NEW_ORDER.ORDER_DETAIL.DELET_PRODUCT' | translate }}
    </button>
</div>
