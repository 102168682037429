import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface ITitlePage {
  text: string;
  icon: string;
  showIcon?:boolean;
}

@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.scss'],
})
export class TitlePageComponent {
  @Input() config: ITitlePage;
  @Output() clickEvent = new EventEmitter();
}
