import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-repeat-order-modal',
  templateUrl: './repeat-order-modal.component.html',
  styleUrls: ['./repeat-order-modal.component.scss'],
})
export class RepeatOrderModalComponent implements OnInit {
  @Output() modalResponse: EventEmitter<any> = new EventEmitter();

  private subscriptions = new Subscription();
  readonly LANG_ROOT = 'NEW_ORDER.RIGHT_SIDEBAR.';
  continueEnabled: boolean;

  constructor(public activeModal: NgbActiveModal, private cartService: CartService, private store: Store<{ cart: Cart }>) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.continueEnabled = this.cartService.shouldContinueButtonDisable();
      })
    );
  }

  ngOnInit(): void {}

  confirm(): void {
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.close(false);
  }
}
