<label [for]="inputId">{{ inputLabel }}</label>
<div class="d-flex">
  <input
    [formControl]="control"
    [class]="customClass"
    [placeholder]="placeholder"
    [type]="showPassword || showPasswordConfirm ? 'text' : 'password'"
    [id]="inputId"
    [name]="inputId"
    [autocomplete]="autocomplete"
    (focus)="onFocus($event)"
    (blur)="onBlur()"
    maxLength="16"
    minLength="8"
  />
  <span *ngIf="inputId === 'password' || inputId === 'passwordConfirm'">
    <svg
      *ngIf="!showPassword || !showPasswordConfirm"
      [ngClass]="{ error: control.touched && !control.valid }"
      (click)="switchShowPassword()"
      [ngbTooltip]="tooltipShow"
      [disableTooltip]="tooltipShow | isMobile"
      class="toggle-pwd"
      id="show-pwd"
      placement="top"
      tooltipClass="custom-tooltip"
      #svgShowPassword
    >
      <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-eye" />
    </svg>
    <svg
      *ngIf="showPassword || showPasswordConfirm"
      [ngClass]="{ error: control.touched && !control.valid }"
      (click)="switchShowPassword()"
      [ngbTooltip]="tooltipHide"
      [disableTooltip]="tooltipHide | isMobile"
      class="toggle-pwd"
      id="hide-pwd"
      placement="top"
      tooltipClass="custom-tooltip"
      #svgHidePassword
    >
      <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-eye-off" />
    </svg>
  </span>
</div>
