import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-ka-notification-cart',
  templateUrl: './ka-notification-cart.component.html',
  styleUrls: ['./ka-notification-cart.component.scss'],
})
export class KaNotificationCartComponent implements OnInit {
  private subscriptions = new Subscription();
  selectedClient: Client;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{ client: Client }>,
    private cartService: CartService,
  ) {}

  ngOnInit(): void {
    this.store
      .select('client')
      .subscribe((client) => (this.selectedClient = client));
  }

  goPortfolio(isDraft: boolean): void {
    const myOrdersRoute = this.router.url.includes('mcc')
      ? '/mcc/2'
      : '/main/nuevo-pedido/2';
    this.router.navigate([myOrdersRoute], { queryParams: { draft: isDraft } });
  }
}
