<div *ngIf="(client.hasLockOrder|| client.data?.hasCreditLock) && !maintenance && !blockPPD" class="block-message d-flex flex-column align-items-center justify-content-center">
  <fa-icon class="mb-2" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
  <p class="strong">{{ 'BLOCK_USER.TITLE' | translate }}</p>
  <p class="text">{{ 'BLOCK_USER.SUBTITLE_1' | translate }}</p>
</div>

<div *ngIf="maintenance" class="block-message d-flex flex-column align-items-center justify-content-center">
  <fa-icon class="mb-2" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
  <p class="strong">{{ 'MAINTENANCE.MODAL.TITLE' | translate }}</p>
  <p class="text strong mt-2">{{ 'MAINTENANCE.MODAL.SUBTITLE' | translate }}</p>
  <p class="text mt-2">{{ 'MAINTENANCE.MODAL.ACTION' | translate}}</p>
  <p class="whatsapp-description mt-3"><a href="https://wa.me/{{ 'MAINTENANCE.MODAL.WHATSAPP' | translate }}" target="_blank">{{ 'MAINTENANCE.MODAL.WHATSAPP' | translate }}</a></p>
</div>

<div *ngIf="blockPPD && !maintenance" class="block-message d-flex flex-column align-items-center justify-content-center">
  <p class="text">
    {{ 'PPD_MODAL.TEXT.PART_1' | translate }}
    <span class="mid-strong">{{ 'PPD_MODAL.TEXT.PART_2' | translate }}</span> 
    {{ 'PPD_MODAL.TEXT.PART_3' | translate }}
    <a href="https://wa.me/{{ 'PPD_MODAL.WHATSAPP' | translate }}" target="_blank">{{ 'PPD_MODAL.WHATSAPP' | translate }}</a>
  </p>
</div>