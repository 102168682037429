import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-text-skeleton',
  template: `<div #textSkeletonTemplate class="skeleton-text"></div>`,
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements AfterViewInit {
  @ViewChild('textSkeletonTemplate') textSkeleton: ElementRef<HTMLDivElement>;

  constructor(private render: Renderer2) {}

  ngAfterViewInit(): void {
    this.addRandomWith();
  }

  private addRandomWith(): void {
    const randomNumber = Math.random();
    const finalNumer = randomNumber * 90 + 10;
    this.render.setStyle(
      this.textSkeleton.nativeElement,
      'width',
      `${finalNumer}%`,
    );
  }
}
