import { Injectable } from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ApiService} from "./api.service";
import {EndpointsCodes} from "../enums/endpoints-codes.enum";
import {Store} from "@ngrx/store";
import {Client} from "../models/client.model";
import { HttpClient } from '@angular/common/http'
import { PaymentMethods } from "../models/payment-method.model";
import {UserInfo} from "../models/user-info.model";

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  private subscriptions = new Subscription();
  client: Client;

  constructor(private apiSrv: ApiService, private httpClient: HttpClient, private store: Store<{ user: UserInfo, client: Client }>) {
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.client = client)));
  }

  getPaymentMethod(): Observable<PaymentMethods> {
    return new Observable((obs) => {
      this.apiSrv.get(`clients/${this.client.clientId}/paymentmethod`, EndpointsCodes.GET_PAYMENT_METHOD, { showError: false }).subscribe(
          (res) => obs.next(res?.data),
          (err) => obs.error(err),
          () => obs.complete()
      );
    });
  }
}
