import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmptyObj',
})
export class IsEmptyObjPipe implements PipeTransform {
  transform(obj: any): unknown {
    return JSON.stringify(obj) === '{}';
  }
}
