type OrderByDirection = 'asc' | 'desc';

export abstract class AppUtils {

  static groupBy(array: any[], key: string): { [key: string]: any[] } {
    return array.reduce((result, item) => {
      const keyValue = item[key] as any;

      if (!result[keyValue]) {
        result[keyValue] = [];
      }

      result[keyValue].push(item);
      return result;
    }, {});
  }

  static groupProductsBySector(array: any[], keys: string): { [key: string]: any[] } {
    const keysArray = keys.split('.');
    return array.reduce((result, item) => {
      const nestedObject = keysArray.reduce((obj, key) => obj && obj[key], item);
      const keyToAdd = nestedObject !== undefined ? nestedObject : '';
      if (!result[keyToAdd]) {
        result[keyToAdd] = [];
      }
      result[keyToAdd].push(item);
      return result;
    }, {});
  }

  static orderBy<T>(array: T[], iteratees: ((item: T) => any)[], orders: OrderByDirection[]): T[] {
    return array.slice().sort((a, b) => {
      for (let i = 0; i < iteratees.length; i++) {
        const iteratee = iteratees[i];
        const order = orders[i] === 'desc' ? -1 : 1;

        const aValue = iteratee(a);
        const bValue = iteratee(b);

        if (aValue < bValue) return -1 * order;
        if (aValue > bValue) return 1 * order;
      }

      return 0;
    });
  }

  static mapValues(obj: any, mapper: (value: any) => any): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        result[key] = mapper(obj[key]);
      }
    }

    return result;
  }

  static parseCurrencyRegular(value: any, symbol: string): string {
    const finalValue = parseFloat(value)
      ?.toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${symbol}${finalValue}`;
  }

  static parseCurrencyWithoutDecimals(value: any, symbol: string): string {
    const finalValue = parseFloat(value)
      ?.toFixed(0)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${symbol}${finalValue}`;
  }
}
