import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Cart } from '../models/cart.model';
import * as CartActions from '../state/actions/cart.actions';
import { Client } from '../models/client.model';

@Injectable()
export class VisitPlanGuard implements CanActivate, OnDestroy {
  private subscriptions = new Subscription();
  cart: Cart;
  client: Client;

  constructor(private store: Store<{ cart: Cart; client: Client }>) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
  }

  canActivate(): boolean {
    const deliveryCondition = this.client?.deliveryCondition || 24;
    const deliveryConditionInDays = deliveryCondition / 24;
    const nextValidDate = this.addDays(new Date(), deliveryConditionInDays);

    if (this.cart.visitDate && this.cart.visitDate !== null) {
      if (nextValidDate > new Date(this.cart.visitDate)) {
        this.store.dispatch(CartActions.loadDeliveryDates());
      }
    } else if (
      nextValidDate > new Date(this.client?.data?.visitDates[0]?.visitDate)
    ) {
      this.store.dispatch(CartActions.loadDeliveryDates());
    }
    return true;
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
