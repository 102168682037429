<div class="modal-header">
  <div class="w-100 d-flex align-items-center">
    <div class="col-5 col-sm-4 col-md-4 col-lg-3 pr-16">
      <img
        src="/assets/media/missing-bottles.png"
        class="img-fluid"
        [alt]="ROOT_LANG + 'INDIVIDUAL_BOTTLES' | translate"
      />
    </div>
    <div class="col-7 col-sm-8 col-lg-9 pr-0">
      <button
        type="button" 
        class="btn-close" 
        data-bs-dismiss="modal" 
        aria-label="Close" 
        (click)="activeModal.dismiss('Cross click')">
      </button>
      <div class="my-5 mx-0 w-100 pr-0 pr-md-5">
        <p class="mb-2 mid-strong title">
          {{ ROOT_LANG + 'INDIVIDUAL_BOTTLES' | translate }}
        </p>
        <p>
          {{ ROOT_LANG + 'TO_BUY' | translate }}
          <span class="mid-strong">{{
            ROOT_LANG + 'MUST_COMPLETE' | translate
          }}</span>
        </p>
        <p>{{ ROOT_LANG + 'FLAVORS_ALLOWED' | translate }}</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <div ngbAccordion class="accordion">
    <ng-container *ngFor="let productGroup of productGroups; let i = index">
      <div ngbAccordionItem [collapsed]="false">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="btn-missing-bottles-detail-accordion">
            <div class="d-flex flex-row align-items-center justify-content-between text-center">
              <p>
                {{ productGroup.package }} {{ productGroup.size }} - Caja x{{ productGroup.boxSize }}
              </p>

              <div class="d-flex flex-row align-items-center justify-content-between">
                <div
                  *ngIf="!productGroup.reachedLimit"
                  class="d-flex flex-row align-items-center">
                  <svg class="text-danger">
                    <use
                      [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-alert-circle'"
                    />
                  </svg>
                  <p class="ms-2">
                    {{ ROOT_LANG + 'NEED_TO_ADD' | translate }}
                    {{ productGroup.missingQuantity - productGroup.groupQuantityCount }}
                  </p>
                </div>
                <div
                  *ngIf="productGroup.reachedLimit"
                  class="d-flex flex-row align-items-center">
                  <svg class="text-success">
                    <use
                      [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-circle-check'"
                    />
                  </svg>
                  <p class="ms-2">
                    {{ ROOT_LANG + 'BOX_COMPLETED' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <ul class="p-0 list-unstyled">
                <li *ngFor="let product of productGroup.products">
                  <div
                    class="d-flex justify-content-between align-items-center row m-4 mx-0"
                  >
                    <div class="col-2 col-md-1 pr-0">
                      <img
                        [src]="img_root + product.image | toThumbnail"
                        class="img-fluid"
                        [alt]="product.name"
                        (error)="onImgError($event)"
                      />
                    </div>
                    <div
                      class="col-4 col-md-5 text-start d-flex align-items-center justify-content-start flex-column text-center"
                    >
                      <span>{{ product.name }}</span>
                    </div>
                    <div class="col-3">
                      <span class="price">{{
                        product.price.finalPrice | kaCurrency
                      }}</span>
                    </div>
                    <div
                      class="col-12 col-lg-2 px-2 px-sm-4"
                    >
                      <div class="product-pill">
                        <div
                          class="btn-pill quantity p-2 d-flex align-items-center justify-content-around w-100 bg-white text-center"
                        >
                          <span
                            (click)="removeProduct(product)"
                            class="button d-flex align-items-center justify-content-center"
                            [ngClass]="{ disabled: product.quantity === 0 }"
                          >
                            <fa-icon icon="minus"></fa-icon>
                          </span>
                          <input
                            type="number"
                            [(ngModel)]="product.quantity"
                            (keydown)="validQuantLength($event, product)"
                            (keyup)="validQuantCalc($event, product)"
                            (paste)="onQuantityPaste($event, product)"
                            [ngClass]="{ error: product.quantity < 0 }"
                            (change)="refreshProductsSelected()"
                            [disabled]="productGroup.reachedLimit"
                            #maxQuantityTooltip="ngbTooltip"
                            [ngbTooltip]="
                              product.quantitySelected + 1 >
                              product.maxOrderQuantity
                                ? (ROOT_LANG + 'TOOLTIP_MAXORDER'
                                  | translate
                                    : {
                                        productsMaxQuantityAllowed:
                                          product.maxOrderQuantity,
                                      })
                                : ''
                            "
                            tooltipClass="custom-tooltip"
                            placement="top"
                            triggers="hover"
                            closeDelay="3000"
                            style="max-width: 50px"
                          />
                          <span
                            (click)="addProduct(product, productGroup)"
                            class="button d-flex align-items-center justify-content-center"
                            [ngClass]="{
                              disabled:
                                productGroup.reachedLimit ||
                                product.quantity + 1 > product.maxOrderQuantity,
                            }"
                          >
                            <fa-icon icon="plus"></fa-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </li>
              </ul>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer d-flex flex-column pt-3">
  <div class="row d-flex flex-column w-100">
    <div class="col d-flex justify-content-center">
      <button
        type="button"
        class="btn-square-black"
        [disabled]="productGroups | areAllBoxesCompleted"
        (click)="addProductsToCart()"
      >
        {{ ROOT_LANG + 'ADD_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
