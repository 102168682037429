import { CountryConfig, FEATURES, IEnvironment, countries } from './env.model';
import { env } from '../../app/app.component';

export class EnvironmentUtils {
  private country: countries;
  private env: IEnvironment;

  setEnvironment(env: IEnvironment) {
    this.env = env;
  }

  setCountry(country: countries): void {
    this.country = country;
  }

  getConfigByCountry(): CountryConfig {
    return this.env.countriesConfig[this.country];
  }

  getEnvironmentName(): string {
    return this.env.type;
  }

  getEnvironment(): IEnvironment {
    return this.env;
  }

  isFeatureAvailable(feature: FEATURES): boolean {
    return env
      .getConfigByCountry()
      .availableFeatures.some((features) => features === feature);
  }
}
