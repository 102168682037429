import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ka-draggable-carousel',
  templateUrl: './ka-draggable-carousel.component.html',
  styleUrls: ['./ka-draggable-carousel.component.scss'],
})
export class KaDraggableCarouselComponent {
  @ViewChild('container') container: ElementRef | undefined;
  @Input() suggested:boolean;
  @Input() whitespace = true;
  @Input() hideButton = false;
  private isDragging = false;
  private pos = { left: 0, top: 0, x: 0, y: 0 };
  items = Array.from({ length: 20 }, (_, i) => i + 1);

  mouseDownHandler(event: MouseEvent | TouchEvent) {
    this.isDragging = true;

    const clientX =
      'touches' in event
        ? (event as TouchEvent).touches[0].clientX
        : (event as MouseEvent).clientX;
    const clientY =
      'touches' in event
        ? (event as TouchEvent).touches[0].clientY
        : (event as MouseEvent).clientY;

    if (this.container) {
      this.pos = {
        left: this.container.nativeElement.scrollLeft,
        top: this.container.nativeElement.scrollTop,
        x: clientX,
        y: clientY,
      };
    }

    document.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
    document.addEventListener('touchmove', this.mouseMoveHandler.bind(this), {
      passive: false,
    });
    document.addEventListener('mouseup', this.mouseUpHandler.bind(this));
    document.addEventListener('touchend', this.mouseUpHandler.bind(this));
  }

  mouseMoveHandler(event: MouseEvent | TouchEvent) {
    if (!this.isDragging) {
      return;
    }

    const clientX =
      'touches' in event
        ? (event as TouchEvent).touches[0].clientX
        : (event as MouseEvent).clientX;
    const clientY =
      'touches' in event
        ? (event as TouchEvent).touches[0].clientY
        : (event as MouseEvent).clientY;

    if (this.container) {
      const dx = clientX - this.pos.x;
      const dy = clientY - this.pos.y;

      this.container.nativeElement.scrollTop = this.pos.top - dy;
      this.container.nativeElement.scrollLeft = this.pos.left - dx;
    }
  }

  mouseUpHandler() {
    this.isDragging = false;

    if (this.container) {
      document.removeEventListener(
        'mousemove',
        this.mouseMoveHandler.bind(this),
      );
      document.removeEventListener(
        'touchmove',
        this.mouseMoveHandler.bind(this),
      );
      document.removeEventListener('mouseup', this.mouseUpHandler.bind(this));
      document.removeEventListener('touchend', this.mouseUpHandler.bind(this));
    }
  }

  scrollRight() {
    if (this.container) {
      this.container.nativeElement.scrollLeft += 100;
    }
  }

  scrollLeft() {
    if (this.container) {
      this.container.nativeElement.scrollLeft -= 100;
    }
  }
}
