import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../../core/models/product.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: "app-stock-digital-modal",
  templateUrl: "./stock-digital-modal.component.html",
  styleUrls: ["./stock-digital-modal.component.scss"],
})
export class StockDigitalModalComponent {
  @Input() data: { products: Product[]; itemColor: string };
  @Input() continueBtnTranslation = 'ORDER_STATUS.SHOW_DETAILS';
  readonly baseImgURL = environment.WEB_DOMAIN;
  constructor(public activeModal: NgbActiveModal) {}
}
