import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Auth } from 'aws-amplify';
import { NgxSpinnerService } from 'ngx-spinner';
import { from, Observable, Subscription } from 'rxjs';
import * as UserActions from 'src/app/core/state/actions/user.actions';
import {
  OtherDeviceLoginWarningComponent
} from 'src/app/shared/modals/other-device-login-warning/other-device-login-warning.component';
import {
  SessionExpiredModalComponent
} from 'src/app/shared/modals/session-expired-modal/session-expired-modal.component';
import { environment } from 'src/environments/environment';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { CognitoErrorResp } from '../models/backend/cognito-error-resp';
import { UserInfo } from '../models/user-info.model';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { UserLocal } from '../models/user-local.model';
import { env } from 'src/app/app.component';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  readonly EndpointsCodes = EndpointsCodes;
  subscriptions = new Subscription();
  user: UserInfo;
  userPool: CognitoUserPool;
  userPoolInstance: CognitoUserPool;

  constructor(
    private spinner: NgxSpinnerService,
    private gtmService: GoogleTagManagerService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private store: Store<{ user: UserInfo; userLocal: UserLocal }>,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      }),
    );
  }

  refreshUserPoolInstance(): void {
    const poolData = {
      UserPoolId: env.getConfigByCountry()?.awsUserPoolId,
      ClientId: env.getConfigByCountry()?.awsClientId,
    };
    this.userPoolInstance = new CognitoUserPool(poolData);
  }

  signInAws(username: string, password: string, event?: string) {
    this.spinner.show();

    const authenticationData = {
      Username: username,
      Password: password,
    };

    const userData = {
      Username: username,
      Pool: this.userPoolInstance
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType(env.getConfigByCountry()?.awsAuthFlow);

    return new Observable((obs) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          this.spinner.hide();
          this.saveCredentialsInBrowser(username, password);
          this.handleLoginSuccess(false, event).subscribe();
        },
        onFailure: (err) => {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_LOGIN, err);
          obs.error(err)
        },
        newPasswordRequired: (userAttributes) => {
          this.spinner.hide();
          obs.error({ message: 'NEW_PASSWORD_REQUIRED', email: userAttributes.email })
        },
      });
    });
  }

  handleLoginSuccess(isSocial = false, event?: string): Observable<any> {
    this.store.dispatch(UserActions.isSocial({ isSocial }));
    this.spinner.show();
    return new Observable<any>((obs) => {
      const cognitoUser = this.userPoolInstance.getCurrentUser();
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_LOGIN, err);
          obs.error(err);
        } else {
          this.spinner.hide();
          const idToken = session.getIdToken().getJwtToken();
          const decodedIdToken = jwt_decode(idToken);
          const username = decodedIdToken['cognito:username'] || decodedIdToken['username'];
          const refreshToken = session.getRefreshToken().getToken();

          this.store.dispatch(UserActions.loadJwt({ jwt: idToken }));
          this.store.dispatch(UserActions.loadRefreshJwt({ refreshJwt: refreshToken }));
          this.store.dispatch(UserActions.loadCognitoUserName({ cognitoUserName: username }));
          this.store.dispatch(UserActions.loginUser({ cognitoUsername: username, loginEvent: event, isSocial }));

          obs.next();
        }
      });
    });
  }

  private saveCredentialsInBrowser(username, password): boolean {
    if (!(window as any).PasswordCredential) {
      return false;
    }
    const cred = new (window as any).PasswordCredential({
      id: username,
      password,
      name: username,
    });
    navigator.credentials.store(cred);
  }

  isTokenExpired(): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = this.user.jwt ? jwt_decode(this.user.jwt)['exp'] : undefined;
    return expirationTime - currentTime < 60;
  }

  getSessionExpirationTime(): Observable<any> {
    const cognitoUser = this.userPoolInstance.getCurrentUser();
    return new Observable<any>((obs) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          obs.error(err);
        } else {
          obs.next(session.getAccessToken().getExpiration());
          obs.complete();
        }
      });
    });
  }

  signInMCC(userSession) {
    const poolData = {
      IdentityPoolId: env.getConfigByCountry()?.identityPoolId,
      Region: environment.AWS_REGION,
      UserPoolId: env.getConfigByCountry()?.awsUserPoolId,
      ClientId: env.getConfigByCountry()?.awsClientIdMCC,
    };

    this.userPool = new CognitoUserPool(poolData);

    const cognitoIdToken = new CognitoIdToken({
      IdToken: userSession.IdToken,
    });

    const cognitoAccessToken = new CognitoAccessToken({
      AccessToken: userSession.AccessToken,
    });

    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: userSession.RefreshToken,
    });

    const user = new CognitoUser({
      Username: userSession.username,
      Pool: this.userPool,
    });

    user.setSignInUserSession(new CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    }));
  }

  refreshUserSessionMCC(): Observable<any> {
    return from(new Promise((resolve: any, reject: any) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser === null) {
        this.modalService.open(OtherDeviceLoginWarningComponent, { windowClass: 'ngbmodal-centered' });
        reject('Cognito: Can not get the session: null user');
      }
      cognitoUser.getSession((getSessionErr: any, getSessionSession: any) => {
        if (getSessionErr) {
          this.modalService.open(OtherDeviceLoginWarningComponent, { windowClass: 'ngbmodal-centered' });
          reject('Cognito: Can not get the session:' + getSessionErr);
        } else {
          const refreshToken = getSessionSession.getRefreshToken();
          cognitoUser.refreshSession(refreshToken, (refreshSessionErr, refreshSessionSession) => {
            if (refreshSessionErr) {
              this.modalService.open(OtherDeviceLoginWarningComponent, { windowClass: 'ngbmodal-centered' });
              reject('Cognito: Can not set the credentials:' + refreshSessionErr);
            } else {
              this.store.dispatch(UserActions.loadJwt({ jwt: refreshSessionSession.idToken.jwtToken }));
              resolve('Cognito: refreshed successfully');
            }
          });
        }
      });
    })
    )
  }

  refreshUserSession(): Observable<any> {
    return from(new Promise((resolve: any, reject: any) => {
      const cognitoUser = this.userPoolInstance.getCurrentUser();
      if (cognitoUser === null) {
        this.forceLogoutUser();
        reject('Cognito: Can not get the session: null user');
      }
      cognitoUser.getSession((getSessionErr: any, getSessionSession: any) => {
        if (getSessionErr) {
          this.forceLogoutUser();
          reject('Cognito: Can not get the session:' + getSessionErr);
        } else {
          const refreshToken = getSessionSession.getRefreshToken();
          cognitoUser.refreshSession(refreshToken, (refreshSessionErr, refreshSessionSession) => {
            if (refreshSessionErr) {
              this.forceLogoutUser();
              reject('Cognito: Can not set the credentials:' + refreshSessionErr);
            } else {
              this.store.dispatch(UserActions.loadJwt({ jwt: refreshSessionSession.idToken.jwtToken }));
              resolve('Cognito: refreshed successfully');
            }
          });
        }
      });
    })
    )
  }

  forceLogoutUser(): any {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    this.modalService.open(SessionExpiredModalComponent, { windowClass: 'ngbmodal-centered' });
  }

  confirmSignUpAws(username: string, code: string): Observable<any> {
    this.spinner.show();
    return new Observable((obs) => {
      from(Auth.confirmSignUp(username, code?.toString())).subscribe(
        (res) => {
          this.spinner.hide();
          obs.next(res);
        },
        (error) => {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_CONFIRM_SIGN_UP, error);
          obs.error(error);
        }
      );
    });
  }

  forgotPassword(username: string, metadata: { cpgId: string, countryId: string, organizationId: string }): Observable<any> {
    this.spinner.show();
    return new Observable<any>((obs) => {
      from(Auth.forgotPassword(username, metadata)).subscribe(
        (res) => {
          this.spinner.hide();
          obs.next(res);
        },
        (error) => {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_SEND_CONFIRM_CODE, error);
          obs.error(error);
        }
      );
    });
  }

  resendSignUp(username: string, metadata: { cpgId: string, countryId: string, organizationId: string }): Observable<any> {
    this.spinner.show();
    return new Observable<any>((obs) => {
      from(Auth.resendSignUp(username, metadata)).subscribe(
        (res) => {
          this.spinner.hide();
          obs.next(res);
        },
        (error) => {
          this.spinner.hide();
          this.pushGTMError(EndpointsCodes.AWS_RESEND_SIGN_UP, error);
          obs.error(error);
        }
      );
    });
  }

  signOut(): Observable<any> {
    const cognitoUser = this.userPoolInstance.getCurrentUser();
    this.spinner.show();
    return new Observable<any>((obs) => {
      this.spinner.hide();
      cognitoUser.signOut();
      obs.next();
      obs.complete();
    });
  }

  pushGTMError(serviceKey, cognitoError: CognitoErrorResp): void {
    let description;
    this.translateService.get('ERRORS.' + cognitoError.code).subscribe((errorText: string) => {
      description = errorText.startsWith('ERRORS.') ? cognitoError.name + ' - ' + cognitoError.message : errorText;
    });

    this.gtmService.pushTag({
      event: 'error',
      error: {
        serviceKey,
        source: 'cognito',
        description,
      },
    });
  }

}
