import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthExternalGuard } from './core/guards/authExternal.guard';
import { LoginGuard } from './core/guards/login.guard';
import { VisitPlanGuard } from './core/guards/visitPlan.guard';
import { ExternalAccessToB2BGuard } from './core/guards/external-access-to-b2-b.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'validate',
    loadComponent: () =>
      import('./pages/validate-session/validate-session.component').then((c) => c.ValidateSessionComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard,ExternalAccessToB2BGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./pages/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule,
      ),
  },
  {
    path: 'mcc',
    loadChildren: () =>
      import('./pages/isolated-new-order/isolated-new-order.module').then(
        (m) => m.IsolatedNewOrderModule,
      ),
      canActivate:[ExternalAccessToB2BGuard]
  },
  {
    path: 'mcc/:stepId',
    loadChildren: () =>
      import('./pages/isolated-new-order/isolated-new-order.module').then(
        (m) => m.IsolatedNewOrderModule,
      ),
    canActivate: [
      AuthExternalGuard,
      VisitPlanGuard,
      ExternalAccessToB2BGuard
    ],
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'reload-required',
    loadChildren: () =>
      import('./pages/reload-required/reload-required.module').then(
        (m) => m.ReloadRequiredModule,
      ),
  },
  {
    path:'external',
    loadChildren: () => import('./pages/external-integration/external-integration.module').then((m)=> m.ExternalIntegrationModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
