import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ka-whatsapp-call',
  templateUrl: './ka-whatsapp-call.component.html',
  styleUrls: ['./ka-whatsapp-call.component.scss'],
})
export class KaWhatsappCallComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
