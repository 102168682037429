import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FavoritesService,
  notificationInfo,
} from 'src/app/core/services/favorites.service';

@Component({
  selector: 'app-ka-fav-notifications',
  templateUrl: './ka-fav-notifications.component.html',
  styleUrls: ['./ka-fav-notifications.component.scss'],
})
export class KaFavNotificationsComponent implements OnDestroy {
  notification: notificationInfo;
  showNotification = false;
  private subscriptions = new Subscription();

  constructor(private favoriteService: FavoritesService) {
    this.subscriptions.add(
      this.favoriteService
        .getSharingFavoriteNotifications()
        .subscribe((notification) => {
          this.notification = notification;
          this.showNotification = !this.showNotification;
          setTimeout(() => {
            this.showNotification = !this.showNotification;
          }, notification.delay);
        }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
