import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LoginSteps } from '../../core/enums/login-steps.enum';

@Pipe({
  name: 'validateErrors',
})
export class ValidateErrorsPipe implements PipeTransform {
  transform(loginForm: UntypedFormGroup): unknown {
    return (
      (loginForm.controls.email.touched &&
        !loginForm.get(LoginSteps.EMAIL)?.valid) ||
      (loginForm.controls.password.touched &&
        !loginForm.get(LoginSteps.PASSWORD)?.valid) ||
      (loginForm.controls.restoreEmail.touched &&
        !loginForm.get(LoginSteps.RESTORE_EMAIL)?.valid)
    );
  }
}
