import {
  Directive,
  Renderer2,
  ElementRef,
  OnInit,
  Input,
  HostListener,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SCREEN_LG } from 'src/app/core/constants/media-query.constant';
 
@Directive({
  selector: '[appBestOption]',
})
export class BestOptionDirective implements OnInit {
  @Input() isBestOption = false;
  private bestOption: any;

  readonly LABEL = 'HOME.QUICK_ACCESS_LABEL.BEST_OPTION';
  innerWidth: number;
  constructor(
    private ele: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.applyStyle(window.innerWidth);
  }

  removeDiv() {
    if (this.bestOption) {
      this.renderer.removeChild(this.ele.nativeElement, this.bestOption);
      this.bestOption = null;
    }
  }

  private applyStyle(width: number) {
    if (this.isBestOption) {
      const styles = getComputedStyle(document.documentElement);
      const backgroundColor = styles.getPropertyValue('--dark-green').trim();
      const bestOptionLabel = this.translateService.instant(this.LABEL);
      this.removeDiv();
      this.bestOption = this.renderer.createElement('div' );
      this.renderer.setStyle(this.bestOption, 'position', 'absolute');
      this.renderer.setStyle(this.bestOption, 'top', '0px');
      this.renderer.setStyle(this.bestOption, 'left', '0px');
      this.renderer.setStyle(this.bestOption, 'background-color', backgroundColor);
      this.renderer.setStyle(this.bestOption, 'color', 'white');
      this.renderer.setStyle(this.bestOption, 'display', 'flex');
      this.renderer.setStyle(this.bestOption, 'justify-content', 'center');
      this.renderer.setStyle(this.bestOption, 'align-items', 'center');
      this.innerWidth = window.innerWidth;
      if (width > SCREEN_LG ) {
        this.renderer.setStyle(this.bestOption, 'border-radius', '8px 0 8px 0');
        this.renderer.setStyle(this.bestOption, 'width', '35%');
        this.renderer.setStyle(this.bestOption, 'height', '18%');
        this.renderer.setStyle(this.bestOption, 'font-size', '14px');
      } else {
        this.renderer.setStyle(this.bestOption, 'border-radius', '8px 8px 0 0');
        this.renderer.setStyle(this.bestOption, 'width', '100%');
        this.renderer.setStyle(this.bestOption, 'height', '15%');
        this.renderer.setStyle(this.bestOption, 'font-size', '12px');
      }
      this.renderer.setProperty(this.bestOption, 'innerText', bestOptionLabel);
      this.renderer.appendChild(this.ele.nativeElement, this.bestOption);
    }
  }
  
  @HostListener('window:resize', ['$event']) onResize(event: Event) {
    if (this.isBestOption) {
      this.applyStyle(window.innerWidth);
    }
  }

}
