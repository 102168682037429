import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IListConfig } from './models/list.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() config: IListConfig;
  @Output() clickItemEvent = new EventEmitter();
}
