import { Pipe, PipeTransform } from '@angular/core';
import { DiscountScale } from '../../core/models/discount-scale.model';
import { Discount } from '../../core/models/discount.model';

@Pipe({
  name: 'getScaleDiscountPrice',
})
export class GetScaleDiscountPricePipe implements PipeTransform {
  transform(discount: Discount, currentScale: DiscountScale): number {
    const maxScaleDisc =
      discount.requirements?.scales[discount.requirements?.scales?.length - 1]
        .max;
    if (discount.quantitySelected <= maxScaleDisc) {
      return currentScale
        ? discount.quantitySelected * currentScale?.price.finalPrice
        : discount.quantitySelected *
            discount.requirements?.scales[0].price.finalPrice;
    } else {
      const discountPrice = maxScaleDisc * currentScale.price.finalPrice;
      const normalPrice =
        (discount.quantitySelected - maxScaleDisc) *
        discount.requirements.price.finalPriceWithoutDiscount;
      return discountPrice + normalPrice;
    }
  }
}
