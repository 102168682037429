<ng-container *ngIf="client$ | async as client">
  <radio-group [formControl]="control">
    <ng-container *ngIf="clientFrozenVisitDates?.length">
      <div class="select-date-options-container">
        <div class="select-date-options-title">
          {{'NEW_ORDER.DELIVERY_PLAN.NAME' | translate}}
        </div>
        <div 
          *ngIf="client.amountMinFrozen > 0"
          class="select-date-options-subtitle">
          {{'NEW_ORDER.DELIVERY_PLAN.MINIMUM_AMOUNT' | translate: { 
            amount: (labelAmountMinFrozen | async) 
          } }}
        </div>
        <div class="mb-16"></div>
        
        <div class="radio-container">
          <app-radio *ngFor="let date of clientFrozenVisitDates" [value]="date" class="radio">
            {{date.visitDate | weekdayDayMonthParser}}
          </app-radio>
        </div>
      </div>
    </ng-container>
  </radio-group>
</ng-container>
