<ng-container *ngIf="!readonly">
  <a
    class="nav-link dropdown-toggle"
    role="button" 
    data-bs-toggle="dropdown" 
    aria-expanded="false"
    id="dropdown05">
    <img
      class="img-fluid flags rounded-circle"
      alt="Country flag"
      src="assets/media/countriesFlags/{{ selectedCountry.key }}.png"
    />
  </a>
  <ul class="dropdown-menu dropdown-menu-end">
    <li
      *ngFor="let flag of countryFlags"
      class="dropdown-item"
      (click)="selectLanguage(flag)"
    >
      <img
        class="img-fluid flags rounded-circle mr-8"
        alt="Country flag"
        src="assets/media/countriesFlags/{{ flag.key }}.png"
      />
      {{ flag.label }}
    </li>
  </ul >
</ng-container>

<ng-container *ngIf="readonly">
  <a class="nav-link">
    <img
      class="img-fluid flags rounded-circle"
      alt="Country flag"
      src="assets/media/countriesFlags/{{ selectedCountry.key }}.png"
    />
  </a>
  <span *ngIf="hasLabel" class="ms-3">{{ selectedCountry.label }}</span>
</ng-container>
<app-ka-frontend-version *ngIf="isNuevoPedidoPath"></app-ka-frontend-version>
