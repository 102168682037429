import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { BERespModel } from 'src/app/core/models/backend/BE-response.model';
import { UserExternalData } from 'src/app/core/models/user-external-info.model';

import { ApiService } from 'src/app/core/services/api.service';
import { ClientService } from 'src/app/core/services/client.service';

import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { ExternalIntegration } from '../enums/external-integration.enum';

import { env } from 'src/app/app.component';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { UserService } from 'src/app/core/services/user.service';
import { UserRole } from 'src/app/core/enums/user-role';
import { first } from 'rxjs/operators';

import { logout, cleanUserInformation } from 'src/app/core/state/actions/user.actions';

@Injectable({
  providedIn: 'root',
})
export class ExternalDataService {
  subscriptions = new Subscription();
  private validateExternalUserSubject = new BehaviorSubject<BERespModel>({} as BERespModel);
  validateExternalIntegrationUser$ = this.validateExternalUserSubject.asObservable();

  getExternalUserValidationData(): Observable<any> {
    return this.validateExternalIntegrationUser$;
  }

  get user$(): Observable<UserInfo>{
    return this.userService.user$;
  }

  constructor(
    private apiSrv: ApiService,
    private clientService: ClientService,
    private userService: UserService,
    private router: Router,
    private store: Store<{user: UserInfo}>
  ) { }

  validateExternalIntegrationUser(uuid: string): Observable<BERespModel> {
    const requestObj = { session: uuid };
    return new Observable((obs) => {
      this.apiSrv.post(`clients/${this.clientService.client.clientId}/external/validate`, EndpointsCodes.LOGIN_EXTERNAL_INTEGRATIONS, requestObj, { showError: false }).subscribe(
        (res) => {
          this.validateExternalUserSubject.next(res);
          obs.next(res);
        },
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  getExternalIntegrationUserInfo(clientId: string, authValue: string, authType: string): Observable<BERespModel> {
    const requestObj = { authValue: authValue, authType: authType };
    return new Observable((obs) => {
      this.apiSrv.post(`clients/${clientId}/external/user_info`, EndpointsCodes.POST_EXTERNAL_USER_INFO, requestObj, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  createUserSession(data: UserExternalData): Observable<BERespModel> {
    const requestObj = {
      origin: data.origin,
      accessToken: data.AccessToken,
      idToken: data.IdToken,
      refreshToken: data.RefreshToken,
      contextId: data.contextId,
      authType: data.authType,
      authValue: data.authValue
    };
    return new Observable((obs) => {
      this.apiSrv.post(`clients/${this.clientService.client.clientId}/external/session`, EndpointsCodes.POST_EXTERNAL_USER_SESSION, requestObj, { showError: false }).subscribe(
        (res) => obs.next(res),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  isExternalIntegrationModule(): boolean {
    return this.router.url.includes(ExternalIntegration.EXTERNAL);
  }

  goBackToWhatsapp(): void {
    if (!this.isExternalIntegrationModule()) {
      return;
    }

    const phoneNumber = env.getEnvironment().kobossWhatsapp;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    
    window.location.href = whatsappUrl;
  }

  updateClientStore$(): Observable<void> {
    return new Observable<void>((obs) => {
      const params = {
        clientId: this.clientService.client.clientId.toString(),
        authValue: this.userService.user?.authValue,
        authType: this.userService.user?.authType,
      };

      this.getExternalIntegrationUserInfo(
        params.clientId,
        params.authValue,
        params.authType
      ).subscribe({
        next: ({ data }) => {
          this.clientService.updateCurrentClient(data);
          obs.next();
        },
        error: () => {
          obs.error();
        }
      });
    });
  }

  handleNewOrderRoutes() {
    this.user$.pipe(
      first(user => user.role != null)
    ).subscribe({
      next: (user) => {
        if (user.role === UserRole.BUYER ||(user.role === UserRole.ADMINISTRATOR && user.clients.length === 1)) {
          this.router.navigate(
            ['./external/nuevo-pedido', 1], 
            { queryParamsHandling: 'preserve' }
          );
        }
      } 
    });
  }

  clearStoreUserData(): void {
    if (!this.isExternalIntegrationModule()) {
      return
    }
    else {
      this.store.dispatch(logout());
      this.store.dispatch(cleanUserInformation());
    }
  }
}
