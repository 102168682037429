import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnDestroy {
  progressValue = 0;
  private intervalId: NodeJS.Timeout;

  start(): void {
    this.progressValue = 0;
    const startTime = new Date().getTime();

    this.intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < startTime) {
        const newProgressValue = (Math.round(elapsedTime) % 100) * 5;
        if (newProgressValue < 100) {
          if (newProgressValue > this.progressValue) {
            this.progressValue = newProgressValue;
          }
        }
      }
    }, 500);
  }

  finish(): void {
    clearInterval(this.intervalId);
    this.progressValue = 100;
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
