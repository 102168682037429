import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { OrderStatus } from '../../../core/enums/order-status';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { SourceChannels } from 'src/app/core/enums/source-channels';

import { OrderDetail } from '../../../core/models/order-detail.model';
import { Order } from '../../../core/models/order.model';
import { UserInfo } from '../../../core/models/user-info.model';

import * as ClientActions from '../../../core/state/actions/client.actions';

import { FileUtils } from '../../../core/utils/file-utils';
import { OrdersService } from '../../../pages/my-orders/services/orders.service';
import { ModalsService } from '../../../core/services/modals.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../../../core/services/cart.service';

import { InvalidDetailOrderPipe } from 'src/app/pages/my-orders/pipes/invalid-detail-order.pipe';

import { env } from 'src/app/app.component';
import { FEATURES } from 'src/environments/utils/env.model';

import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-ka-bottom-modal-history-order',
  templateUrl: './ka-bottom-modal-history-order.component.html',
  styleUrls: ['./ka-bottom-modal-history-order.component.scss'],
  providers: [InvalidDetailOrderPipe],
})
export class KaBottomModalHistoryOrderComponent implements OnInit {
  user: UserInfo;
  order: Order;
  isAvailableThirdPartyOrderDetail: boolean;
  isDownloadInvoiceAvailable: boolean | undefined;
  private subscriptions = new Subscription();
  readonly CountryCodes = CountryCodes;
  readonly SourceChannels = SourceChannels;

  constructor(
    private ordersService: OrdersService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: Order,
    private modalService: ModalsService,
    private store: Store<{ user: UserInfo }>,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public cartService: CartService,
    public dialogRef: MatBottomSheetRef<KaBottomModalHistoryOrderComponent>,
    private isInvalidDetailOrder: InvalidDetailOrderPipe,
    private platform: Platform
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.order = data['order'];
  }

  ngOnInit(): void {
    this.loadAvailableFeatures();
  }

  private loadAvailableFeatures(): void {
    this.isAvailableThirdPartyOrderDetail = env.isFeatureAvailable(
      FEATURES.THANOS_ORDER_DETAIL,
    );
    this.isDownloadInvoiceAvailable = env.isFeatureAvailable(
      FEATURES.DOWNLOAD_INVOICE,
    );
  }

  goOrderDetails(): void {
    const INVALID_DETAIL_ORDER = this.isInvalidDetailOrder.transform(
      this.order,
      this.isAvailableThirdPartyOrderDetail,
    );

    if (!INVALID_DETAIL_ORDER) {
      this.cartService.goOrderDetail(this.order);
      this.dialogRef.dismiss();
    }
  }

  isDownloadInvoice(order: Order): boolean {
    return (
      this.isDelivered(order.status as OrderStatus) &&
      order.documentNumber &&
      this.isDownloadInvoiceAvailable
    );
  }

  isDelivered(status: OrderStatus): boolean {
    return (
      status === OrderStatus.DELIVERED_CLT ||
      status === OrderStatus.TRANSIT ||
      status === OrderStatus.RETURNED ||
      status === OrderStatus.PARTIAL_DELIVERY
    );
  }

  getOrderInvoicePDF(order: OrderDetail) {
    this.ordersService
      .getOrderInvoice(order.orderId, order.erpOrderId)
      .subscribe((invoice) => {
        FileUtils.getPdfFromBase64(
          invoice.data.fileContent,
          `${order.erpOrderId}`,
          (this.platform.SAFARI && this.platform.isBrowser)
        );
      });
    this.dialogRef.dismiss();
  }

  showCancelOrderButton(status: string): boolean {
    const statuses = [
      'initiated',
      'created',
      'delivered',
      'registered',
      'blocked',
    ];
    return statuses.includes(status.toLowerCase()) && this.order?.orderId;
  }

  cancelOrder(orderId: number): void {
    this.dialogRef.dismiss();
    this.modalService.openCancellationOrder().result.then((confirm) => {
      if (confirm) {
        this.ordersService.cancelOrder(orderId).subscribe(() => {
          this.store.dispatch(ClientActions.loadOrders());
          const message = this.translateService.instant(
            'MY_ORDERS.ORDER_DETAIL.CANCEL_ORDER_MESSAGE',
          );
          this.toastr.success(message);
        });
      }
    });
  }

  hideRepeatAndCancelButton(): boolean {
    return this.ordersService.isOrderCreatedFromMET(this.order);
  }
}
