<button
  class="dropdown-item py-3 d-flex align-items-center"
  (click)="goOrderDetails()"
  [disabled]="order | invalidDetailOrder: isAvailableThirdPartyOrderDetail"
>
  <svg class="pr-2">
    <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-eye'" />
  </svg>
  <span>{{ 'MY_ORDERS.MY_HISTORY.TABLE.ORDER_DETAIL' | translate }}</span>
</button>

<button
  class="dropdown-item py-3 d-flex align-items-center"
  id="order_invoice_{{ order.orderId }}"
  (click)="getOrderInvoicePDF(order)"
  *ngIf="isDownloadInvoice(order)"
  [disabled]="!isDelivered(order.status)"
>
  <svg class="pr-2">
    <use
      [attr.xlink:href]="
        './assets/icons/tabler-sprite.svg#tabler-file-download'
      "
    />
  </svg>
  <span>{{
    'MY_ORDERS.MY_HISTORY.TABLE.DOWNLOAD_INVOICE'
      | translate: { erpOrderId: order.erpOrderId }
  }}</span>
</button>

<button
  *ngIf="!hideRepeatAndCancelButton()"
  class="dropdown-item py-3 d-flex align-items-center"
  id="cancel_order_{{ order.orderId }}"
  [id]="'cancel_order_' + order.orderId"
  (click)="cancelOrder(order.orderId)"
  [disabled]="!showCancelOrderButton(order.status)"
>
  <svg class="pr-2">
    <use
      [attr.xlink:href]="
        './assets/icons/tabler-sprite.svg#tabler-shopping-cart-x'
      "
    />
  </svg>
  <span>{{ 'MY_ORDERS.ORDER_DETAIL.CANCEL_ORDER' | translate }}</span>
</button>
