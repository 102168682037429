<div class="conteiner">
  <app-alert
    *ngIf="showNotification"
    [type]="notification['alert-type']"
    [text]="notification['text'] | translate"
    [size]="12"
    [showLeftIcon]="true"
    [customIcon]="notification['icon']"
  ></app-alert>
</div>
