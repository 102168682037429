import { createAction, props } from '@ngrx/store';
import { OptionsLoyalty } from '../../models/loyalty/loyalty.model';

export const getOptionsLoyalty = createAction(
  '[Ui Loyalty] getOptionsLoyalty',
  props<{ clientId: number }>(),
);
export const successGetOptionsLoyalty = createAction(
  '[Ui Loyalty] successGetOptionsLoyalty',
  props<{ options: OptionsLoyalty[] }>(),
);
export const failureGetOptionsLoyalty = createAction(
  '[Ui Loyalty] failureGetOptionsLoyalty',
);
export const resetOptionsLoyalty = createAction(
  '[Ui Loyalty] resetOptionsLoyalty',
);
export const saveLoyaltyUrl = createAction(
  '[Ui Loyalty] saveUrl',
  props<{ url: string }>(),
);
export const removeLoyaltyUrl = createAction(
  '[Ui Loyalty] removeUrl',
);