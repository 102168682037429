import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { getCartFrozenVisitDate } from '../state/reducers/cart.reducer';
import { getClientFrozenVisitDates } from '../state/reducers/client.reducer';
import {
  updateFrozenVisitDate,
  setFrozenOperationDate,
  updateDeliveryDate,
  loadFrozenDeliveryDates,
} from '../state/actions/cart.actions';

import { VisitDate } from '../models/client.model';
import { DateUtility } from '../utils/app-date-utils';

@Injectable({
  providedIn: 'root'
})
export class VisitFrozenDatesService {
  clientFrozenVisitDates: VisitDate[];
  currentFrozenVisitDate: string | Date;
  get clientCartStream$(): Observable<[string | Date, VisitDate[]]> {
    return combineLatest(
      this.store.select(getCartFrozenVisitDate),
      this.store.select(getClientFrozenVisitDates),
    );
  }

  constructor(private store: Store) { }

  getFrozenVisitDatesByClient(): Subscription {
    return this.clientCartStream$.subscribe({
      next: ([
        cartFrozenVisitDate,
        clientFrozenVisitDates,
      ]) => {
        this.setVisitDates(clientFrozenVisitDates, cartFrozenVisitDate);
      },
    })
  }

  private setVisitDates(
    clientFrozenVisitDates: VisitDate[],
    cartFrozenVisitDate: string | Date
  ): void {
    this.clientFrozenVisitDates = clientFrozenVisitDates;
    if (!this.clientFrozenVisitDates) {
      return;
    }

    this.currentFrozenVisitDate = this.getCurrentFrozenVisitDate(cartFrozenVisitDate);
  }

  private getCurrentFrozenVisitDate(cartFrozenVisitDate: string | Date): string | Date {
    if (!cartFrozenVisitDate) {
      return this.clientFrozenVisitDates[0]?.visitDate
        ? DateUtility.formatDateToISOString(this.clientFrozenVisitDates[0]?.visitDate)
        : null;
    }

    return DateUtility.formatDateToISOString(cartFrozenVisitDate);
  }

  updateFrozenVisitDate(): void {
    this.store.dispatch(updateFrozenVisitDate({ date: this.currentFrozenVisitDate }));
  }

  updateFrozenVisitDateBySelect(selectedVisitDate: string | Date): void {
    this.store.dispatch(updateFrozenVisitDate({ date: selectedVisitDate }));
  }

  setFrozenOperationDate(): void {
    this.store.dispatch(setFrozenOperationDate());
  }

  isSameDeliveryDate(deliveryDate: VisitDate): boolean {
    return DateUtility.isSameDay(deliveryDate.visitDate, this.currentFrozenVisitDate)
  }

  updateCurrentVisitDateBySelectedDate(selectedVisitDate: VisitDate): void {
    this.store.dispatch(updateDeliveryDate({ date: selectedVisitDate }));
  }

  loadFrozenDeliveryDates(): void {
    this.store.dispatch(loadFrozenDeliveryDates());
  }
}
