import { Pipe, PipeTransform } from '@angular/core';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { OrderDetail } from 'src/app/core/models/order-detail.model';

@Pipe({
  name: 'showPaymentMethod'
})
export class ShowPaymentMethodPipe implements PipeTransform {
  transform(
    user: UserInfo,
    hasPayMethodByCategory: boolean,
    orderDetail: OrderDetail,
    countryCodes: typeof CountryCodes
  ): boolean {
    return (
      (user.countryId !== countryCodes.ARGENTINA && !hasPayMethodByCategory) ||
      (hasPayMethodByCategory && orderDetail.erpOrders.length === 0)
    );
  }
}