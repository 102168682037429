import { Component, Input } from '@angular/core';

export type labelType =
  'primary' |
  'success' |
  'info' |
  'warning' |
  'danger';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  @Input() text: string;
  @Input() class: labelType;
}
