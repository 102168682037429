import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/core/models/user-info.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() public data: any;
  @Input() public showFooterButtons? = true;
  @Output() modalResponse: EventEmitter<any> = new EventEmitter();
  
  private subscriptions = new Subscription();
  whatsapp: string;
  user: UserInfo;

  constructor(public activeModal: NgbActiveModal, public router: Router, 
    private translateService: TranslateService, private store: Store<{ user: UserInfo; }>) {
      this.subscriptions.add(
        this.store.select('user').subscribe((user) => {
          this.user = user;
        })
      );
    }

  ngOnInit(): void {
    if (this.data.whatsapp) this.whatsapp = this.translateService.instant(this.data.whatsapp);
  }

  confirm(): void {
    if (this.data.navigateTo) {
      this.router.navigate([this.data.navigateTo]);
    }
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.close(false);
  }
}
