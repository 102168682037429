import { ApplicationRef, inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserInfo } from '../../models/user-info.model';
import { CountryCodes } from '../../enums/country-codes.enum';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CheckForUpdateService {
  appRef = inject(ApplicationRef);
  updates = inject(SwUpdate);
  router = inject(Router);
  route = inject(ActivatedRoute);
  store = inject(Store<{ user: UserInfo}>);

  constructor() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable),
    );
    const everyHour$ = interval(60 * 60 * 1000); // 1h
    const everyFourHours$ = interval(environment.sessionTimeout); // 4h
    // const everyFourHours$ = interval(60 * 1000 * 4); // 4m
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    everyHourOnceAppIsStable$.subscribe(async () => {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        if (this.updates.isEnabled && registration?.active) {
          const updateFound = await this.updates.checkForUpdate();
          console.info(
            updateFound
              ? 'A new version is available.'
              : 'Already on the latest version.',
          );

          if (updateFound) {
            document.location.reload();
          }
        } else {
          await navigator.serviceWorker.register('ngsw-worker.js', {
            scope: '/',
          });
        }
      } catch (err) {
        console.error('Failed to check for updates: ', err);
        await navigator.serviceWorker.register('ngsw-worker.js', {
          scope: '/',
        });
      }
    });

    everyFourHours$.pipe(switchMap(() => this.store.select('user').pipe(take(1)))).subscribe((user) => {
      if ((user.countryId === CountryCodes.CHILE || user.countryId === CountryCodes.BRASIL) && !this.route.snapshot?.queryParams?.user) {
        console.info('Validating session...');
        this.router.navigate(['/validate']);
      }
    });

    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe((evt) => {
        console.info('Version info: ', evt);
        document.location.reload();
      });
  }
}
