import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';

import { UserInfo } from 'src/app/core/models/user-info.model';
import { UserLocal } from 'src/app/core/models/user-local.model';
import { TranslationService } from 'src/app/core/services/translation.service';
import * as UserLocalActions from 'src/app/core/state/actions/user-local.actions';

import { env } from 'src/app/app.component';
import { countries, CountryConfig } from 'src/environments/utils/env.model';
import { Router } from '@angular/router';
import { HeadDocumentService } from 'src/app/core/services/head-document.service';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

@Component({
  selector: 'app-ka-select-country',
  templateUrl: './ka-select-country.component.html',
  styleUrls: ['./ka-select-country.component.scss'],
})
export class KaSelectCountryComponent implements OnInit, OnDestroy {
  router = inject(Router);

  selectedCountry: CountryConfig;
  countryFlags: CountryConfig[];
  user: UserInfo;
  userLocal: UserLocal;
  isNuevoPedidoPath = false;

  @Input() hasLabel? = false;
  @Input() readonly? = false;

  private subscriptions = new Subscription();

  constructor(
    private store: Store<{ user: UserInfo; userLocal: UserLocal }>,
    private gtmService: GoogleTagManagerService,
    private translationService: TranslationService,
    private headDocumentService: HeadDocumentService
  ) {
    this.subscriptions.add(
      this.store
        .select('userLocal')
        .subscribe((userLocal) => (this.userLocal = userLocal)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  ngOnInit(): void {
    this.selectedCountry = env.getConfigByCountry();
    this.countryFlags = [
      env.getEnvironment().countriesConfig.CL,
      env.getEnvironment().countriesConfig.AR,
      env.getEnvironment().countriesConfig.BR,
      env.getEnvironment().countriesConfig.PY,
    ];

    this.isNuevoPedidoPath = this.router.url.includes('nuevo-pedido/1');

  }

  selectLanguage(selectedCountry: CountryConfig): void {
    this.headDocumentService.setTitleByCountry(selectedCountry.key as CountryCodes);
    this.store.dispatch(UserLocalActions.updateloginError({ errorCode: '' }));
    this.selectedCountry = selectedCountry;
    this.translationService.setLanguage(selectedCountry.key as countries);
    this.store.dispatch(
      UserLocalActions.loadGeoCountryCode({ countryCode: selectedCountry.key }),
    );
    this.store.dispatch(
      UserLocalActions.loadOrganizationId({
        organizationId: selectedCountry.organizationId,
      }),
    );
    this.pushCountryGTM(selectedCountry);
  }

  pushCountryGTM(lang): void {
    const countryGTM = {
      event: 'ngCountryChange',
      country: {
        isoCodeCountry: lang.key,
        labelCountry: lang.label,
      },
    };
    this.gtmService.pushTag(countryGTM);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
