import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const MODAL_OPEN = true;
const MODAL_CLOSED = false;

@Injectable({
  providedIn: 'root'
})
export class ModalMobileDeliveryDateService {
  isCloseModal$ = new Subject<boolean>;
  currentShowModal = false;
  canCloseWithShadow = true;

  openModal(): void {
    this.currentShowModal = MODAL_OPEN;
  }

  closeModal(): void {
    this.isCloseModal$.next(true);
    this.currentShowModal = MODAL_CLOSED;
  }

  resetCanCloseWithShadow(): void {
    this.canCloseWithShadow = true;
  }
}