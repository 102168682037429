import {
  Directive,
  AfterContentInit,
  Output,
  EventEmitter,
  ContentChildren,
  forwardRef,
  QueryList,
  Input,
  ChangeDetectorRef,
  InjectionToken
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { RadioComponent, MatRadioChange } from "../radio.component";

let nextUniqueId = 0;

export const RADIO_GROUP = new InjectionToken<RadioGroup>('RadioGroup');

@Directive({
  selector: 'radio-group',
  exportAs: 'RadioGroup',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroup),
      multi: true,
    },
    {
      provide: RADIO_GROUP,
      useExisting: RadioGroup,
    },
  ],
  standalone: true,
})
export class RadioGroup implements ControlValueAccessor, AfterContentInit {
  private _value: any = null;
  private _selected: RadioComponent | null = null;
  private _disabled: boolean = false;
  private _name: string = `radio-group-${nextUniqueId++}`;
  private _isInitialized: boolean = false;
  private _buttonChanges = new Subscription;
  private _required: boolean;
  _controlValueAccessorChangeFn: (value: any) => void = () => { };
  onTouched: () => any = () => { };
  @Output() readonly change: EventEmitter<MatRadioChange> = new EventEmitter<MatRadioChange>();

  @ContentChildren(
    forwardRef(() => RadioComponent),
    { descendants: true }
  ) _radios: QueryList<RadioComponent>;

  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (this._value !== newValue) {
      this._value = newValue;

      this._updateSelectedRadioFromValue();
      this._checkSelectedRadioButton();
    }
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this._markRadiosForCheck();
  }

  @Input()
  get selected() {
    return this._selected;
  }
  set selected(selected: RadioComponent | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkSelectedRadioButton();
  }

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this._updateRadioButtonNames();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = value;
    this._markRadiosForCheck();
  }

  constructor(private _changeDetector: ChangeDetectorRef) { }

  ngAfterContentInit(): void {
    this._isInitialized = true;
    this._buttonChanges.add(
      this._radios.changes.subscribe(() => {
        if (this.selected && !this._radios.find(radio => radio === this.selected)) {
          this._selected = null;
        }
      })
    );
  }

  private _updateSelectedRadioFromValue(): void {
    const isAlreadySelected = this._selected !== null && this._selected.value === this._value;

    if (this._radios && !isAlreadySelected) {
      this._selected = null;
      this._radios.forEach(radio => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  _checkSelectedRadioButton() {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  _markRadiosForCheck() {
    if (this._radios) {
      this._radios.forEach(radio => radio._markForCheck());
    }
  }

  private _updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach(radio => {
        radio.name = this.name;
        radio._markForCheck();
      });
    }
  }

  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.change.emit(new MatRadioChange(this._selected!, this._value));
    }
  }

  writeValue(value: any) {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this._changeDetector.markForCheck();
  }
}