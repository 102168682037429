import { Pipe, PipeTransform } from '@angular/core';
import { MissingBottlesInfo } from '../../core/models/missing-bottles-info.model';

@Pipe({
  name: 'areAllBoxesCompleted',
})
export class AreAllBoxesCompletedPipe implements PipeTransform {
  transform(productsString: string): boolean {
    if (!productsString) {
      return false;
    }
    const products: MissingBottlesInfo[] = JSON?.parse(productsString);
    if (!products) {
      return false;
    }
    return products?.some((group) => !group.reachedLimit);
  }
}
