import { Pipe, PipeTransform } from '@angular/core';
import { DiscountTypes } from '../../core/enums/discount-types';
import { Discount } from '../../core/models/discount.model';

@Pipe({
  name: 'showDiscountLabelForCC',
})
export class ShowDiscountLabelForCCPipe implements PipeTransform {
  transform(discount: Discount): boolean {
    let isDiscountCC = false;

    switch (discount.discountType) {
      case DiscountTypes.CLOSED:
      case DiscountTypes.BOM:
        isDiscountCC = true;
        break;
      case DiscountTypes.PERCENTAGE:
      case DiscountTypes.AMOUNT:
      case DiscountTypes.FIXED:
        isDiscountCC = discount.config.class === 'close';
        break;
    }
    return isDiscountCC && discount.requirements?.scales;
  }
}
