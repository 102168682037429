import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import * as UiActions from '../actions/ui.actions';
import { LoyaltyService } from '../../services/loyalty/loyalty.service';

@Injectable()
export class UiEffects {
  getOptionsLoyalty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiActions.getOptionsLoyalty),
      switchMap((action) =>
        this.loyaltyService.getOptionsLoyalty(action.clientId).pipe(
          map((response) =>
            UiActions.successGetOptionsLoyalty({ options: response.data }),
          ),
          catchError(() => {
            return of(UiActions.failureGetOptionsLoyalty());
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private loyaltyService: LoyaltyService,
  ) { }
}
