import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { startWith, distinctUntilChanged, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

import { VisitFrozenDatesService } from 'src/app/core/services/visit-frozen-dates.service';
import { ClientService } from 'src/app/core/services/client.service';
import { CurrencyPipe } from '../../pipes/currency.pipe';
import { WeekdayDayMonthParserPipe } from '../../pipes/week-day-day-month.pipe';

import { RadioGroup } from '@components/radio/directives/radio-group.directive';
import { RadioComponent } from '@components/radio/radio.component';
import { env } from 'src/app/app.component';

import { Client, VisitDate } from 'src/app/core/models/client.model';
import { DateUtility } from 'src/app/core/utils/app-date-utils';
import { AppUtils } from 'src/app/core/utils/app-utils';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { ComponentsModule } from '@components/components.module';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    ComponentsModule,
    ReactiveFormsModule,
    WeekdayDayMonthParserPipe,
    CurrencyPipe,
    RadioComponent,
    RadioGroup,
    CommonModule,
  ],
  selector: 'app-options-frozen-delivery-date',
  templateUrl: './options-frozen-delivery-date.component.html',
  styleUrls: ['./options-frozen-delivery-date.component.scss']
})
export class OptionsFrozenDeliveryDateComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription;
  control: FormControl = this.formBuilder.control('');
  get currentFrozenVisitDate(): string | Date {
    return this._visitFrozenDatesService.currentFrozenVisitDate;
  }
  get client$(): Observable<Client> {
    return this.clientService.getClientUntilChanged$();
  }
  get clientFrozenVisitDates(): VisitDate[] {
    return this._visitFrozenDatesService.clientFrozenVisitDates.filter((client) => {
      return !client.offRoute;
    });
  }
  get isFrozenVisitDatesEqualOne(): boolean {
    return this._visitFrozenDatesService.clientFrozenVisitDates.length === 1;
  }
  get labelAmountMinFrozen(): Observable<string> {
    return this.client$.pipe(
      map((client) => {
        switch (env.getConfigByCountry().key) {
          case CountryCodes.CHILE:
            return AppUtils.parseCurrencyWithoutDecimals(client.amountMinFrozen, '$');
          case CountryCodes.ARGENTINA:
            return AppUtils.parseCurrencyWithoutDecimals(client.amountMinFrozen, '$');
          case CountryCodes.PARAGUAY:
            return AppUtils.parseCurrencyWithoutDecimals(client.amountMinFrozen, '₲');
          case CountryCodes.BRASIL:
            return AppUtils.parseCurrencyWithoutDecimals(client.amountMinFrozen, 'R$');
          default:
            return AppUtils.parseCurrencyWithoutDecimals(client.amountMinFrozen, '$');
        }
      }),
    );
  }
  @Output() selectedFrozenVisitDate = new EventEmitter<VisitDate>();

  constructor(
    private _visitFrozenDatesService: VisitFrozenDatesService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.getFrozenVisitDatesByClient();
    this.setUpForm();
  }

  private getFrozenVisitDatesByClient(): void {
    this._subscription.add(this._visitFrozenDatesService.getFrozenVisitDatesByClient());
  }

  private setUpForm(): void {
    this._subscription.add(
      this._visitFrozenDatesService.clientCartStream$.subscribe({
        next: () => {
          this.control = this.formBuilder.control(this.getInitialValue());
          this.updateSelectVisitDate();

          if (this.isFrozenVisitDatesEqualOne) {
            this.control.disable();
          }
        },
      })
    );
  }

  private getInitialValue(): VisitDate {
    let initialControlValue: VisitDate;

    this._visitFrozenDatesService.clientFrozenVisitDates?.forEach((date) => {
      if (DateUtility.isSameDay(date.visitDate, this.currentFrozenVisitDate)) {
        initialControlValue = date;
      }
    });

    return initialControlValue
      ? initialControlValue
      : this._visitFrozenDatesService.clientFrozenVisitDates[0];
  }

  private updateSelectVisitDate(): void {
    this._subscription.add(
      this.control.valueChanges.pipe(
        startWith(this.control.value),
        distinctUntilChanged((prev: VisitDate, curr: VisitDate) => {
          return prev.visitDate === curr.visitDate;
        }),
      ).subscribe({
        next: (value) => this.selectedFrozenVisitDate.emit(value),
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
