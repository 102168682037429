import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class LocalStorageService {
  static set(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static get(key: string): string {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    return itemStr;
  }

  static remove(key: string): void {
    localStorage.removeItem(key);
  }

  static clean(): void {
    localStorage.clear();
  }
}
