import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Locks } from 'src/app/core/enums/locks.enum';
import { Client } from 'src/app/core/models/client.model';

@Component({
  selector: 'app-ka-block-message',
  templateUrl: './ka-block-message.component.html',
  styleUrls: ['./ka-block-message.component.scss']
})
export class KaBlockMessageComponent implements OnDestroy {

  client: Client;
  maintenance: boolean;
  blockPPD: boolean;
  private subscriptions = new Subscription();

  constructor(private store: Store<{ client: Client }>) {
    this.subscriptions.add(this.store.select('client').subscribe((client) => {
      this.client = client;
      this.maintenance = client.locks?.some(lock => lock.description === Locks.MAINTENANCE) || this.client.lockType === 'Maintenance';
      this.blockPPD = client.locks?.some(lock => lock.description === Locks.PPD_USER);
    }));
   }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
