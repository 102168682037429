import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Client } from 'src/app/core/models/client.model';
import { OptionsLoyalty } from 'src/app/core/models/loyalty/loyalty.model';
import { FEATURES } from 'src/environments/utils/env.model';
import { UserInfo } from 'src/app/core/models/user-info.model';

import { env } from 'src/app/app.component';

import { NavbarMenuMobileService } from '../../ka-navbar-menu-mobile/services/navbar-menu-mobile.service';
import { ClientService } from 'src/app/core/services/client.service';
import { UserService } from 'src/app/core/services/user.service';

import {
  getOptionsLoyalty,
  removeLoyaltyUrl,
  saveLoyaltyUrl
} from 'src/app/core/state/actions/ui.actions';
import { selectFeatureOptionsLoyalty, selectFeatureUrlLoyalty } from 'src/app/core/state/reducers/ui.reducer';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

@Injectable({
  providedIn: 'root'
})
export class SidebarLoyaltyService {
  private options: OptionsLoyalty[];
  get loyaltyUrl$(): Observable<string> {
    return this.store.select(selectFeatureUrlLoyalty);
  }
  get optionsLoyalty$(): Observable<OptionsLoyalty[]> {
    return this.store.select(selectFeatureOptionsLoyalty);
  }
  subscription: Subscription;

  constructor(
    private store: Store<{ client: Client, user: UserInfo }>,
    private router: Router,
    private navbarMenuMobileService: NavbarMenuMobileService,
    private clientService: ClientService,
    private userService: UserService,
  ) { }

  initLoyaltyLogic(): void {
    this.subscription = new Subscription;
    
    if (this.isFeatureAvailable()) {
      this.getOptionsLoyalty();
    }
  }

  private isFeatureAvailable(): boolean {
    return env.isFeatureAvailable(FEATURES.LOYALTY);
  }

  private getOptionsLoyalty(): void {
    this.subscription.add(
      this.clientService.getClientUntilChanged$().subscribe({
        next: (client) => {
          this.store.dispatch(getOptionsLoyalty({ clientId: client.clientId }));
        }
      })
    );
  }

  setupLoyaltyOptions(menuItems: any) {
    this.optionsLoyalty$.subscribe({
      next: (options) => {
        this.options = options;
        menuItems.forEach((item) => {
          if (item.langKey === 'MY_REWARDS') {
            if (this.canShowLoyaltyOptions()) {
              item.show = true;
              item.options = this.options;
              this.setupOptionDisplay();
              return;
            }
            item.show = false;
          }
        });
      }
    });
  }

  showItemMenuDigitalPromotions(menuItems: any) {
    this.optionsLoyalty$.subscribe({
      next: (options) => {
        this.options = options;
        menuItems.forEach((item) => {
          if (item.langKey === 'DIGITAL_PROMOTIONS') {
            if (this.userService.user.countryId === CountryCodes.PARAGUAY) {
              item.show = this.canShowLoyaltyOptions();
            }
          }
        });
      }
    });
  }

  private canShowLoyaltyOptions(): boolean {
    return this.options.length !== 0;
  }

  private setupOptionDisplay(): void {
    const optionMapping = {
      award_wallet: { label: 'Mis Puntos', icon: 'tabler-award-wallet' },
      bingo: { label: 'Mi Bingo', icon: 'tabler-bingo' },
      gana_tool: { label: 'Mis Objetivos', icon: 'tabler-target-arrow' },
      foto_tool: { label: 'Mis Fotos', icon: 'tabler-camera' },
    };

    this.options.forEach((option) => {
      const mapping = optionMapping[option.code];
      if (mapping) {
        option.label = mapping.label;
        option.icon = mapping.icon;
      }
    });
  }

  saveURLintoStore(url: string): void {
    this.store.dispatch(saveLoyaltyUrl({ url }));
    this.navbarMenuMobileService.openCloseNavBarMenuMobile();
    this.router.navigate(['/main/loyalty']);
  }

  removeUrlOfStore(): void {
    this.store.dispatch(removeLoyaltyUrl());
  }
}
