import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethod } from 'src/app/core/models/payment-method.model';

@Pipe({
  name: 'isCreditCategory',
})
export class IsCreditCategoryPipe implements PipeTransform {
  transform(pyamentMethod: PaymentMethod): boolean {
    return pyamentMethod?.showCreditBalance;
  }
}
