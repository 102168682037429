<div class="select-commerce-container">
  <div class="select-commerce-title" *ngIf="isMobile">
    {{ title | translate }}
    <button class="cancel-button" (click)="closeModal()">
      {{ cancel | translate }}
    </button>
  </div>
  <div class="content">
    <ng-container *ngIf="clients">
      <div *ngFor="let client of clients" class="content-item">
        <ng-container *ngIf="client.clientId !== selectedClient.clientId">
          <button class="client-button" (click)="selectClient(client)">
            <div class="commerce-info">
              {{ client.commerceInfo }}
            </div>
            <div class="combo-with-icon-arrow">
              <svg>
                <use
                  xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-right"
                />
              </svg>
            </div>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
