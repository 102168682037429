import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';

@Pipe({
  name: 'kaDiscountsApplyProducts',
})
export class KaDiscountsApplyProducts implements PipeTransform {
  transform(products: Product[], product: Product): Product {
    const discountProduct = products.find(
      (value) => value.productId === product.productId,
    );
    return discountProduct?.price?.finalPrice;
  }
}
