import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ModalDesktopDeliveryDateService } from 'src/app/shared/components/select-delivery-date/services/modal-desktop-delivery-date.service';
import { ModalMobileDeliveryDateService } from 'src/app/shared/components/select-delivery-date/services/modal-mobile-delivery-date.service';
import { SelectDeliveryDateInfoService } from 'src/app/shared/components/select-delivery-date/services/select-delivery-date-info.service';
import { ModalDesktopFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';
import { ModalMobileFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { SelectFrozenDeliveryDateInfoService } from 'src/app/shared/components/select-frozen-delivery-date/services/select-frozen-delivery-date-info.service';
import { IsMobileService } from './is-mobile/is-mobile.service';

import { CartService } from './cart.service';
import { Cart } from '../models/cart.model';
import { updateAllProducts } from '../state/actions/cart.actions';
import { VisitDatesService } from './visit-date.service';
import { VisitFrozenDatesService } from './visit-frozen-dates.service';

@Injectable({
  providedIn: 'root'
})
export class ExpireDateService {
  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }
  get cart$(): Observable<Cart> {
    return this._cartService.cart$;
  }

  constructor(
    private _visitDateService: VisitDatesService,
    private _visitFrozenDateService: VisitFrozenDatesService,
    private _store: Store,
    private _isMobileService: IsMobileService,
    private _modalDesktopDeliveryDateService: ModalDesktopDeliveryDateService,
    private _modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
    private _modalMobileFrozenDeliveryDateService: ModalMobileFrozenDeliveryDateService,
    private _modalDesktopFrozenDeliveryDateService: ModalDesktopFrozenDeliveryDateService,
    private _selectDeliveryDateInfoService: SelectDeliveryDateInfoService,
    private _selectFrozenDeliveryDateInfoService: SelectFrozenDeliveryDateInfoService,
    private _router: Router,
    private _cartService: CartService
  ) { }

  expireDeadLineFrozenDeliveryDate(): void {
    this._selectFrozenDeliveryDateInfoService.setTitle(
      'NEW_ORDER.DELIVERY_PLAN.EXPIRE_DATE_TITLE'
    );
    this._selectFrozenDeliveryDateInfoService.setSubTitle(
      'NEW_ORDER.DELIVERY_PLAN.EXPIRE_DATE_SUBTITLE'
    );
    this.loadFrozenVisitDates();
    
    this.isMobile
      ? this.handleMobileDeliveryFrozenDateModal()
      : this.handleDeskDeliveryFrozenDateModal();
  }

  private loadFrozenVisitDates(): void {
    this._visitFrozenDateService.loadFrozenDeliveryDates();
  }

  private handleMobileDeliveryFrozenDateModal(): void {
    this._modalMobileFrozenDeliveryDateService.openModal();
    this._modalMobileFrozenDeliveryDateService.isCloseModal$
      .pipe(take(1)).subscribe({
        next: (isClose) => {
          if (isClose) {
            this.closeExpireDeadLineModal();
          }
        }
      });
  }

  private closeExpireDeadLineModal(): void {
    if (this._router.url.includes('nuevo-pedido/1')) {
      return;
    }

    this.updateAllProducts();
  }

  private updateAllProducts(): void {
    this.cart$.pipe(take(1)).subscribe({
      next: (cart) => {
        this._store.dispatch(updateAllProducts({ products: cart.products }));
      }
    });
  }

  private handleDeskDeliveryFrozenDateModal(): void {
    this._modalDesktopFrozenDeliveryDateService.openModal()
      .result.then(() => {
        this.closeExpireDeadLineModal();
      });
  }

  expireDeadLineDeliveryDate(): void {
    this._selectDeliveryDateInfoService.setTitle(
      'NEW_ORDER.DELIVERY_PLAN.EXPIRE_DATE_TITLE'
    );
    this._selectDeliveryDateInfoService.setSubTitle(
      'NEW_ORDER.DELIVERY_PLAN.EXPIRE_DATE_SUBTITLE'
    );
    this.loadVisitDates();

    this.isMobile
      ? this.handleMobileDeliveryDateModal()
      : this.handleDeskDeliveryDateModal();
  }

  private loadVisitDates(): void {
    this._visitDateService.loadDeliveryDates();
  }

  private handleMobileDeliveryDateModal(): void {
    this._modalMobileDeliveryDateService.canCloseWithShadow = false;
    this._modalMobileDeliveryDateService.openModal();
    this._modalMobileDeliveryDateService.isCloseModal$
      .pipe(take(1)).subscribe({
        next: (isClose) => {
          if (isClose) {
            this.closeExpireDeadLineModal();
          }
        }
      });
  }

  private handleDeskDeliveryDateModal(): void {
    this._modalDesktopDeliveryDateService.backdrop = 'static';
    this._modalDesktopDeliveryDateService.openModal()
      .result.then(() => {
        this.closeExpireDeadLineModal();
      });
  }
}
