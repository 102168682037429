<div *ngIf="showButton" class="category_container">
  <div class="category">
    <img
      class="category_img"
      [ngClass]="{ suggested: category === 'suggested' }"
      [class.selected]="selected"
      [src]="image"
      (click)="goToCategoryEvent()"
      (error)="handleError()"
    />
    <div
      *ngIf="category === 'suggested'"
      appNewLabel
      [@fadeIn]
      (click)="goToCategoryEvent()"
    ></div>
  </div>
  <p class="category_text">{{ text }}</p>
</div>
