<div class="card d-flex flex-row align-items-center">
  <div class="image">
    <svg>
      <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-map-pin" />
    </svg>
  </div>
  <div>
    <p class="mid-strong">{{ ROOT_LANG + 'DELIVERY_ADDRESS' | translate }}:</p>
    <p>{{ client.street }} {{ client.doorNumber }}</p>
  </div>
</div>
