<ul>
  <li
    *ngFor="let item of config.items"
    (click)="clickItemEvent.emit(item)"
    [ngClass]="{ 'one-item': config.items.length === 1 }"
  >
    <span>{{ item[config.key] | titleCaseFirstWord }}</span>
    <svg class="icon">
      <use
        [attr.xlink:href]="
          './assets/icons/tabler-sprite.svg#tabler-chevron-right'
        "
      />
    </svg>
  </li>
</ul>
