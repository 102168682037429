import { Pipe, PipeTransform } from '@angular/core';
import { CategoriesName } from 'src/app/core/enums/categories-name.enum';

@Pipe({
  name: 'getDeliveryDate'
})
export class GetDeliveryDatePipe implements PipeTransform {
  readonly CategoriesName = CategoriesName;

  transform(sectorName: string, deliveryDateFrozen: string, deliveryDate: string) {
    return sectorName.toUpperCase() === CategoriesName.CONGELADOS
      ? deliveryDateFrozen
      : deliveryDate;
  }

}
