<div class="btn-quantity d-flex flex-row align-items-center justify-content-center" 
    [ngClass]="{
      'flex-lg-column flex-xl-row': simpleProduct, 
      'flex-column': onCart, 
      'flex-xs-column-important': bomRowView}">
  <ng-container *ngIf="!unavailable">
    <div class="quantity p-3 d-flex flex-row align-items-center justify-content-around bg-white text-center w-100"
      [ngClass]="{
        'mb-lg-2 mb-xl-0': simpleProduct, 
        'mb-2': onCart,
        'margin-xs-important': bomRowView}">
      <span (click)="removeProduct()" class="noselect"
        [ngClass]="{'disabled' : product.quantitySelected <= (product.subunitSelected === 'BOT' ? product.subUnit.multiple : 1)}">
        <fa-icon icon="minus"></fa-icon>
      </span>
        <div [ngbTooltip]="
             product.quantitySelected + minSubunit > maxOrderQuantity
                  ? (ROOT_LANG + 'TOOLTIP_MAXORDER'
                  | translate
                    : { productsMaxQuantityAllowed: maxOrderQuantity })
                  : ''
            " tooltipClass="custom-tooltip" placement="top" triggers="hover" class="w-100">       
            <input  *ngIf="!enablePYToolType"  type="number" [(ngModel)]="product.quantitySelected" (keydown)="validQuantLength($event)" (keyup)="validMaxQuantity($event)"
            (paste)="onQuantityPaste($event)" (blur)="blurValidation($event)" (input)="getScaleByInput($event.target.value)"
            [ngbTooltip]="(subUnit === 'BOT' && !showOnlyFirstTime)
                    ? (ROOT_LANG + 'TOOLTIP' | translate : { quantityBox: maxOrderQuantity })
                    : (product?.quantitySelected === selectedProductOnCart?.quantity ? (ROOT_LANG + 'SAME_QUANTITY' | translate) : '')"
            tooltipClass="custom-tooltip tooltip-input-qty" triggers="click" #tooltip="ngbTooltip" id="quantitySelected"
            pattern="[0-9]*" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
            <input  *ngIf="enablePYToolType"  type="number" [(ngModel)]="product.quantitySelected" (keydown)="validQuantLength($event)" (keyup)="validMaxQuantity($event)"
            (paste)="onQuantityPaste($event)" (blur)="blurValidation($event)" (input)="getScaleByInput($event.target.value)"
            [ngbTooltip]="(subUnit === 'BOT' && !showOnlyFirstTime)
                    ?            (ROOT_LANG + 'TOOLTIP' | translate )
                    : (product?.quantitySelected === selectedProductOnCart?.quantity ? (ROOT_LANG + 'SAME_QUANTITY' | translate) : '')"
            tooltipClass="custom-tooltip tooltip-input-qty" triggers="click" #tooltip="ngbTooltip" id="quantitySelected"
            pattern="[0-9]*" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
        </div>
      <span (click)="addProduct()" class="noselect"
        [ngClass]="{'disabled' : product.quantitySelected + minSubunit > maxOrderQuantity}"
        #maxQuantityTooltip="ngbTooltip"
        [ngbTooltip]="product.quantitySelected + minSubunit > maxOrderQuantity
                  ? (ROOT_LANG + 'TOOLTIP_MAXORDER'| translate: { productsMaxQuantityAllowed: maxOrderQuantity })
                  : ''"
        tooltipClass="custom-tooltip" placement="top" triggers="hover" closeDelay="3000">
        <fa-icon icon="plus"></fa-icon>
      </span>
    </div>
    <div class="w-100 ms-1">
      <span *ngIf="!subUnitAvailable">
        <button
            [disabled]="!product.quantitySelected || product.quantitySelected < 1 || !isInteger(product.quantitySelected)"
            class="btn-pill-add noselect w-100"
            (click)="addProductToCart(product)"
            [ngClass]="
              {'ml-md-0 ml-xl-2': simpleProduct,
              'p-4': !selectedProductOnCart || (selectedProductOnCart && product.quantitySelected !== selectedProductOnCart.quantity),
              'px-3 py-4': selectedProductOnCart && product.quantitySelected === selectedProductOnCart.quantity}"
            >
          <app-ka-add-to-cart-button-label [selectedProductOnCart]="selectedProductOnCart" [product]="product"></app-ka-add-to-cart-button-label>
        </button>
      </span>
      <span  *ngIf="subUnitAvailable"
             tooltipClass= "custom-tooltip"
             [autoClose]="true"
             placement="top"
             [ngbTooltip]="(subUnit === 'noSubunits' ? (ROOT_LANG + 'SELECT_SUBUNIT' | translate) : '')"
             #tooltip2="ngbTooltip">
        <button
            [disabled]="!product.quantitySelected || product.quantitySelected < 1 || subUnit === 'noSubunits' ? true : false || !isInteger(product.quantitySelected)"
            class="btn-pill-add noselect w-100"
            (click)="addProductToCart(product)"
            [ngClass]="
              {'ml-md-0 ml-xl-2': simpleProduct,
              'p-4': !selectedProductOnCart || (selectedProductOnCart && product.quantitySelected !== selectedProductOnCart.quantity),
              'px-3 py-4': selectedProductOnCart && product.quantitySelected === selectedProductOnCart.quantity}"
          >
            <app-ka-add-to-cart-button-label [selectedProductOnCart]="selectedProductOnCart" [product]="product"></app-ka-add-to-cart-button-label>
        </button>
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="unavailable">
    <button class="btn-pill-add w-auto" disabled>{{ ROOT_LANG + 'UNAVAILABLE' | translate }}</button>
  </ng-container>
</div>
