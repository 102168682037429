import { Injectable } from '@angular/core';
import { ConnectionType } from '../enums/connection-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ConnectionSpeedService {
  private readonly ConnectionType = ConnectionType;

  getConnectionSpeed(): ConnectionType {
    if ('connection' in navigator) {
      const connection = navigator.connection as any;
      return connection.downlink <= 2 || connection.saveData
        ? this.ConnectionType.LOW
        : this.ConnectionType.FAST;
    } else {
      return this.ConnectionType.FAST;
    }
  }
}
