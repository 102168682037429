import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '../../core/models/discount.model';
import { Product } from '../../core/models/product.model';

@Pipe({
  name: 'getUnitPriceCC',
})
export class GetUnitPriceCCPipe implements PipeTransform {
  transform(discount: Discount): number {
    return discount.requirements.reduce(
      (acc, cur: Product) => acc + cur.quantity * cur.price.priceBySubUnit,
      0
    );
  }
}
