import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { BERespModel } from '../models/backend/BE-response.model';
import { Store } from '@ngrx/store';
import { Client } from '../models/client.model';
import { Product } from '../models/product.model';
import { alertType } from '@components/alert/alert.component';

export type notificationInfo = {
  'alert-type': alertType;
  delay: number;
  text: string;
};

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private notifications: Subject<notificationInfo> =
    new Subject<notificationInfo>();
  clientId: number;
  notificationTypes = {
    ADD: {
      type:'ADD',
      'alert-type': 'success',
      delay: 3000,
      text: 'NEW_ORDER.FAVORITES_NOTIFICATIONS.ADD',
      icon: 'fav-add.svg',
    },
    REMOVE: {
      type:'REMOVE',
      'alert-type': 'warning',
      delay: 3000,
      text: 'NEW_ORDER.FAVORITES_NOTIFICATIONS.REMOVE',
      icon: 'fav-remove.svg',
    },
    ERROR: {
      type:'ERROR',
      'alert-type': 'warning',
      delay: 3000,
      text: 'NEW_ORDER.FAVORITES_NOTIFICATIONS.ERROR',
      icon: 'fav-error.svg',
    },
  };

  constructor(
    private apiSrv: ApiService,
    private store: Store<{ client: Client }>,
  ) {
    this.store
      .select('client')
      .subscribe((client) => (this.clientId = client.clientId));
  }

  addFavoriteProduct(product: Product): Observable<BERespModel> {
    return this.apiSrv.post(
      `clients/${this.clientId}/favorite`,
      EndpointsCodes.POST_FAVORITE,
      { productId: product.productId },
      {
        targetApi: 'serverUrlPublic',
        showError: false,
      },
    );
  }

  removeFavoriteProduct(product: Product): Observable<BERespModel> {
    return this.apiSrv.delete(
      `clients/${this.clientId}/favorite/${product.productId}`,
      EndpointsCodes.DELETE_FAVORITE,
      {},
      { targetApi: 'serverUrlPublic' },
    );
  }

  getSharingFavoriteNotifications(): Observable<notificationInfo> {
    return this.notifications.asObservable();
  }

  dispatachFavoriteAlert(notificationType: string) {
    this.notifications.next(this.notificationTypes[notificationType]);
  }
}