import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/core/models/cart.model';
import { Product } from 'src/app/core/models/product.model';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import { CartService } from 'src/app/core/services/cart.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../../../core/services/products.service';
import { ProductPrice } from '../../../core/models/product-price.model';
import { Client } from '../../../core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { delay, switchMap } from 'rxjs/operators';
import { OrdersService } from 'src/app/pages/my-orders/services/orders.service';
import { ReturnMaterialsService } from 'src/app/core/services/return-materials.service';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

@Component({
  selector: 'app-ka-add-to-cart-button',
  templateUrl: './ka-add-to-cart-button.component.html',
  styleUrls: ['./ka-add-to-cart-button.component.scss'],
})
export class KaAddToCartButtonComponent implements OnInit, OnDestroy {
  readonly ROOT_LANG = 'NEW_ORDER.BUTTON_ADD.';
  private subscriptions = new Subscription();
  @Input() product: Product;
  @Input() simpleProduct?: boolean;
  @Input() subUnit?: string;
  @Input() onCart?: boolean;
  @Input() stockDigital = false;
  @Input() bomRowView?: boolean;
  @Output() currentScaleDiscount = new EventEmitter<ProductPrice>();
  @Output() hasAddedProduct = new EventEmitter<boolean>();
  products: Product[];
  user: UserInfo;
  cart: Cart;
  pill = 0;
  unavailable = false;
  selectedProductOnCart: Product;
  tooltipOpened = false;
  scales: any;
  showOnlyFirstTime = false;
  subUnitAvailable = false;
  minSubunit: number;
  isSubunit: boolean;
  maxOrderQuantity: number;
  enablePYToolType = false; 


  @ViewChild('tooltip') tooltip: NgbTooltip;
  @ViewChild('maxQuantityTooltip') maxQuantityTooltip: NgbTooltip;

  @HostListener('document:click', ['$event.target', '$event'])
  onClick(targetElement, event) {
    if (targetElement.id === 'quantitySelected') {
      event.preventDefault();
      this.tooltip.close();
    }
    if (this.product) {
      this.checkIfSubunitChanged();
      this.selectedProductOnCart = this.searchProductOnCart();
    }
  }

  constructor(
    private store: Store<{ cart: Cart; client: Client; user: UserInfo }>,
    private cartService: CartService,
    private productsService: ProductsService,
    private orderService: OrdersService,
    private returnMatService: ReturnMaterialsService
  ) {
    this.subscriptions.add(
      this.store
        .select('client')
        .subscribe(
          (client) => (this.subUnitAvailable = client.subUnitAvailable),
        ),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;

        if (this.product)
          this.selectedProductOnCart = this.searchProductOnCart();

        this.checkIfSubunitChanged();

        if (this.cart?.products && this.product) {
          const productQuantity = this.product?.suggestedProduct?.isSuggested
            ? this.product.suggestedProduct.quantity
            : this.product.quantitySelected;
          const subUnitQuantity = this.isSubunit
            ? this.minSubunit
            : productQuantity;
          this.pill = this.selectedProductOnCart
            ? this.selectedProductOnCart?.quantity
            : subUnitQuantity;
        }

        if (this.product) {
          if (
            !this.selectedProductOnCart &&
            this.product.quantitySelected > 1
          ) {
            const minSubunitQuantity =
              this.subUnit === 'BOT' && this.product.subUnit.multiple
                ? this.product.subUnit.multiple
                : 1;
            this.product.quantitySelected =
              this.product.suggestedProduct?.isSuggested && !this.isSubunit
                ? this.product.suggestedProduct.quantity
                : minSubunitQuantity;
          } else {
            this.product.quantitySelected = this.pill;
          }
        }
      }),
    );
    this.enablePYToolType = this.user.countryId === CountryCodes.PARAGUAY;
  }

  ngOnInit(): void {
    this.unavailable = this.product.locked;

    if (this.product) {
      this.checkIfSubunitChanged();
      this.selectedProductOnCart = this.searchProductOnCart();
    }

    if (this.product?.suggestedProduct?.isSuggested) {
      if (this.cart.products) {
        const productQuantity = this.isSubunit
          ? this.minSubunit
          : this.selectedProductOnCart?.quantity ||
            this.product.suggestedProduct.quantity;
        this.pill = this.selectedProductOnCart
          ? this.selectedProductOnCart?.quantity
          : productQuantity;
      } else {
        this.pill = this.isSubunit
          ? this.minSubunit
          : this.product.suggestedProduct.quantity;
      }
    } else {
      if (this.cart.products && this.product) {
        const quantity =
          this.product.quantitySelected > 1
            ? this.product.quantitySelected
            : this.selectedProductOnCart?.quantity ||
              this.product.quantitySelected;
        this.pill = quantity;
      } else {
        this.pill = this.isSubunit ? this.minSubunit : 1;
      }
    }

    if (this.product) this.product.quantitySelected = this.pill;
    if (this.product.relatedDiscount) {
      this.getScaleDiscount();
    }
    this.determineMaxOrderQuantity();
  }

  addProduct(): void {
    this.checkIfSubunitChanged();
    this.determineMaxOrderQuantity();
    if (
      this.product.quantitySelected + this.minSubunit >=
      this.maxOrderQuantity
    ) {
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
    }
    if (this.product.quantitySelected + this.minSubunit > this.maxOrderQuantity)
      return;

    this.product.quantitySelected =
      this.product.quantitySelected + this.minSubunit;

    if (this.product.relatedDiscount) {
      this.updateScale();
    }
  }

  removeProduct(): void {
    this.checkIfSubunitChanged();
    if (
      this.product.quantitySelected >
      (this.subUnit === 'BOT' && this.minSubunit ? this.minSubunit : 1)
    ) {
      if (this.subUnit === 'BOT' && this.minSubunit) {
        this.product.quantitySelected =
          this.product.quantitySelected - this.minSubunit;
      } else {
        this.product.quantitySelected--;
      }

      if (this.product.relatedDiscount) {
        this.updateScale();
      }
    }
  }

  checkIfSubunitChanged() {
    this.isSubunit =
      this.product?.subunitSelected === 'BOT' || this.subUnit === 'BOT';
    this.minSubunit =
      this.isSubunit && this.product.subUnit.multiple
        ? this.product.subUnit.multiple
        : 1;
  }

  searchProductOnCart() {
    return this.product.enabledToSellBySubUnit && this.subUnitAvailable
      ? this.cart.products.find(
          (product) =>
            product.productId === this.product.productId &&
            product.subunitSelected === this.subUnit,
        )
      : this.cart.products.find(
          (product) => product.productId === this.product.productId,
        );
  }

  addProductToCart(product: Product): void {
    this.determineMaxOrderQuantity();

    if (product.quantitySelected > this.maxOrderQuantity) return;

    if(this.returnMatService.checkMaterialList(this.product.erpProductId)){
      this.returnMatService.dispatchNotification(true);
      setTimeout(()=> this.returnMatService.dispatchNotification(false),8000);
    }

    const cartProduct = { ...product };
    this.selectedProductOnCart = this.searchProductOnCart();
    if (this.subUnitAvailable)
      cartProduct.subunitSelected = this.subUnit
        ? this.subUnit
        : product.erpUnitMeasureId;

    if (product.enabledToSellBySubUnit && this.subUnitAvailable) {
      if (
        this.subUnit === 'BOT' &&
        this.product.quantity !== this.product.subUnit.quantity &&
        !this.showOnlyFirstTime
      ) {
        this.showOnlyFirstTime = true;
        this.tooltipOpened = false;
        this.openTooltip('fromBot');
      }
    }

    if (
      this.product?.quantitySelected === this.selectedProductOnCart?.quantity &&
      this.subUnitAvailable
    ) {
      this.tooltipOpened = false;
      this.openTooltip('fromQty');
      return;
    }

    if (
      this.product?.quantitySelected === this.selectedProductOnCart?.quantity &&
      !this.subUnitAvailable
    )
      return;
    if (
      !this.product?.quantitySelected ||
      this.product?.quantitySelected < 1 ||
      !this.isInteger(product.quantitySelected)
    )
      return;

    if (this.cart.products && this.product) {
      cartProduct.quantitySelected =
        this.product.quantitySelected -
        (this.selectedProductOnCart?.quantity || 0);
    } else {
      cartProduct.quantitySelected = this.product.quantitySelected;
    }

    if (
      product.deliveryType === 'deliveryfrozen' &&
      !this.cart.hasDeliveryFrozenProducts
    ) {
      this.store.dispatch(
        CartActions.deliveryFrozenActions({ product: cartProduct }),
      );
    } else {
      this.store.dispatch(CartActions.upsertProduct({ product: cartProduct }));
      this.cartService.updateDeliveryProducts();
      if(this.stockDigital)
        this.checkStock(product.erpProductId, product);
    }
  }

  isInteger(quantity: number): boolean {
    return Number.isInteger(quantity);
  }

  blurValidation(event): void {
    this.checkIfSubunitChanged();
    const minQuantity =
      this.subUnit === 'BOT' && this.minSubunit ? this.minSubunit : 1;

    if (
      !this.product.quantitySelected ||
      this.product.quantitySelected < minQuantity ||
      !this.isInteger(this.product.quantitySelected)
    ) {
      this.product.quantitySelected = minQuantity;
      this.pill = minQuantity;
      event.preventDefault();
    }

    if (this.subUnit === 'BOT') {
      const value = this.product.quantitySelected / this.minSubunit;
      this.product.quantitySelected = Math.ceil(value) * this.minSubunit;
    }

    if (this.product.quantitySelected > this.product.maxOrderQuantity) {
      this.product.quantitySelected = this.isSubunit
        ? this.product.quantitySelected - this.minSubunit
        : this.product.quantitySelected;
    }
  }

  validQuantLength(event): void {
    const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (this.product.quantitySelected === 0) {
      this.product.quantitySelected = 1;
      this.pill = 1;
      event.preventDefault();
      return;
    }

    if (validKeys.some((validKey) => validKey === event.key)) return;

    this.determineMaxOrderQuantity();

    if (
      event.target.value.length >
      this.maxOrderQuantity.toString().length - 1
    ) {
      event.preventDefault();
      return;
    }

    if (
      this.product.quantitySelected > this.maxOrderQuantity ||
      !/^\d$/.test(event.key)
    )
      event.preventDefault();
  }

  validMaxQuantity(event): void {
    this.determineMaxOrderQuantity();
    if (Number(event.target.value) > this.maxOrderQuantity) {
      this.pill = this.maxOrderQuantity;
      this.product.quantitySelected = this.pill;
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
      event.preventDefault();
    }
  }

  determineMaxOrderQuantity() {
    const isBottleSelected =
      this.product.subunitSelected === 'BOT' ||
      this.product.erpUnitMeasureId === 'BOT';
    this.maxOrderQuantity = isBottleSelected
      ? this.product.maxOrderQuantityBottle
      : this.product.maxOrderQuantityBox;
  }

  onQuantityPaste(event): void {
    this.determineMaxOrderQuantity();
    const amount = event.clipboardData.getData('text/plain');

    if (amount > this.maxOrderQuantity) {
      this.pill = this.maxOrderQuantity;
      this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
    } else {
      this.pill = parseInt(amount);
    }
    this.product.quantitySelected = this.pill;
    event.preventDefault();
  }

  openTooltip(value) {
    if (value === 'fromBot') this.showOnlyFirstTime = true;
    if (!this.tooltipOpened) {
      this.tooltip.toggle();
      setTimeout(() => {
        this.tooltip.close();
      }, 3000);
      this.tooltipOpened = true;
    }
  }
  updateScale() {
    const currentScale = this.scales.find((scale) => {
      return (
        this.product.quantitySelected >= scale.min &&
        this.product.quantitySelected <= scale.max
      );
    });
    this.currentScaleDiscount.emit(currentScale?.price);
  }

  getScaleByInput(value: string) {
    this.product.quantitySelected = /^\d+$/.test(value) ? Number(value) : null;
    if (this.product.relatedDiscount) this.updateScale();
  }

  getScaleDiscount() {
    this.productsService
      .getDiscount(true, this.product.relatedDiscount)
      .subscribe((res) => {
        this.scales = res['requirements'].scales;
        this.currentScaleDiscount.emit(this.scales[0].price);
      });
  }

  showTooltipForThreeSeconds(tooltip) {
    if (!tooltip.isOpen()) {
      of(tooltip.open())
        .pipe(delay(3000))
        .subscribe({ next: () => tooltip.close() });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkStock(id: string, product: Product) {
    this.subscriptions.add(
      this.orderService
        .isStockDigitalAvailable()
        .pipe(
          switchMap((res) => {
            if (res?.data?.isStockDigitalEnabled) {
              return this.orderService.checkStockDigital(id);
            }
          }),
        )
        .subscribe(
          (stockDigitalCheck) => {
            if (Object.keys(stockDigitalCheck)?.length > 0) {
              this.orderService.showStockDigitalModal([product], () => {
                return;
              });
            }
          },
          (err) => console.error(err),
        ),
    );
  }
}
