<div class="available-credits">
  <div ngbAccordion class="accordion">
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="btn-available-credits-accordion">
            <div class="item">
              <div class="image">
                <img src=".././assets/icons/available-credits.svg" alt="Creditos disponibles" srcset="" />
              </div>
              <div class="content">
                <div class="text">
                  <span>{{ 'NEW_ORDER.RIGHT_SIDEBAR.AVAILABLE_CREDITS_TITLE' | translate }}</span>
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="cart.orderId">
                <div *ngFor="let segment of cartCredits.processedList; last as isLast">
                  <div *ngIf="checkCredit(segment)" class="list-item" [ngClass]="{ danger: segment.status !== 'OK' }">
                    <span>{{ segment.segmentName | kaCategories }}</span>
                    <strong>{{ segment.newAvailable | kaCredits }}</strong>
                  </div>
                </div>
              </div>
      
              <div *ngIf="!cart.orderId || cartCredits.length < 2">
                <div *ngFor="let segment of credits">
                  <div class="list-item">
                    <span>{{ segment.Segment.name | kaCategories }}</span>
                    <strong>{{ segment.available | kaCredits }}</strong>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
  </div>
</div>
