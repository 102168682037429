import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import {Product} from "../../../core/models/product.model";
import { Router } from '@angular/router';

@Component({
  selector: 'app-ka-subunits',
  templateUrl: './ka-subunits.component.html'
})
export class KaSubunitsComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() product?: Product;
  @Output() subUnitChanged = new EventEmitter<string>();
  subUnits: any;
  cart: Cart;
  currentUrl: string;
  isHomePage: boolean;

  constructor(
    private store: Store<{ cart: Cart }>,
    private router: Router
  ) {
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.isHomePage = /\/home$/.test(this.currentUrl);
      
    if(this.product.enabledToSellBySubUnit) {
      this.subUnits = [
        {id: this.product.subUnit.erpMeasureUnitId, value: this.product.subUnit.name},
        {id: this.product.erpUnitMeasureId, value: this.product.unitMeasure}
      ]
    }
  }

  subunitSelected(subUnit) {
    this.product.subunitSelected = subUnit?.id ? subUnit?.id : 'noSubunits';
    this.subUnitChanged.emit(this.product.subunitSelected);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
