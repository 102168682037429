import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import * as CartActions from 'src/app/core/state/actions/cart.actions';

@Component({
  selector: 'app-ka-perceptions-warning',
  templateUrl: './ka-perceptions-warning.component.html'
})
export class KaPerceptionsWarningComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  cart: Cart;
  
  constructor(private store: Store<{ cart: Cart; }>,) {
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
  }

  ngOnInit() {
    this.store.dispatch(CartActions.hasPerceptionAlertOpen({ hasPerceptionAlertOpen: true }));
  }

  alertClosed() {
    this.store.dispatch(CartActions.hasPerceptionAlertOpen({ hasPerceptionAlertOpen: false }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
