import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { Order } from 'src/app/core/models/order.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { OrdersService } from 'src/app/pages/my-orders/services/orders.service';

@Component({
  selector: 'app-ka-order-status',
  templateUrl: './ka-order-status.component.html',
  styleUrls: ['./ka-order-status.component.scss'],
})
export class KaOrderStatusComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly ROOT_LANG = 'ORDER_STATUS.';
  lastOrder: Order;
  user: UserInfo;

  constructor(private store: Store<{ client: Client, user: UserInfo }>, private router: Router, public ordersService: OrdersService) {
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.user = user)));
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.lastOrder = client.data?.lastOrder;
      })
    );
  }

  ngOnInit(): void {}

  goOrderDetail(): void {
    const route = this.user.uuid ? '/mcc/mis-pedidos/detalles/' : '/main/mis-pedidos/detalles/';
    this.router.navigate([route + this.lastOrder.orderId]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
