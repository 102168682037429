import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReturnMaterialsService {
  private ERP_RETURN_MATERIAL_LIST = ['000000000000120119'];
  private notification: Subject<boolean> = new Subject<boolean>();

  getSharingNotifications(): Observable<boolean> {
    return this.notification.asObservable();
  }

  dispatchNotification(showNoti: boolean) {
    this.notification.next(showNoti);
  }

  checkMaterialList(erpProductId: string): boolean {
    return this.ERP_RETURN_MATERIAL_LIST.includes(erpProductId);
  }
}
