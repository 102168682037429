import { Pipe, PipeTransform } from '@angular/core';
import { DiscountScale } from '../../core/models/discount-scale.model';
import { Discount } from '../../core/models/discount.model';

@Pipe({
  name: 'getScaleUnitPrice',
})
export class GetScaleUnitPricePipe implements PipeTransform {
  transform(discount: Discount, currentScale: DiscountScale): number {
    const curScale = currentScale;

    if (curScale.subUnitQuantity) {
      return curScale.price.finalPrice / curScale.subUnitQuantity;
    }

    if (discount?.requirements?.scales?.length) {
      return (
        curScale.price.finalPrice /
        discount.requirements?.scales[1].subUnitQuantity
      );
    }
  }
}
