<button
  [class]="config.style || 'primaryFilled'"
  [type]="config.type || 'button'"
  [disabled]="config.disabled || false"
  (click)="clickEvent.emit()"
>
  <ng-container *ngIf="config.icon?.position === 'left'">
    <ng-container *ngTemplateOutlet="IconButtonTemplate"></ng-container>
  </ng-container>

  {{ config.text }}

  <ng-container *ngIf="config.icon?.position === 'right'">
    <ng-container *ngTemplateOutlet="IconButtonTemplate"></ng-container>
  </ng-container>
</button>

<ng-template #IconButtonTemplate>
  <svg class="icon {{ config.icon?.position }}" stroke-width="3.25">
    <use [attr.xlink:href]="'./assets/icons/' + config.icon?.name" />
  </svg>
</ng-template>
