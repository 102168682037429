import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '../../core/models/discount.model';

@Pipe({
  name: 'isQuantitySelectedValid',
})
export class IsQuantitySelectedValidPipe implements PipeTransform {
  transform(discount: Discount): boolean {
    return (
      discount?.quantitySelected > 0 &&
      Number.isInteger(discount.quantitySelected)
    );
  }
}
