import { createReducer, createSelector, on } from '@ngrx/store';
import * as UiActions from '../actions/ui.actions'
import { OptionsLoyalty } from '../../models/loyalty/loyalty.model';
import { AppState } from '../app.state';

export interface UiState {
  loyalty: {
    options: OptionsLoyalty[],
    url: string,
  },
}

export const selectFeature = (state: AppState) => state.ui;

export const selectFeatureOptionsLoyalty = createSelector(
  selectFeature,
  (state: UiState) => state.loyalty.options,
);
export const selectFeatureUrlLoyalty = createSelector(
  selectFeature,
  (state: UiState) => state.loyalty.url,
);

export const initialState = {
  loyalty: {
    options: [],
    url: ''
  }
};
export const uiReducer = createReducer<UiState>(
  initialState,
  on(UiActions.successGetOptionsLoyalty, (state, props) => {
    return {
      ...state,
      loyalty: {
        ...state.loyalty,
        options: [...props.options]
      }
    };
  }),
  on(UiActions.failureGetOptionsLoyalty, (state) => {
    return {
      ...state,
      loyalty: {
        ...state.loyalty,
        options: []
      }
    };
  }),
  on(UiActions.resetOptionsLoyalty, (state) => {
    return {
      ...state,
      loyalty: {
        ...state.loyalty,
        options: []
      }
    };
  }),
  on(UiActions.saveLoyaltyUrl, (state, props) => {
    return {
      ...state,
      loyalty: {
        ...state.loyalty,
        url: props.url
      }
    };
  }),
  on(UiActions.removeLoyaltyUrl, (state) => {
    return {
      ...state,
      loyalty: {
        ...state.loyalty,
        url: ''
      }
    };
  }),
);