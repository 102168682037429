import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areAllMinimumsCompleted',
})
export class AreAllMinimumsCompletedPipe implements PipeTransform {
  transform(products: any): unknown {
    return products?.some((group) => !group.reachedLimit);
  }
}
