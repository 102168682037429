import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ppd-block',
  templateUrl: './ppd-block.component.html',
  styleUrls: ['./ppd-block.component.scss']
})
export class PpdBlockComponent{
  @Output() modalResponse: EventEmitter<any> = new EventEmitter();

  readonly ROOT_LANG = "PPD_MODAL.";

  constructor(
    public activeModal: NgbActiveModal) {
    }

  confirm(): void {
    this.activeModal.close(true);
  }
}
