<div [class]="'alert-container ' + type" [ngStyle]="{'padding': size + 'px', 'font-size': size + 'px'}">
  <div *ngIf="text" class="text-container">
    <div
      class="message"
      [ngStyle]="{ 'align-items': customIcon ? 'center' : 'flex-start' }"
    >
      <ng-container *ngIf="showLeftIcon && !customIcon">
        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="showLeftIcon && customIcon">
        <ng-container *ngTemplateOutlet="customIconTemplate"></ng-container>
      </ng-container>
      <span [innerHTML]="text"></span>
    </div>

    <svg
      *ngIf="showRightIcon"
      class="icon pointer"
      (click)="closeAlertEvent.emit()"
    >
      <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-x'" />
    </svg>
  </div>

  <ng-container *ngIf="button">
    <div class="mt-8"></div>
    <app-button
      [config]="button"
      (clickEvent)="buttonOnClick.emit()"
    ></app-button>
  </ng-container>

  <ng-container *ngIf="showMoreContent">
    <div *ngIf="text" class="mt-8"></div>
    <div class="more-content {{ class }}" [class.pt-8]="text">
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>

<ng-template #iconTemplate>
  <svg class="icon">
    <use [attr.xlink:href]="'./assets/icons/' + getIconUrl" />
  </svg>
</ng-template>

<ng-template #customIconTemplate>
  <img [src]="'../../../assets/icons/' + customIcon" alt="" srcset="" />
</ng-template>