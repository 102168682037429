import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '../../core/models/discount.model';
import { Product } from '../../core/models/product.model';

@Pipe({
  name: 'getTotalListPriceCC',
})
export class GetTotalListPriceCCPipe implements PipeTransform {
  transform(discount: Discount): number {
    return discount.requirements.reduce(
      (acc, cur: Product) =>
        acc + cur.quantity * cur.price.finalPriceWithoutDiscount,
      0
    );
  }
}
