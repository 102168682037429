import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface IStepByStepConfig {
  key: string;
}

@Component({
  selector: 'app-step-by-step',
  templateUrl: './step-by-step.component.html',
  styleUrls: ['./step-by-step.component.scss'],
})
export class StepByStepComponent {
  steps: unknown[] = [];

  @Input() config: IStepByStepConfig;
  @Output() clickItemEvent = new EventEmitter();

  addStep(item: unknown, key: string): void {
    const index = this.isItemIncluded(item, key);
    this.add(item, index);
  }

  resetSteps(items: unknown[]): void {
    this.steps = items;
  }

  private isItemIncluded(item: unknown, key: string): number {
    return this.steps.findIndex((localItem) => {
      return localItem[key] === item[key];
    });
  }

  private add(item: unknown, index: number): void {
    if (index === -1) {
      this.steps.push(item);
    } else {
      this.steps.splice(index + 1);
    }
  }
}
