import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { CategoriesName } from 'src/app/core/enums/categories-name.enum';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { UserInfo } from 'src/app/core/models/user-info.model';

@Pipe({
  name: 'kaCategories',
})
export class KaCategoriesPipe implements PipeTransform {
  user: UserInfo;
  CategoriesName = CategoriesName;
  readonly CountryCodes = CountryCodes;

  constructor(private store: Store<{ user: UserInfo }>) {
    this.store.select('user').subscribe((user) => (this.user = user));
  }

  transform(value: string): string {
    let parsedName: string = value;

    switch (this.user.countryId) {
      case CountryCodes.ARGENTINA:
        break;
      case CountryCodes.CHILE:
      case CountryCodes.PARAGUAY:
        if (CategoriesName.NART === value || CategoriesName.NARTD === value) {
          parsedName = 'BEBIDAS SIN ALCOHOL';
        }
        break;
      case CountryCodes.BRASIL:
        break;
      default:
    }
    return parsedName;
  }
}
