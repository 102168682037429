import { inject, Pipe, PipeTransform } from '@angular/core';
import { Cart } from '../../core/models/cart.model';
import { CartService } from '../../core/services/cart.service';

@Pipe({
  name: 'shouldContinueButtonDisable',
})
export class ShouldContinueButtonDisablePipe implements PipeTransform {
  cartService = inject(CartService);

  transform(cart?: Cart): unknown {
    return this.cartService.shouldContinueButtonDisable();
  }
}
