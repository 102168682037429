<div [class]="data.customClass">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button 
      *ngIf="!data.hideCloseButton" 
      type="button" 
      class="btn-close" 
      data-bs-dismiss="modal" 
      aria-label="Close" 
      (click)="activeModal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="data.image" class="d-flex justify-content-center align-items-center">
      <img class="not-found-img mb-3 w-50 p-4" [src]="'/assets/media/emojis/' + data.image" alt="" />
    </div>

    <h3 *ngIf="data.text" class="title my-2">{{ data.text | translate }}{{ data.type | translate }}</h3>

    <div *ngIf="data.type">
      <app-ka-select-delivery-date [visitType]="data.type" [titleTranslate]="'HOME.MY_DELIVERY_DATES_SELECT'"></app-ka-select-delivery-date>
    </div>

    <p *ngIf="data.description" class="description">{{ data.description | translate }}</p>
    <p *ngIf="data.additionalDescription" class="additional-description">{{data.additionalDescription | translate}}</p>

    <p *ngIf="data.whatsapp" class="whatsapp-description mt-4">{{ data.action | translate }} <a href="https://wa.me/{{ data.whatsapp | translate }}" target="_blank">{{ data.whatsapp | translate }}</a></p>
  </div>

  <div *ngIf="showFooterButtons" class="modal-footer d-flex justify-content-center">
    <button
      *ngIf="data.rejectBtnLabel"
      [id]="data.id + '-cancel-modal'"
      type="button"
      class="btn-square border-0"
      [ngClass]="{ 'btn-wide': data.btnWide }"
      (click)="reject()"
    >
      {{ data.rejectBtnLabel | translate }}
    </button>
    <button
      *ngIf="data.confirmBtnLabel"
      [id]="data.id + '-confirm-modal'"
      class="btn-square-black"
      [ngClass]="{ 'btn-wide': data.btnWide }"
      (click)="confirm()"
    >
      {{ data.confirmBtnLabel | translate }}
    </button>

    <a 
      *ngIf="data.whatsapp && user.countryId !== 'AR'"
      [id]="data.id + '-open-whatsapp'"
      class="btn-square-black text-center w-auto px-5 d-flex flex-row align-items-center justify-content-center"
      [href]="'https://wa.me/' + whatsapp"
      target="_blank"
    >
    <svg class="mr-8"><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-brand-whatsapp'" /></svg>
    {{ data.whatsappLabel | translate }}
  </a>
  </div>
</div>
