import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationMaxProduct',
})
export class NotificationMaxProductPipe implements PipeTransform {
  private readonly MAX_PRODUCT_QUANTITY = 99;
  private readonly DEFAULT_VALUE = '99+';

  transform(value: number): string {
    return value > this.MAX_PRODUCT_QUANTITY
      ? this.DEFAULT_VALUE
      : value.toString();
  }
}