import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';

@Component({
  selector: 'app-ka-delivery-address-widget',
  templateUrl: './ka-delivery-address-widget.component.html',
  styleUrls: ['./ka-delivery-address-widget.component.scss'],
})
export class KaDeliveryAddressWidgetComponent implements OnInit, OnDestroy {
  readonly ROOT_LANG = 'NEW_ORDER.ORDER_DETAIL.';
  client: Client;

  private subscriptions = new Subscription();

  constructor(private store: Store<{ client: Client }>) {
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.client = client)));
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
