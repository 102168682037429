import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncrDecrService {
  //The set method is use for encrypt the value.
  encrypt(keys, value): string {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  }

    encryptMCC (keys, value) {
    const keySize = 128 / 8;
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
      keySize,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    // add dummy iv
    const encryptedBytes = Buffer.from(encrypted.toString(), 'base64');
    const newValueBytes = [];
    if (newValueBytes.length < keySize) {
      const diff = keySize - newValueBytes.length;
      for (let i = 0; i < diff; i++) {
        newValueBytes.push(Math.floor(Math.random() * 255));
      }
  
    }
    for (const byte of encryptedBytes) {
      newValueBytes.push(byte);
    }
  
  
    return Buffer.from(newValueBytes).toString('base64');
  }
}
