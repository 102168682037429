import { Component, Input } from '@angular/core';
import { OrderStatus } from 'src/app/core/enums/order-status';

@Component({
  selector: 'app-ka-status-pill',
  templateUrl: './ka-status-pill.component.html',
  styleUrls: ['./ka-status-pill.component.scss'],
})
export class KaStatusPillComponent {
  @Input() status;
  @Input() isDiscount?;

  readonly ROOT_LANG = 'ORDER_STATUS.';
  readonly DISCOUNT_TEXT = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  readonly BOM = 'NEW_ORDER.DISCOUNTS.COMBO';

  getStatusClass() {
    switch (this.status) {
      case OrderStatus.created:
      case OrderStatus.DELIVERED:
      case OrderStatus.FAILED:
      case OrderStatus.REGISTERED:
      case OrderStatus.PREPARING:
      case OrderStatus.TRANSIT:
        return 'badge-warning';
      case OrderStatus.CANCELLATION_REQUEST:
      case OrderStatus.CANCELLED:
      case OrderStatus.RETURNED:
      case OrderStatus.BLOCKED:
      case OrderStatus.ORDER_NOT_PROCESSED:
      case OrderStatus.INACTIVE:
        return 'badge-danger';
      case OrderStatus.DELIVERED_CLT:
      case OrderStatus.PARTIAL_DELIVERY:
      case OrderStatus.ACTIVE:
        return 'badge-success';
      case this.DISCOUNT_TEXT:
      case this.BOM:
        return 'badge-discount';
      default:
        return 'badge-warning';
    }
  }
}
