import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { BERespModel } from '../models/backend/BE-response.model';
import { Cart } from '../models/cart.model';
import { Client } from '../models/client.model';
import { ApiService } from './api.service';
import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { ParserUtils } from '../utils/parser-utils';
import { UserInfo } from '../models/user-info.model';

import { env } from 'src/app/app.component';

@Injectable({
  providedIn: 'root',
})
export class VisitPlanService implements OnDestroy {
  client: Client;
  cart: Cart;
  user: UserInfo;

  private subscriptions = new Subscription();
  readonly EndpointsCodes = EndpointsCodes;

  constructor(
    private apiSrv: ApiService,
    private store: Store<{ client: Client; cart: Cart; user: UserInfo }>,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  getClientVisitPlan(isFrozen = false): Observable<BERespModel> {
    const visitPlan = isFrozen ? 'deliveryfrozen' : 'delivery';
    const paramsObj = {
      visitType: visitPlan,
      limit: 7,
    };
    const queryParams = this.apiSrv.generateQueryParams(paramsObj);
    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/visitplan${queryParams}`,
          EndpointsCodes.GET_VISIT_PLAN,
          { showError: false },
        )
        .subscribe(
          (res) => {
            res.data = res.data.slice(0, this.getVisitPlanLength());
            res.data = this.updateOffRouteVisitPlansForUnsupportedCountries(
              res.data,
            );
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  setOperationDate(
    visitType = 'delivery',
    visitDate?: string,
  ): Observable<BERespModel> {
    const rqBody = {
      visitType,
      deliverydate: this.cart.visitDate
        ? new Date(this.cart.visitDate).toISOString()
        : visitDate,
    };
    if (visitType === 'deliveryfrozen')
      rqBody.deliverydate = new Date(this.cart.frozenVisitDate).toISOString();
    return new Observable((obs) => {
      this.apiSrv
        .post(
          `clients/${this.client.clientId}/setoperationdates`,
          EndpointsCodes.SET_OPERATION_DATE,
          rqBody,
          { showError: false },
        )
        .subscribe(
          (res) => obs.next(res),
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  getInvoiceDeadline(): Observable<BERespModel> {
    return new Observable((obs) => {
      this.apiSrv
        .get(
          `clients/${this.client.clientId}/invoicedeadline`,
          EndpointsCodes.GET_VISIT_PLAN,
          { showError: false },
        )
        .subscribe(
          (res) => {
            if (res?.data?.deadlineTime)
              res.data.deadlineTime = ParserUtils.parserDate(res.data);
            window.parent.postMessage('fullyLoaded', '*');
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete(),
        );
    });
  }

  updateOffRouteVisitPlansForUnsupportedCountries(visitPlans) {
    const selectedCountry = env.getConfigByCountry();
    if (!selectedCountry?.hasOffRoute) {
      return visitPlans?.map((vp) => ({ ...vp, offRoute: false }));
    }
    return visitPlans ?? [];
  }

  getVisitPlanLength() {
    return env.getConfigByCountry().visitPlanLength;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
