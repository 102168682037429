import { Component, Input } from '@angular/core';
import { GoBackService } from 'src/app/core/services/go-back.service';

@Component({
  selector: 'app-ka-back-button',
  templateUrl: './ka-back-button.component.html',
  styleUrls: ['./ka-back-button.component.scss']
})
export class KaBackButtonComponent {

  @Input() title: string;

  constructor(
    private navigation: GoBackService
  ) { }
  
  goBack(): void {
    this.navigation.goBack();
  }
}
