import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IButtonConfig } from './models/button.model';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() config: IButtonConfig;
  @Output() clickEvent = new EventEmitter<void>();
}
