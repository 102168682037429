<div class="modal-header">
  <div class="w-100 d-flex flex-row justify-content-between pb-4">
    <div
      class="col-4 col-sm-3 d-flex align-items-center justify-content-center"
    >
      <img [src]="discount.image | toThumbnail" class="img-fluid" alt="" />
    </div>
    <div
      class="col-8 col-sm-9 d-flex flex-column align-items-start justify-content-around"
    >
      <div
        class="w-100 d-flex flex-row align-items-center justify-content-between mb-2"
      >
        <app-ka-status-pill
          [status]="pillMsg"
          [isDiscount]="true"
        ></app-ka-status-pill>
        <button
          type="button" 
          class="btn-close" 
          data-bs-dismiss="modal" 
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')">
        </button>
      </div>

      <p class="discount-name">{{ discount.name }}</p>

      <p
        *ngIf="
          discount.maxRepetitionAllowed < 1000 &&
          discount.calculatedCap < 1000 &&
          discount.config.reward.restrictionType === 'physicalBox'
        "
      >
        {{
          'NEW_ORDER.DISCOUNTS.OPEN.MAXIMUM_NUMBER_BOXES_PER_PROMOTION'
            | translate
        }}
        {{ discount.calculatedCap }}
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.BOX' | translate
        }}<span *ngIf="discount.calculatedCap > 1">s</span>
      </p>

      <p
        *ngIf="
          discount.maxRepetitionAllowed > 999 || discount.calculatedCap > 999
        "
      >
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.NO_LIMIT' | translate }}
      </p>

      <p
        *ngIf="
          discount.maxRepetitionAllowed < 1000 &&
          discount.calculatedCap < 1000 &&
          discount.config.reward.restrictionType !== 'physicalBox'
        "
      >
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.DISCOUNT_VALIDTO' | translate
        }}{{ discount.calculatedCap | kaCurrency }}
      </p>
    </div>
  </div>
</div>
<div class="modal-body products-container">
  <div ngbAccordion class="accordion mb-16">
    <ng-container *ngFor="let requisiteGroup of productsGrouped; let i = index">
      <div ngbAccordionItem [collapsed]="false">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="btn-open-pack-accordion">
            <div class="d-flex w-100 flex-row align-items-center justify-content-between">
              <div class="group-title">
                <p class="mid-strong">
                  {{ ROOT_LANG + 'GROUP' | translate }} {{ i + 1 }} -
                  <span class="condition">
                    {{ ROOT_LANG + 'ADD' | translate }}
                    {{ requisiteGroup.quantity }}
                    {{ ROOT_LANG + 'BOX' | translate }}
                  </span>
                  <span *ngIf="requisiteGroup.quantity > 1">s</span>
                  <span>{{ ROOT_LANG + 'OR_MORE_OF_THIS_GROUP' | translate }}</span>
                </p>
              </div>

              <div class="d-flex flex-row align-items-center justify-content-between">
                <div
                  *ngIf="!requisiteGroup.reachedLimit"
                  class="d-flex flex-row align-items-center">
                  <svg class="text-danger">
                    <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-alert-circle'"/>
                  </svg>
                  <p class="ms-2 help-text">
                    {{ ROOT_LANG + 'NEED_TO_ADD' | translate }}
                    {{ requisiteGroup.quantity - requisiteGroup.groupQuantityCount }}
                  </p>
                </div>
                <div
                  *ngIf="requisiteGroup.reachedLimit"
                  class="d-flex flex-row align-items-center">
                  <svg class="text-success">
                    <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-circle-check'"/>
                  </svg>
                  <p class="ms-2 help-text">
                    {{ ROOT_LANG + 'BOX_COMPLETED' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div ngbAccordion class="accordion sub-accordion mb-16">
                <ng-container *ngFor="let productGroup of requisiteGroup.products | keyvalue; let j = index">
                  <div [ngbAccordionItem]="j" [collapsed]="j === 0 ? false : true">
                    <h2 ngbAccordionHeader>
                      <button ngbAccordionButton class="btn-open-pack-accordion">
                        <div class="category-header d-flex w-100 flex-row align-items-center justify-content-between">
                          <p class="group-name mb-3">{{ productGroup.key }}</p>
                        </div>
                      </button>
                    </h2>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                        <ng-template>
                          <ng-container *ngFor="let product of productGroup.value">
                            <div class="d-flex justify-content-between align-items-center row mb-2">
                              <div class="col-2 col-md-1 pr-0 product-image">
                                <img
                                  [src]="img_root + product.image | toThumbnail"
                                  class="img-fluid"
                                  [alt]="product.name"
                                  (error)="onImgError($event)"
                                />
                              </div>
                              <div class="col-4 col-md-5 text-start d-flex align-items-start justify-content-start flex-column product-name">
                                <span>{{ product.name }}</span>
                              </div>
                              <div class="col-3 price">
                                <div class="product-prices">
                                  <small
                                    *ngIf="
                                      product.price.finalPriceWithoutDiscount !==
                                        product.price.finalPrice &&
                                      product.price.finalPrice !== 0
                                    "
                                    >{{
                                      product.price.finalPriceWithoutDiscount
                                        | kaCurrency
                                    }}</small
                                  >
                                  <span *ngIf="product.price.finalPrice !== 0">{{
                                    product.price.finalPrice | kaCurrency
                                  }}</span>
                                  <span *ngIf="product.price.finalPrice === 0">{{
                                    product.price.finalPriceWithoutDiscount | kaCurrency
                                  }}</span>
                                </div>
                                <small class="text-secondary">{{
                                  ROOT_LANG + 'UNIT'
                                    | translate
                                      : {
                                          price:
                                            product.price.priceBySubUnit | kaCurrency
                                        }
                                }}</small>
                              </div>
                              <div class="col-12 col-md-2 col-lg-2 px-2 px-sm-4">
                                <div class="product-pill mt-2 mt-md-0 mt-lg-0">
                                  <div
                                    class="btn-pill quantity p-2 d-flex align-items-center justify-content-around w-100 bg-white text-center"
                                  >
                                    <span
                                      (click)="removeProduct(product)"
                                      class="button d-flex align-items-center justify-content-center"
                                      [ngClass]="{
                                        disabled: product.quantitySelected === 0
                                      }"
                                    >
                                      <fa-icon icon="minus"></fa-icon>
                                    </span>
                                    <input
                                      type="number"
                                      [(ngModel)]="product.quantitySelected"
                                      (keydown)="validQuantLength($event, product)"
                                      (keyup)="validMaxQuantity($event, product)"
                                      (blur)="validQuantCalc($event, product)"
                                      (paste)="onQuantityPaste($event, product)"
                                      (ngModelChange)="onQuantityChange(product.productId, product.quantitySelected)"
                                      [ngClass]="{
                                        error: product.quantitySelected < 0
                                      }"
                                      (change)="refreshProductsSelected()"
                                      #maxQuantityTooltip="ngbTooltip"
                                      [ngbTooltip]="
                                        product.quantitySelected + 1 > maxOrderQuantity
                                          ? (ROOT_LANG + 'TOOLTIP_MAXORDER'
                                            | translate
                                              : {
                                                  productsMaxQuantityAllowed:
                                                    maxOrderQuantity
                                                })
                                          : ''
                                      "
                                      tooltipClass="custom-tooltip"
                                      placement="top"
                                      triggers="hover"
                                      closeDelay="3000"
                                    />
                                    <span
                                      (click)="addProduct(product)"
                                      class="button d-flex align-items-center justify-content-center"
                                      [ngClass]="{
                                        disabled:
                                          product.quantitySelected + 1 >
                                          maxOrderQuantity
                                      }"
                                    >
                                      <fa-icon icon="plus"></fa-icon>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer d-flex flex-column">
  <div class="d-flex flex-row-reverse w-100 product-prices">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 text-center">
      <span *ngIf="showTotal"> Total: {{ calculatedTotal | kaCurrency }}</span>
      <button
        *ngIf="!showTotal"
        type="button"
        class="btn-square justify-content-center"
        id="calculate-price"
        (click)="calculatePrice()"
      >
        {{ 'NEW_ORDER.DISCOUNTS.OPEN.CALCULATE_DISCOUNT' | translate }}
      </button>
    </div>
  </div>
  <div class="max-repetition">
    <p>{{ 'NEW_ORDER.DISCOUNTS.OPEN.MODAL_OPEN_WARN' | translate }}</p>
  </div>

  <div class="max-repetition d-none">
    <p>
      {{
        'NEW_ORDER.DISCOUNTS.OPEN.MODAL_MAX_QUANT'
          | translate: { maxQuantity: discount.maxRepetitionAllowed }
      }}
    </p>
  </div>
  <button
    [disabled]="productsGrouped | areAllMinimumsCompleted"
    type="button"
    class="btn-square-black"
    id="add-products-modal"
    (click)="addProductsToCart()"
  >
    {{ 'NEW_ORDER.DISCOUNTS.OPEN.MODAL_BUTTON' | translate }}
  </button>
</div>
