<div
  class=" quickaccess-container"
>
  <ng-container *ngFor="let button of buttons">
    <app-ka-quick-access-button
      *ngIf="button.isSuggested"
      [quickAccess]="button"
      [disabled]="button?.hasLockOrder"
      class="quick-button"
    >
    </app-ka-quick-access-button>
  </ng-container>
</div>
