import { ProductFilter } from "../models/product-filter.model";
import { RegexList } from "./regex-list.regex";
export class FileUtils {
  static getPdfFromBase64(value: string, filename: string, isSafari?: boolean) {
      if (isSafari) {
          const byteCharacters = atob(value);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers); 
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);     
          FileUtils.downloadFile(fileURL, filename);
      } else {
          const linkSource ="data:application/pdf;base64," + value;
          FileUtils.downloadFile(linkSource, filename);
      }
  }

  static downloadFile(source, filename: string) {
      const downloadLink = document.createElement("a");
      downloadLink.href = source;
      downloadLink.download = `${filename}.pdf`;
      downloadLink.click();
  }
}

export function requestCredential(): Promise<Credential> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return navigator.credentials.get({ password: true })
}

export function getActualStatusForFilters(status: boolean | null ): boolean | null {
  return status ? null : true;
}

export function countFilter(filter: ProductFilter): number {
  let counterFilter = 0
  for (const key in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, key)) {
      if (filter[key] !== '' && filter[key] !== null) {
        counterFilter++;
      }
    }
  }
  return counterFilter;
}

export function avoidSpecialCharacters(text: string): string {
  if (!text.match(RegexList.detectSpecialChar)) {
    return text;
  }
  return text.replace(RegexList.detectSpecialChar, '');
}
