import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pass-attemp-modal',
  templateUrl: './pass-attemp-modal.component.html',
  styleUrls: ['./pass-attemp-modal.component.scss'],
})
export class PassAttempModalComponent implements OnInit {
  @Input() public data: any;
  @Input() public showFooterButtons? = true;

  @Output() modalResponse: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, public router: Router) {}

  ngOnInit(): void {
    this.data = {
      id: 'pass-attemps-failed-modal',
      image: 'tristecito.png',
      text: 'Demasiados intentos erróneos',
      description: 'Intenta nuevamente más tarde.',
      alertText: 'Revisa las indicaciones para crear tu nueva contraseña',
      confirmBtnLabel: 'Aceptar',
      showAlert: false,
      btnWide: true,
    };
  }

  confirm(): void {
    if (this.data.navigateTo) {
      this.router.navigate([this.data.navigateTo]);
    }
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.close(false);
  }
}
