import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import * as CartActions from "../../../core/state/actions/cart.actions";
import {Subscription} from "rxjs";
import {UserInfo} from "../../../core/models/user-info.model";

@Component({
  selector: 'app-ka-product-category-lock',
  templateUrl: './ka-product-category-lock.component.html',
  styleUrls: ['./ka-product-category-lock.component.scss']
})
export class KaProductCategoryLockComponent implements OnInit {
  private subscriptions = new Subscription();
  constructor(private store: Store<{ user: UserInfo }>)                  {

  }

  ngOnInit() {
    this.store.dispatch(CartActions.haslockedCategoryAlertOpen({ haslockedCategoryAlertOpen: true }));
  }

  alertClosed() {
    this.store.dispatch(CartActions.haslockedCategoryAlertOpen({ haslockedCategoryAlertOpen: false }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
