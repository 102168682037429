import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalDesktopDeliveryDateService } from '../select-delivery-date/services/modal-desktop-delivery-date.service';
import { SelectDeliveryDateComponent } from '../select-delivery-date/select-delivery-date.component';

@Component({
  standalone: true,
  imports: [
    SelectDeliveryDateComponent,
    CommonModule,
  ],
  selector: 'app-select-delivery-date-desktop',
  templateUrl: './select-delivery-date-desktop.component.html',
  styleUrls: ['./select-delivery-date-desktop.component.scss'],
})
export class SelectDeliveryDateDesktopComponent {
  get showCloseItems(): boolean {
    return this._modalDesktopDeliveryDateService.backdrop === true;
  }
  constructor(private _modalDesktopDeliveryDateService: ModalDesktopDeliveryDateService) { }

  closeModal(): void {
    this._modalDesktopDeliveryDateService.closeModal();
  }
}
