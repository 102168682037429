import { Pipe, PipeTransform } from '@angular/core';
import { Cart } from '../../core/models/cart.model';

@Pipe({
  name: 'isProductFrozen',
})
export class IsProductFrozenPipe implements PipeTransform {
  transform(cart: Cart): unknown {
    return !cart.hasDeliveryFrozenProducts || cart.minPurchaseFrozenReached;
  }
}
