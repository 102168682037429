<mat-expansion-panel (opened)="panelOpened()" (closed)="panelClosed()" class="mb-4 card" hideToggle>
	<mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
		<mat-panel-title>
			<div class="row w-100" style="flex: auto;">
				<div class="col">
					<p><strong>{{exchange.Nombre}}</strong></p>
				</div>
				<div class="col text-end">
					<mat-icon *ngIf="isExpanded">keyboard_arrow_up</mat-icon>
    				<mat-icon *ngIf="!isExpanded">keyboard_arrow_down</mat-icon>
				</div>
			</div>
		</mat-panel-title>
		<hr class="separator">
		<div class="row my-2">
			<div class="col">
				<p>{{ LANG_ROOT + 'EXCHANGE_DONE' | translate}}</p>
			</div>
			<div class="col text-end">{{exchange.Realizados}}</div>
		</div>
		<div class="row my-2">
			<div class="col">
				<p>{{ LANG_ROOT + 'EXCHANGE_PENDING' | translate}}</p>
			</div>
			<div class="col text-end">{{exchange.Pendientes}}</div>
		</div>
		<div class="row my-2">
			<div class="col">
				<strong>{{ LANG_ROOT + 'EXCHANGE_COMPLETED' | translate}}</strong>
			</div>
			<div class="col text-end">
				<span class="accredited">{{exchange.Acreditado | kaCurrency}}</span>
			</div>
		</div>

	</mat-expansion-panel-header>
	<div class="card-content">
		<div *ngFor="let periodo of exchange.Periodos;  let isLast = last">
			<p class="strong">{{periodo.Periodo | formatPeriod}}</p>
			<hr class="separator">
			<div class="row my-2">
				<div class="col">
					<p>{{ LANG_ROOT + 'EXCHANGE_DONE' | translate }}</p>
				</div>
				<div class="col text-end">{{periodo.Realizados}}</div>
			</div>

			<div class="row my-2">
				<div class="col">
					<p>{{ LANG_ROOT + 'EXCHANGE_PENDING' | translate }}</p>
				</div>
				<div class="col text-end">{{periodo.Pendientes}}</div>
			</div>

			<div class="row my-2">
				<div class="col">
					<strong>{{ LANG_ROOT + 'EXCHANGE_COMPLETED' | translate }}</strong>
				</div>
				<div class="col text-end">
					<span class="accredited">{{periodo.Acreditado | kaCurrency}}</span>
				</div>
			</div>
			<hr class="separator" *ngIf="!isLast">
		</div>
	</div>
</mat-expansion-panel>
