import { Pipe, PipeTransform } from '@angular/core';
import { Cart } from '../../core/models/cart.model';

@Pipe({
  name: 'checkSubunitCompleteOrder',
})
export class CheckSubunitCompleteOrderPipe implements PipeTransform {
  transform(cart: Cart): boolean {
    const hasSubunits = cart.products.some(
      (product) => product.subunitSelected === 'BOT'
    );
    let enabledToConfirmOrder = true;
    if (hasSubunits) {
      enabledToConfirmOrder = cart.enabledToConfirmOrder;
    }
    return enabledToConfirmOrder;
  }
}
