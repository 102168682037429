<div class="modal-body">
  <div class="d-flex justify-content-center align-items-center">
    <img class="my-4" src="/assets/media/emojis/blush.png" alt="" />
  </div>

  <h3 class="title my-2">{{ LANG_ROOT + 'REPEAT_ORDER' | translate }}</h3>

  <p class="description1">
    <span class="strong">{{ LANG_ROOT + 'REPEAT_ORDER_DESCRIPTION1' | translate }}</span>
  </p>
  <p *ngIf="continueEnabled" class="description2">{{ LANG_ROOT + 'REPEAT_ORDER_DESCRIPTION2' | translate }}</p>
</div>
<div class="modal-footer d-flex justify-content-center">
  <div class="col-12 col-lg-6 mb-3 d-flex flex-row align-items-center justify-content-center">
    <button id="see-more-products-modal" class="btn-square-black w-100" (click)="reject()">{{ LANG_ROOT + 'BUTTON.SHOW_MORE_PRODUCTS' | translate }}</button>
  </div>
  <div *ngIf="continueEnabled" class="col-12 col-lg-6 d-flex flex-row align-items-center justify-content-center">
    <button id="go-to-order-details-modal" type="button" class="btn-square border-0 w-100 mx-auto" (click)="confirm()">
      {{ LANG_ROOT + 'BUTTON.GO_TO_ORDER_DETAIL' | translate }}
    </button>
  </div>
</div>
