import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { SelectFrozenDeliveryDateDesktopComponent } from '../../select-frozen-delivery-date-desktop/select-frozen-delivery-date-desktop.component';

@Injectable({
  providedIn: 'root'
})
export class ModalDesktopFrozenDeliveryDateService {
  private modalRef: NgbModalRef;

  constructor(private ngbModalService: NgbModal) { }

  openModal(): NgbModalRef {
    this.modalRef = this.ngbModalService.open(SelectFrozenDeliveryDateDesktopComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
      container: 'app-main',
    });

    return this.modalRef;
  }

  closeModal(): void {
    this.modalRef.close();
  }
}