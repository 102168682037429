import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { Product } from 'src/app/core/models/product.model';
import { CartService } from 'src/app/core/services/cart.service';
import * as CartActions from 'src/app/core/state/actions/cart.actions';

@Component({
  selector: 'app-ka-confirm-delete-product',
  templateUrl: './ka-confirm-delete-product.component.html',
  styleUrls: ['./ka-confirm-delete-product.component.scss']
})
export class KaConfirmDeleteProductComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Output() hasAddedProduct = new EventEmitter();

  private subscriptions = new Subscription();
  cart: Cart;
  
  constructor(
    private cartService: CartService,
    private store: Store<{ cart: Cart }>,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => this.cart = cart),
    );
  }

  removeProductFromCart(product: Product): void {
    this.gtmService.pushTag({
      event: 'removeProducts',
      products: product,
      orderId: this.cart.orderId,
    });
    this.store.dispatch(CartActions.deleteProduct({ product }));
    this.cartService.updateDeliveryProducts();
    this.hasAddedProduct.emit(false);
  }

  hideConfirmDeleteProduct(): void {
    this.store.dispatch(CartActions.updateConfirmDeleteProduct({ product: this.product }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
