import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { Client } from '../models/client.model';
import { ApiService } from './api.service';
import { Draft } from '../models/draft.model';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as ClientActions from '../state/actions/client.actions';
import * as CartActions from '../state/actions/cart.actions';

@Injectable({
  providedIn: 'root',
})
export class DraftRequestService implements OnDestroy {
  readonly EndpointsCodes = EndpointsCodes;
  private subscriptions = new Subscription();
  client: Client;

  constructor(private apiSrv: ApiService, private store: Store<{ client: Client }>) {
    this.subscriptions.add(this.store.select('client').subscribe((client) => (this.client = client)));
  }

  getDrafts(): Observable<Draft[]> {
    return new Observable((obs) => {
      this.apiSrv.get(`clients/${this.client.clientId}/order/draft`, EndpointsCodes.GET_DRAFT_ORDER, { showError: false }).subscribe(
        (res) => obs.next(res?.data),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  getMissingOnDraft() {
    return this.getDrafts().pipe(switchMap(([data]) => {
        const ids = data.items.map(o => o.productId);
        const { orderId } = data;

        if (ids?.length) {
          const draftIds = ids.sort().join(',');
          return this.apiSrv.get(`clients/${this.client.clientId}/order/missingondraft?productList=${draftIds}`, EndpointsCodes.MISSING_ON_DRAFT, { showError: false })
            .pipe(
              map(({ missing_elements }) => {
                const products = [];

                missing_elements?.forEach((id) => {
                  const productFoundInList = data.items.find(
                    (p) => p.productId === id,
                  );
                  products.push(productFoundInList);
                });

                return { products };
              }),
              tap(({ products}) => {
                const missingDraftIds = products.map(o => o.productId).sort().join(',');
                if (draftIds === missingDraftIds) {
                  this.store.dispatch(ClientActions.deleteDraft({ draft: { orderId } as any }));
                  this.store.dispatch(CartActions.cleanCart());
                }
              }),
              catchError(() => {
                return of({
                  products: []
                });
              }),
              shareReplay(1)
            )
        }

        return of({
          products: []
        });
      }
    ))
  }

  deleteDraft(orderId: number): Observable<any> {
    return new Observable((obs) => {
      this.apiSrv.delete(`clients/${this.client.clientId}/order/draft`, EndpointsCodes.GET_DRAFT_ORDER, { orderId }, { showError: false }).subscribe(
        (res) => obs.next(res.data),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
