import { Component } from '@angular/core';
import { SelectFrozenDeliveryDateComponent } from '../select-frozen-delivery-date/select-frozen-delivery-date.component';
import { ModalDesktopFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';

@Component({
  standalone: true,
  imports: [SelectFrozenDeliveryDateComponent],
  selector: 'app-select-frozen-delivery-date-desktop',
  templateUrl: './select-frozen-delivery-date-desktop.component.html',
  styleUrls: ['./select-frozen-delivery-date-desktop.component.scss'],
})
export class SelectFrozenDeliveryDateDesktopComponent {
  constructor(private _modalDesktopFrozenDeliveryDate: ModalDesktopFrozenDeliveryDateService) { }

  closeModal(): void {
    this._modalDesktopFrozenDeliveryDate.closeModal();
  }
}
