import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import { CartService } from '../../../core/services/cart.service';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/core/models/product.model';
import { ExternalDataService } from 'src/app/pages/external-integration/services/external-data.service';

@Component({
  selector: 'app-draft-request-modal',
  templateUrl: './draft-request-modal.component.html',
  styleUrls: ['./draft-request-modal.component.scss'],
})
export class DraftRequestModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  readonly LANG_ROOT = 'MY_ORDERS.DRAFT_MODAL.';
  public suggestedProduct:Product[];
  isNewOrder: boolean;
  isValidDate = true;
  myOrdersRoute: string;
  selectedClient: Client;
  repeatOrder: number;
  showSuggested = false;
  constructor(
    public activeModal: NgbActiveModal, 
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private externalDataService : ExternalDataService,
    private store: Store<{ client: Client }>, private cartService:CartService) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
      })
    );
    this.subscriptions.add(this.store.select('client').subscribe((client)=> {
      this.suggestedProduct = client.data.suggestedProducts.products
    }));
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.repeatOrder = params?.repeat;
      })
    );
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.showSuggested = params?.showSuggested;
      })
    );
  }

  ngOnInit(): void {
    this.isNewOrder = this.router.url.includes('nuevo-pedido/1');
  }

  continueDraft(): void {
    // TODO sacar esto y mandar querParam en ka-my-orders-detail
    this.store.dispatch(CartActions.updateRepeated({ isRepeated: false }));
    this.store.dispatch(CartActions.cleanCart());
    if (!this.isNewOrder) {
      this.myOrdersRoute = this.router.url.includes('mcc') ? '../mcc/1' : '../main/nuevo-pedido/1';
      this.router.navigate([this.myOrdersRoute], { relativeTo: this.activatedRoute.parent, queryParams: { draft: true } });
    } else {
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { draft: true } });
    }
    this.activeModal.close(true);
  }

  cancelDraft(): void {
    this.activeModal.close(true);
  }

  deleteDraft(): void {
    this.store.dispatch(ClientActions.deleteDraft({ draft: this.selectedClient.data.drafts[0] }));
    this.store.dispatch(CartActions.cleanCartWithoutFilters());
    this.activeModal.close(true);
    if (this.externalDataService.isExternalIntegrationModule()) {
      return
    }
    if (this.router.url.includes('nuevo-pedido/1')) {
      this.myOrdersRoute = this.router.url.includes('mcc') ? '../mcc/1' : '../main/nuevo-pedido/1';
      if (this.repeatOrder ) {
        this.router.navigate([this.myOrdersRoute], { relativeTo: this.activatedRoute.parent, queryParams: { repeat: this.repeatOrder }  });
      } else {
        this.router.navigate([this.myOrdersRoute], { relativeTo: this.activatedRoute.parent });
      }
    }
    if (this.router.url.includes('home')){
      const products = this.suggestedProduct.filter((p) => p.quantitySelected !== 0);
      this.store.dispatch(CartActions.upsertMultipleProducts({ products }));
      this.cartService.updateDeliveryProducts();
      this.gotToPortfolio();
    }
    if (this.router.url.includes('mcc')){
      if(this.showSuggested){
        const products = this.suggestedProduct.filter((p) => p.quantitySelected !== 0);
        this.store.dispatch(CartActions.upsertMultipleProducts({ products }));
        this.cartService.updateDeliveryProducts();
        this.gotToPortfolio();
      }      
    }   

  }

  private gotToPortfolio(): void {
    if (this.router.url.includes('mcc')) {
      this.router.navigate(['/mcc/2']);
    } else {
      this.router.navigate(['/main/nuevo-pedido/2']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
