import { Injectable } from '@angular/core';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';

const MODAL_OPEN = true;
const MODAL_CLOSED = false;
const PREVIOUS_INDEX = 0;
const CURRENT_INDEX = 1;

@Injectable({
  providedIn: 'root'
})
export class NavbarMenuMobileService {
  private hasOpenModals = [MODAL_CLOSED, MODAL_CLOSED];

  constructor(private isMobileService: IsMobileService) { }

  openCloseNavBarMenuMobile(): void {
    if (this.isMobileService.isMobile) {
      const iconOpenCloseMobileMenu = document.getElementById('navbar-menu-mobile');
      iconOpenCloseMobileMenu.click();
    }
  }

  updateHistoryForModal(): void {
    if (this.isMobileService.isMobile) {
      const modals = document.getElementsByTagName('app-modal-mobile');
      modals.length === 0
        ? this.hasOpenModals.push(MODAL_CLOSED) 
        : this.hasOpenModals.push(MODAL_OPEN);
      this.hasOpenModals.splice(0, 1);
    }
  }

  /**
   * 
   * @returns a boolean that allows to close the sidebar menu mobile
   * We have to know when it's able to close the modal because with the app-modal-mobile
   * component which is in front of the sidebar menu mobile. it detects the app-modal-mobile
   * as a click outside, and then close the sidebar menu mobile, which is an issue.
   * To solve this issue we're saving every click in the app with the 
   * updateHistoryForModals() method. 
   * If both previous and current status are false, we're able to close the sidebar menu mobile.
   */
  canCloseSidebar(): boolean {
    return !this.hasOpenModals[PREVIOUS_INDEX] && !this.hasOpenModals[CURRENT_INDEX];
  }
}
