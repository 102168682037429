import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TextComponent } from './text/text.component';
import { ButtonComponent } from './button/button.component';
import { BoxComponent } from './box/box.component';

const components = [TextComponent, ButtonComponent, BoxComponent];

@NgModule({
  declarations: [components],
  imports: [CommonModule],
  exports: [components],
})
export class SkeletonModule {}
