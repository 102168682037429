import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { ProductFilter } from 'src/app/core/models/product-filter.model';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-ka-categories-icon',
  templateUrl: './ka-categories-icon.component.html',
  styleUrls: ['./ka-categories-icon.component.scss'],
  animations: [
    trigger('fadeIn', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(10px)',
        }),
      ),
      transition(':enter', [
        animate(
          '300ms ease-in',
          style({
            opacity: 1,
            transform: 'translateY(0)',
          }),
        ),
      ]),
    ]),
  ],
})
export class KaCategoriesIconComponent implements OnDestroy {
  private subscriptions = new Subscription();
  @Input() text: string;
  @Input() image: string;
  @Input() selected = false;
  @Input() category?: string;
  @Output() clickEvent = new EventEmitter();
  public showButton = true;
  currentFilters: ProductFilter;

  constructor(private store: Store<{ cart: Cart }>) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.currentFilters = cart.productFilters;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleError() {
    this.showButton = false;
  }

  goToCategoryEvent() {
    this.clickEvent.emit(this.text);
  }
}
