<div
  class="card px-4 pb-4 pt-0"
  [ngClass]="{
    'home-card': page === PageNames.HOME,
    alternative: homeStyle === 2,
    unavailable: unavailable,
    'suggested-border': productSuggetedLabel,
  }"
>
  <div
    class="suggested-label pt-3 pb-2"
    *ngIf="productSuggetedLabel && !carousel"
  >
    <p>
      <img src="../../../../assets/svg/sugerido.svg" alt="" />
      {{ ROOT + 'SUGGESTED_PRODUCT' | translate }}
    </p>
  </div>
  <app-ka-fav-button
    *ngIf="page !== PageNames.HOME"
    [product]="product"
    [isFavorite]="product?.isFavorite"
    class="fav-btn m-2"
  ></app-ka-fav-button>
  <div
    class="row image"
    [ngClass]="{ cursor: page !== PageNames.HOME }"
    (click)="cartService.openImage(product)"
  >
    <img
      class="image-product mw-100"
      [ngClass]="{ carousel: carousel }"
      [src]="product.image | toThumbnail"
      alt=""
      (error)="onImgError()"
    />
  </div>
  <div
    class="product-name pt-3 pb-2"
    [ngClass]="{ home: page === PageNames.HOME }"
    style="white-space: normal"
  >
    <span style="height: 70px">{{ product.name }}</span>
  </div>
  <ng-container *ngIf="product.availability">
    <div class="status-pill w-100 mt-3" *ngIf="product.relatedDiscount">
      <app-ka-status-pill
        [status]="pillMsg"
        [isDiscount]="true"
      ></app-ka-status-pill>
    </div>
    <div
      class="product-price d-flex flex-column mt-1"
      [ngClass]="{ home: page === PageNames.HOME }"
    >
      <small
        *ngIf="product.price.listPrice > product.price.finalPrice"
        class="d-inline-block me-3">
        {{ product.price.listPrice | kaCurrency }}
      </small>
      <small
        *ngIf="product.price.listPrice === product.price.finalPrice"
        class="d-inline-block me-3">
        <br/>
      </small>
      <span *ngIf="!product.relatedDiscount">
        {{ product.price.finalPrice | kaCurrency }}
      </span>
      <span
        *ngIf="
          product.relatedDiscount &&
          currentScaleDiscount &&
          !(cart.discountProducts | kaDiscountsApplyProducts: product)
        "
        >{{ currentScaleDiscount.finalPrice | kaCurrency }}</span
      >
      <small
        *ngIf="product.price.listPrice === product.price.finalPrice"
        class="d-inline-block mr-3"
        ><br
      /></small>
      <small
        *ngIf="product.price.listPrice > product.price.finalPrice"
        class="d-inline-block mr-3"
        >{{ product.price.listPrice | kaCurrency }}</small
      >
      <span
        *ngIf="
          product.relatedDiscount &&
          currentScaleDiscount &&
          (cart.discountProducts | kaDiscountsApplyProducts: product)
        "
        >{{
          cart.discountProducts | kaDiscountsApplyProducts: product | kaCurrency
        }}</span
      >
      <span *ngIf="product.relatedDiscount && !currentScaleDiscount">{{
        product.price.finalPrice | kaCurrency
      }}</span>
    </div>
    <div
      class="product-unit-label mb-3 mt-1 d-flex justify-content-between align-items-center"
      [ngClass]="{ home: page === PageNames.HOME }"
    >
      <div *ngIf="!product.relatedDiscount">
        <span>{{ 'NEW_ORDER.DISCOUNTS.SCALE.PER_UNIT' | translate }} </span>
        <span class="bold-price-unit">
          {{ product.price.priceBySubUnit | kaCurrency }}
        </span>
      </div>

      <div *ngIf="product.relatedDiscount && currentScaleDiscount">
        <span> {{ 'NEW_ORDER.DISCOUNTS.SCALE.PER_UNIT' | translate }} </span>
        <span class="bold-price-unit">
          {{ currentScaleDiscount.priceBySubUnit | kaCurrency }}
        </span>
      </div>
    </div>

    <div
      class="product-unit-label mb-3 mt-1 d-flex justify-content-between align-items-center"
      [ngClass]="{ home: page === PageNames.HOME }"
    >
      <div
        class="open-modal-product-scale d-flex align-items-center"
        (click)="handleProductScale(product)"
        *ngIf="product.relatedDiscount && page !== PageNames.HOME"
      >
        <span>{{
          'NEW_ORDER.SUGGESTED_PRODUCTS.SCALE_BUTTON_MODAL' | translate
        }}</span>
        <svg>
          <use
            xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
          />
        </svg>
      </div>
    </div>
    <p
      class="mid-strong-suggested mid-strong mb-3"
      [ngClass]="{ 'mb-3': page !== PageNames.HOME || client.subUnitAvailable }"
      *ngIf="product.suggestedProduct?.isSuggested"
    >
      {{ 'NEW_ORDER.SUGGESTED_PRODUCTS.SUGGESTED_QUANTITY' | translate }}
      <span>{{ product.suggestedProduct.quantity }}</span>
      <span> {{ product.unitMeasure | lowercase }}</span>
      <span *ngIf="product.suggestedProduct.quantity > 1">s</span>
    </p>
    <app-ka-subunits
      [product]="product"
      *ngIf="client.subUnitAvailable && page === ProductTypes.UNIT"
      (subUnitChanged)="updateSubUnitQuantity($event)"
    ></app-ka-subunits>
    <div *ngIf="page !== PageNames.HOME">
      <span
        class="disclaimer-product-text"
        *ngIf="enableProductPriceDisclaimer"
        >{{ ROOT_LANG + 'PRODUCT_PRICE_DISCLAIMER' | translate }}</span
      >
      <app-ka-add-to-cart-button
        [product]="product"
        [subUnit]="subUnitSelected"
        (currentScaleDiscount)="currentScaleDiscount = $event"
      ></app-ka-add-to-cart-button>
    </div>
  </ng-container>

  <button
    *ngIf="!product.availability"
    class="btn-pill-add btn-pill-add-enabled p-4 w-100 mt-2"
    disabled
  >
    {{
      'NEW_ORDER.ORDER_DETAIL.STOCK_DIGITAL.PRODUCT_NOT_AVAILABLE' | translate
    }}
  </button>
</div>
