import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/storage/localStorage.service';

@Pipe({
  name: 'formatPeriod',
})
export class FormatPeriodPipe implements PipeTransform {
  transform(value: string): string {
    const userLang = LocalStorageService.get('userLang');
    const locale = userLang === 'BR' ? 'pt-BR' : 'es-ES';
    const year = Number(value.toString().slice(0, 4));
    const month = Number(value.toString().slice(4));
    const date = new Date(year, month - 1);

    const monthName = date.toLocaleString(locale, { month: 'long' });

    return `${this.capitalize(monthName)} ${year}`;
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
