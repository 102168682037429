export class DateUtility {
  /**
   * format: 2024-06-13
   */
  static formatDateYearMonthDayNumbers(date: Date | string): string {
    return new Date(date).toISOString().split('T')[0];
  }

  /**
   * format: 2024-06-13T23:59:00.000Z
   */
  static formatDateToISOString(date: Date | string): string {
    return new Date(date).toISOString();
  }

  static isSameDay(date1: Date | string, date2: Date | string): boolean {
    return this.formatDateYearMonthDayNumbers(date1) === this.formatDateYearMonthDayNumbers(date2);
  }

  static parseDateToHHMM(date: string): string {
    if (!date) return '';
    const newDate = new Date(date);
    const hours = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
    const minutes = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
    return hours + ':' + minutes;
  }
}