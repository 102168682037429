import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { countries } from 'src/environments/utils/env.model';
import { UserInfo } from '../models/user-info.model';
import { UserLocal } from '../models/user-local.model';

import { LocalStorageService } from './storage/localStorage.service';
import { TimeLanguagesEnum } from '../enums/time-languages.enum';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  user: UserInfo;
  userLocal: UserLocal;

  constructor(
    private translateService: TranslateService,
    private store: Store<{ user: UserInfo; userLocal: UserLocal }>,
  ) {
    this.store.select('user').subscribe({ next: (user) => (this.user = user) });
    this.store
      .select('userLocal')
      .subscribe({ next: (userLocal) => (this.userLocal = userLocal) });
  }

  init(): void {
    const lang =
      this.user?.countryId ||
      this.userLocal?.geoCountryCode ||
      LocalStorageService.get('userLang') ||
      'CL';

    this.translateService.addLangs(['CL', 'AR', 'BR', 'PY']);
    this.translateService.setDefaultLang(lang);
    this.setLanguage(lang as countries);
  }

  setLanguage(language: countries): void {
    this.translateService.use(language);
    LocalStorageService.set('userLang', language);
  }

  get(key: string, params: unknown = {}): string {
    return this.translateService.instant(key, params);
  }

  getCurrentLanguage(): string {
    switch (this.translateService.currentLang) {
      case 'CL':
        return TimeLanguagesEnum.CL;
      case 'AR':
        return TimeLanguagesEnum.AR;
      case 'BR':
        return TimeLanguagesEnum.BR;
      case 'PY':
        return TimeLanguagesEnum.PY;
        
      default:
        return TimeLanguagesEnum.CL;
    }
  }
}
