import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMobile',
})
export class IsMobilePipe implements PipeTransform {
  transform(_obj: unknown, size = 576): boolean {
    return screen.width < size;
  }
}
