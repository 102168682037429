import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rootImage'
})
export class RootImagePipe implements PipeTransform {

  transform(imgUrl: string): unknown {
    return imgUrl.replace(/_thumbnail.webp/, '.png');
  }

}
