<div class="mb-6">
  <div id="otp" class="d-flex justify-content-center">
    <input class="m-2 text-center input-div input-div-solid rounded" [ngClass]="{ 'error-input': userLocal.showInputBorderError }" type="tel" pattern="\d*" id="first" maxlength="1" max="9" (paste)="onPaste($event)" [value]="resetInput"/>
    <input class="m-2 text-center input-div input-div-solid rounded" [ngClass]="{ 'error-input': userLocal.showInputBorderError }" type="tel" pattern="\d*" id="second" maxlength="1" max="9" [value]="resetInput"/>
    <input class="m-2 text-center input-div input-div-solid rounded" [ngClass]="{ 'error-input': userLocal.showInputBorderError }" type="tel" pattern="\d*" id="third" maxlength="1" max="9" [value]="resetInput"/>
    <input class="m-2 text-center input-div input-div-solid rounded" [ngClass]="{ 'error-input': userLocal.showInputBorderError }" type="tel" pattern="\d*" id="fourth" maxlength="1" max="9" [value]="resetInput"/>
    <input class="m-2 text-center input-div input-div-solid rounded" [ngClass]="{ 'error-input': userLocal.showInputBorderError }" type="tel" pattern="\d*" id="fifth" maxlength="1" max="9" [value]="resetInput"/>
    <input class="m-2 text-center input-div input-div-solid rounded" [ngClass]="{ 'error-input': userLocal.showInputBorderError }" type="tel" pattern="\d*" id="sixth" maxlength="1" max="9" [value]="resetInput"/>
  </div>
</div>
