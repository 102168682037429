export enum LoginSteps {
  CHOOSE_USER = 'chooseUser',
  RESTORE_CHOOSE = 'restoreChoose',
  RESTORE_PHONE = 'restorePhone',
  RESTORE_PHONE_CONFIRM = 'restorePhoneConfirm',
  RESTORE_PHONE_PWD = 'restorePhonePassword',
  RESTORE_EMAIL = 'restoreEmail',
  RESTORE_EMAIL_CODE = 'restoreEmailCode',
  RESTORE_EMAIL_PWD = 'restoreEmailPassword',
  CHANGE_PWD = 'changePassword',
  LEGALS = 'legals',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  RUT_NUMBER = 'rutNumber',
  CLIENT_NUMBER = 'clientNumber',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'passwordConfirm',
  CONFIRM_CODE = 'confirmCode',
  UPDATE_PASSWORD_LANDING = 'updatePasswordLanding',
  BASIC_INFO = 'basicInfo',
  COMPLETE_INFO = 'CompleteInfo',
}
