import { Component } from '@angular/core';
import packageJson from 'package.json';

@Component({
  selector: 'app-ka-frontend-version',
  templateUrl: './ka-frontend-version.component.html',
  styleUrls: ['./ka-frontend-version.component.scss']
})
export class KaFrontendVersionComponent{
  public version: string = packageJson.version;

  constructor() { }

}
