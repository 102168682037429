import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'segmentErrorsFormat',
})
export class SegmentErrorsFormatPipe implements PipeTransform {
  transform(currentCredits: any, hasCredits: boolean): string {
    const segmentErrors = [];

    if (hasCredits) {
      currentCredits?.processedList.forEach((segment) => {
        if (segment.segmentName && segment.status !== 'OK') {
          segmentErrors.push(segment.segmentName.trim());
        }
      });
    }

    if (segmentErrors.length === 1) {
      return segmentErrors.join();
    } else if (segmentErrors.length === 2) {
      return segmentErrors.join(' y ').trim();
    }

    const segment1 = segmentErrors.pop();
    const segment2 = segmentErrors.join(', ');

    return segment2.trim() + ' y ' + segment1;
  }
}
