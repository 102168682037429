import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { Client } from '../../../core/models/client.model';

import { QuickAccessButtonModel } from 'src/app/core/models/quick-access-button.models';
import { ProductTypes } from 'src/app/core/enums/product-types';

@Component({
  selector: 'app-ka-quick-access',
  templateUrl: './ka-quick-access.component.html',
  styleUrls: ['./ka-quick-access.component.scss'],
})
export class KaQuickAccessComponent implements OnDestroy {
  selectedClient: Client;
  isSuggestedProductsAvailable: boolean;
  readonly ROOT_LANG = 'HOME.QUICK_ACCESS_LABEL.';
  suggestedProducts: any[] = [];
  countrySelected: any;
  bannerImages: string[];
  subscriptions = new Subscription();
  client: Client;
  bannersUrl = environment.WEB_DOMAIN + 'images/banners';
  image: string;
  buttons: QuickAccessButtonModel[];
  button: QuickAccessButtonModel;
  homeStyle: any;
  hasLockOrder = false;

  constructor(
    private store: Store<{
      client: Client;
    }>,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
        this.hasLockOrder =
          client.hasLockOrder ||
          client.data.visitDates.length === 0 ||
          client.data.hasCreditLock;
        const sugProd = client?.data?.suggestedProducts.products ?? [];
        this.suggestedProducts = sugProd.map((prod) => ({ ...prod }));
        this.isSuggestedProductsAvailable = this.suggestedProducts.length > 0;
        this.constructorButtons();
      }),
    );
  }

  constructorButtons(): QuickAccessButtonModel[] {
    this.buttons = [
      {
        title: this.ROOT_LANG + 'SUGGESTED_ORDER',
        image: './assets/icons/tabler-sprite.svg#tabler-wand',
        addresNavigator: '/nuevo-pedido/2',
        isBestOption: true,
        isSuggested: this.isSuggestedProductsAvailable,
        filter: ProductTypes.SUGGESTED,
      },
      {
        title: this.ROOT_LANG + 'NEW_ORDER',
        image: './assets/icons/tabler-sprite.svg#tabler-shopping-cart-plus',
        addresNavigator: '/nuevo-pedido/2',
        isBestOption: false,
        isSuggested: true,
        filter: ProductTypes.UNIT,
        hasLockOrder: this.hasLockOrder,
      },
      {
        title: this.ROOT_LANG + 'OLD_ORDER',
        image: './assets/icons/tabler-sprite.svg#tabler-bucket',
        addresNavigator: `/mis-pedidos/`,
        isBestOption: false,
        isSuggested: true,
        filter: '',
      },
    ];

    return (this.buttons = this.buttons.filter(
      (button) => button.isSuggested === true,
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
