<div
  class="d-inline-block"
  ngbDropdown
  #myDrop="ngbDropdown"
  id="dropdownBasic2"
>
  <button
    type="button"
    class="btn btn-outline-light me-2"
    id="dropdownManual"
    ngbDropdownAnchor
    (focus)="myDrop.open()"
  >
    {{ ROOT + '.TITLE' | translate }}
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownManual">
    <button
      ngbDropdownItem
      class="button-order"
      (click)="updateSortFilter('RELEVANT')"
      [class.active]="orderSort === 'RELEVANT'"
    >
      {{ ROOT + '.RELEVANT' | translate }}
    </button>
    <button
      ngbDropdownItem
      class="button-order"
      (click)="updateSortFilter('AZ')"
      [class.active]="orderSort === 'AZ'"
    >
      {{ ROOT + '.AZ' | translate }}
    </button>
    <button
      ngbDropdownItem
      class="button-order"
      (click)="updateSortFilter('ZA')"
      [class.active]="orderSort === 'ZA'"
    >
      {{ ROOT + '.ZA' | translate }}
    </button>
    <button
    ngbDropdownItem
    class="button-order"
    (click)="updateSortFilter('cheaper')"
    [class.active]="orderSort === 'CHEAPER'"
  >
    {{ ROOT + '.CHEAPER' | translate }}
  </button>
  <button
    ngbDropdownItem
    class="button-order"
    (click)="updateSortFilter('expensive')"
    [class.active]="orderSort === 'EXPENSIVE'"
  >
    {{ ROOT + '.EXPENSIVE' | translate }}
  </button>
  </div>
</div>
<p
  class="order-button-option label-order"
  id="label-orden-button"
  *ngIf="orderSort !== ''"
>
  {{ ROOT + '.' + orderSort | translate }}
</p>
