import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-mobile',
  templateUrl: './modal-mobile.component.html',
  styleUrls: ['./modal-mobile.component.scss'],
})
export class ModalMobileComponent {
  @Input() canCloseWithShadow = true;
  @Output() clickOnShadow = new EventEmitter<void>;

  closeModal(): void {
    if (this.canCloseWithShadow) {
      this.clickOnShadow.emit();
    }
  }
}
