import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GoBackService {
  private history: string[] = [];

  constructor(
      private router: Router
  ) { }

  public startSaveHistory(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length > 1) {
          if (this.history[this.history.length - 1] == event.urlAfterRedirects) {
            this.history.pop();
          }
        }
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  public goBack(): void {
      if (this.history.length > 1) {
        this.history.pop();
        this.router.navigate([this.history[this.history.length - 1]]);
      } 
  }
}
