import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showCancelOrderButton'
})
export class ShowCancelOrderButtonPipe implements PipeTransform {
  private statuses = [
    'initiated',
    'created',
    'delivered',
    'registered',
    'blocked'
  ];

  transform(status: string): boolean {
    return this.statuses.includes(status?.toLowerCase());
  }
}
