<div class="select-date-container">
  <ng-container *ngIf="isFrozenVisitDatesMoreThanZero">
    <div class="select-date-header">
      <div class="icon">
        <svg class="header-icon" stroke-width="3.25">
          <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-snowflake'" />
        </svg>
      </div>
      <div class="text">
        <div 
          *ngIf="title"
          class="select-date-title">
          {{title | translate}}
        </div>
        <div 
          *ngIf="subtitle"
          class="select-date-info">
          {{subTitle | translate}}
        </div>
      </div>
    </div>
  
    <div class="select-options-container">
      <app-options-frozen-delivery-date 
        (selectedFrozenVisitDate)="updateSelectedDate($event)">
      </app-options-frozen-delivery-date>
    </div>
  </ng-container>

  <ng-container *ngIf="isFrozenVisitDatesEqualZero">
    <div class="something-went-wrong">
      <svg>
        <use [attr.xlink:href]="getChapitaIconURL" />
      </svg>
      <div class="mb-16"></div>
      
      <div class="something-went-wrong-text">
        {{'NEW_ORDER.DELIVERY_PLAN.NO_AVAILABLE_DATES' | translate}}
      </div>
      <div class="mb-16"></div>
    </div>
  </ng-container>
</div>

<div class="select-date-buttons">
  <app-button
    [config]="{
      text: 'NEW_ORDER.DELIVERY_PLAN.BUTTON_SELECT_FROZEN_DELIVERY_DATE' | translate,
      style: 'primaryFilled',
    }"
    (clickEvent)="closeModal()"
  ></app-button>
</div>