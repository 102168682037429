import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from '@components/components.module';
import { VisitDate } from 'src/app/core/models/client.model';
import { OptionsFrozenDeliveryDateComponent } from '../options-frozen-delivery-date/options-frozen-delivery-date.component';

import { VisitFrozenDatesService } from 'src/app/core/services/visit-frozen-dates.service';
import { ModalMobileFrozenDeliveryDateService } from './services/modal-mobile-frozen-delivery-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { SelectFrozenDeliveryDateInfoService } from './services/select-frozen-delivery-date-info.service';
import { ButtonComponent } from '@components/button/button.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ComponentsModule,
    OptionsFrozenDeliveryDateComponent,
    ButtonComponent,
  ],
  selector: 'app-select-frozen-delivery-date',
  templateUrl: './select-frozen-delivery-date.component.html',
  styleUrls: ['./select-frozen-delivery-date.component.scss']
})
export class SelectFrozenDeliveryDateComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription;
  private selectedVisitDate: VisitDate;
  get isFrozenVisitDatesMoreThanZero(): boolean {
    return this._visitFrozenDatesService.clientFrozenVisitDates.length > 0;
  }
  get isFrozenVisitDatesEqualZero(): boolean {
    return this._visitFrozenDatesService.clientFrozenVisitDates.length === 0;
  }
  get isMobile(): boolean {
    return this._isMobile.isMobile;
  }
  get getChapitaIconURL(): string {
    const url = '../../../../assets/icons/chapita/chapita.svg#sad-';
    return this.isMobile ? `${url}mobile` : `${url}desktop`;
  }
  get title(): string {
    return this._selectFrozenDeliveryDateInfoService.getTitle();
  }
  get subtitle(): string {
    return this._selectFrozenDeliveryDateInfoService.getSubTitle();
  }
  @Output() closeModalDesktopEvent = new EventEmitter<void>;

  constructor(
    private _visitFrozenDatesService: VisitFrozenDatesService,
    private _modalMobileFrozenDeliveryDate: ModalMobileFrozenDeliveryDateService,
    private _isMobile: IsMobileService,
    private _selectFrozenDeliveryDateInfoService: SelectFrozenDeliveryDateInfoService,
  ) { }

  ngOnInit(): void {
    this.getVisitFrozenDatesByClient();
    this.updateFrozenVisitDate();
  }

  private getVisitFrozenDatesByClient(): void {
    this._subscription.add(this._visitFrozenDatesService.getFrozenVisitDatesByClient());
  }

  private updateFrozenVisitDate(): void {
    this._visitFrozenDatesService.updateFrozenVisitDate();
  }

  closeModal(): void {
    this._visitFrozenDatesService.setFrozenOperationDate();
    this._visitFrozenDatesService.updateFrozenVisitDateBySelect(this.selectedVisitDate.visitDate);
    
    this.isMobile
      ? this._modalMobileFrozenDeliveryDate.closeModal()
      : this.closeModalDesktopEvent.emit();
  }

  updateSelectedDate(selectedVisitDate: VisitDate): void {
    this.selectedVisitDate = selectedVisitDate;
  }

  ngOnDestroy(): void {
    this._selectFrozenDeliveryDateInfoService.resetTexts();
    this._subscription.unsubscribe();
  }
}
