<div *ngIf="steps.length > 0" class="step-by-step">
  <ng-container *ngFor="let step of steps; index as i">
    <span class="text" (click)="clickItemEvent.emit(step)">
      {{ step[config.key] | titleCaseFirstWord }}
    </span>
    <ng-container *ngIf="steps.length > 1 && i + 1 < steps.length">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="13"
        viewBox="0 0 9 13"
        fill="none"
      >
        <path
          d="M2 1.27759L7.22239 6.49998L2 11.7224"
          stroke="#3C3C3C"
          stroke-width="2.49938"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>
  </ng-container>
</div>
