import { Injectable } from '@angular/core';

const initialValues = {
  title: 'NEW_ORDER.DELIVERY_PLAN.SELECT_FROZEN_DELIVERY_DATE',
  subtitle: 'NEW_ORDER.DELIVERY_PLAN.SELECT_FROZEN_DELIVERY_DATE_INFO',
}

@Injectable({
  providedIn: 'root'
})
export class SelectFrozenDeliveryDateInfoService {
  private _title = initialValues.title;
  private _subtitle = initialValues.subtitle;

  setTitle(title: string): void {
    this._title = title;
  }

  getTitle(): string {
    return this._title;
  }

  setSubTitle(subtitle: string): void {
    this._subtitle = subtitle;
  }

  getSubTitle(): string {
    return this._subtitle;
  }

  resetTexts(): void {
    this._title = initialValues.title;
    this._subtitle = initialValues.subtitle;
  }
}
