import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';
import { SCREEN_LG } from 'src/app/core/constants/media-query.constant';

@Component({
  selector: 'app-ka-dropdown',
  templateUrl: './ka-dropdown.component.html',
  styleUrls: ['./ka-dropdown.component.scss'],
})
export class KaDropdownComponent implements OnInit {

  @Input() filter: any;
  @Input() onlyForSubunits = false;
  @Input() selected: string;
  @Input() product?: Product;
  @Output() selectOption: EventEmitter<any> = new EventEmitter();
  @ViewChild('filters', { static: false }) filters: ElementRef;
  distanceFromBottom: number;

  ROOT_LANG = 'NEW_ORDER.PRODUCTS.FILTER.';

  ngOnInit(): void {
    if(this.onlyForSubunits) {
      let subUnitDesc = this.product.subunitSelected === 'BOT' ? this.product.subunitdesc : this.product.unitMeasure;
      this.selected = this.product.subunitSelected === 'BOT' ? subUnitDesc : this.filter[1].value;
    }
  }

  calculateDistanceFromBottom() {
   const divElement: HTMLElement = this.filters.nativeElement;
   const rect = divElement.getBoundingClientRect();
   this.distanceFromBottom = window.innerHeight - rect.bottom;
  }

  get isMobile(): boolean {
    return screen.width < SCREEN_LG;
  }

  updateFilter(event) {
    if(this.onlyForSubunits) {
      this.selected = event.value;
      this.selectOption.emit(event.id);
    } else
    this.selected = event;
    this.selectOption.emit(event);
  }

}
