import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toTruncate',
})
export class ToTruncatePipe implements PipeTransform {
  transform(value: string , size: number): string  {
   if (value === null || value ==="")
      return value ;
    if (value.length > size ) {  
     const  val = value.replace(/,/gi, " ");
     return  val.substring(0,size)+"...";
    }
   else 
   return value
  }
}