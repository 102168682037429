import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { Product } from 'src/app/core/models/product.model';
import { getCartProducts } from 'src/app/core/state/reducers/cart.reducer';
import * as ClientActions from 'src/app/core/state/actions/client.actions';

@Component({
  selector: 'app-ka-suggested-on-cart-carousel',
  templateUrl: './ka-suggested-on-cart-carousel.component.html',
  styleUrls: ['./ka-suggested-on-cart-carousel.component.scss'],
})
export class KaSuggestedOnCartCarouselComponent implements OnInit, OnDestroy {
  @Output() hasAddedProduct = new EventEmitter();

  private subscriptions = new Subscription();
  suggestedCarouselOptions: OwlOptions;
  suggestedProducts: Product[];
  cart: Cart;
  client: Client;

  constructor(private store: Store<{ cart: Cart; client: Client }>) {
    this.subscriptions.add(
      this.store.select(getCartProducts).subscribe((products) => {
        if (this.cart) this.cart.products = products;
        this.filterSuggestedProducts();
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
  }

  ngOnInit() {
    this.store.dispatch(ClientActions.loadSuggestedProducts());
    this.filterSuggestedProducts();
    this.suggestedCarouselOptions = this.getCarouselOptions();
  }

  filterSuggestedProducts(): void {
    this.suggestedProducts = this.client?.data?.suggestedProducts.products;
    if (this.suggestedProducts) {
      this.cart.products.forEach((cartProd) => {
        this.suggestedProducts = this.suggestedProducts.filter((sugProd) => {
          return sugProd.productId !== cartProd.productId;
        });
      });
    }
  }

  getCarouselOptions(): OwlOptions {
    return {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      autoWidth: true,
      margin: 5,
      navText: [
        "<img src='../../../../assets/icons/arrow-left-white.svg'/>",
        "<img src='../../../../assets/icons/arrow-right-white.svg'/>",
      ],
      responsive: {
        0: {
          items: 1,
        },
        700: {
          items: 2,
        },
      },
      nav: false,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAddedProductHandler(evt: boolean) {
    this.hasAddedProduct.emit(evt);
  }
}
