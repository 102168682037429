<div class="modal-header">
  <div class="row w-100 pb-4">
    <div class="col-4 col-sm-3">
      <img [src]="product.image | toThumbnail" class="img-fluid" alt="" />
    </div>
    <div
      class="col-8 col-sm-9 d-flex flex-column align-items-start justify-content-center"
    >
      <app-ka-status-pill
        [status]="pillMsg"
        [isDiscount]="true"
      ></app-ka-status-pill>
      <p class="mt-5">{{ product.requirements.name }}</p>
    </div>
  </div>
  <button
    type="button" 
    class="btn-close" 
    data-bs-dismiss="modal" 
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
  </button>
  <hr />
</div>
<div class="modal-body pb-0 m-4">
  <div class="products-container">
    <p class="detail-price">{{ LANG_ROOT + 'PRICES_DETAIL' | translate }}</p>
    <p class="regular-price">
      {{ LANG_ROOT + 'REGULAR_PRICE' | translate }}
      {{ regularPrice | kaCurrency }}
    </p>
    <hr class="ml-16 mr-16 mt-3 mb-3" />
    <ul class="p-0 list-unstyled">
      <li *ngFor="let scale of sortScales; let i = index">
        <div class="d-flex justify-content-between align-items-center">
          <span class="item-count" *ngIf="i > 0 && i < product.requirements.scales.length - 1">
            {{ LANG_ROOT + 'DISCOUNT_LABEL_2' | translate: { scale: scale } }}
          </span>
          <span class="item-count" *ngIf="i === product.requirements.scales.length - 1">
            {{ LANG_ROOT + 'DISCOUNT_LABEL_3' | translate: { scale: secondToLastSortScale } }}
          </span>
          <div *ngIf="i > 0 && i <= product.requirements.scales.length - 1">
            <span>{{ LANG_ROOT + 'PAY' | translate }}</span>
            <span class="price ms-3 mr-8">{{
              scale.price.finalPrice | kaCurrency
            }}</span>
            <span>{{ LANG_ROOT + 'PER_PACK' | translate }}</span>
          </div>
        </div>        
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer d-flex flex-column p-0 mx-4 mt-0 mb-4">
  <div class="d-flex w-100 justify-content-md-end">
    <div>
      <div class="product-pill">
        <div
          class="btn-pill quantity p-2 d-flex align-items-center justify-content-around w-100 bg-white text-center"
        >
          <span
            (click)="removeProduct()"
            class="button d-flex align-items-center justify-content-center"
            [ngClass]="{ disabled: product.quantitySelected === 1 }"
          >
            <fa-icon icon="minus"></fa-icon>
          </span>
          <input type="number" [ngModel]="product.quantitySelected" disabled />
          <span
            (click)="addProduct()"
            class="button d-flex align-items-center justify-content-center"
            [ngClass]="{'disabled' : product.quantitySelected >= maxOrderQuantity}"
            #maxQuantityTooltip="ngbTooltip"
            [ngbTooltip]="product.quantitySelected >= maxOrderQuantity
                  ? (ROOT_LANG + 'TOOLTIP_MAXORDER'| translate: { productsMaxQuantityAllowed: maxOrderQuantity })
                  : ''"
            tooltipClass="custom-tooltip" placement="top" triggers="hover" closeDelay="3000">
            <fa-icon icon="plus"></fa-icon>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="CountryCodes.BRASIL !== user.countryId" class="ml-16 total d-flex align-items-center pl-0">
        {{
          currentScale
            | getTotalFinalPriceCC: scales : product : lastScale
            | kaCurrency
        }}
    </div>
  </div>
  <div class="d-flex flex-column w-100">
    <div class="mt-3">
      <div class="max-repetition w-100">
        <span *ngIf="product.quantitySelected < lastScale.min">
          {{
            LANG_ROOT + 'DISCOUNT_INFO'
              | translate
                : { quantity: nextScale?.min - this.product.quantitySelected }
          }}
          {{ nextScaleDiscount | kaCurrency }}
          {{ LANG_ROOT + 'PER_BOX' | translate }}
        </span>
        <span *ngIf="product.quantitySelected >= lastScale.min">
          {{ LANG_ROOT + 'BEST_PRICE' | translate }}
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-center w-100">
      <button
        type="button"
        class="btn-square-black"
        [disabled]="!product.quantitySelected || product.quantitySelected === productInCart?.quantity"
        (click)="addProductToCart()"
      >
        {{ 'NEW_ORDER.DISCOUNTS.SCALE.ADD_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
