<div class="w-100 card banner p-4 d-flex justify-content-between align-items-center flex-column flex-lg-row">
  <div class="draft-info d-flex flex-row align-items-center">
    <img class="draw-icon mr-8" alt="draft-image" src="./assets/svg/pencil.svg" />
    <div class="d-flex flex-column">
      <h1 class="draft-info_title">{{ LANG_ROOT + 'TITLE' | translate }}</h1>
      <h4 class="draft-info_subtitle mb-0">{{ LANG_ROOT + 'SUBTITLE' | translate }}</h4>
    </div>
  </div>
  <div class="actions d-flex">
    <button id="continue_order_banner" class="btn-square-black" (click)="continueDraft()">{{ LANG_ROOT + 'CONTINUE' | translate }}</button>
    <button id="delete_order_banner" class="btn-square border-0" (click)="deleteDraft()">{{ LANG_ROOT + 'DISCART' | translate }}</button>
  </div>
</div>
