import { Product } from '../models/product.model';

export class ProductsCalcs {

  static getTotalFinalPrice = (products: Product[]): number => {
    return products.reduce(
      (acc, cur: Product) => {
        return acc + Math.max(parseFloat(cur.totals?.finalPrice) || 0, 0);
      },
      0
    );
  };

  static getTotalListPrice = (products: Product[]): number => {
    return products.reduce(
        (acc, cur: Product) => {
        return acc + Math.max(parseFloat(cur.totals?.listPrice) || 0, 0);
      },
      0
    );
  };

  static getTotalFinalTaxes = (products: Product[]): number => {
    return products.reduce(
      (acc, cur: Product) => {
        return acc + Math.max(parseFloat(cur.totals?.finalTaxes) || 0, 0);
      },
      0
    );
  };

  static getTotalDiscounts = (products: Product[]): number => {
    return products.reduce(
      (acc, cur: Product) => {
        return acc + Math.max(parseFloat(cur.totals?.discounts) || 0, 0);
      },
      0
    );
  };

  static getSubtotalsByGroup = (products: Product[]): any => {
    const groupBySegmentId = products.reduce((finalObj, currentProduct) => {
      // si no existe el grupo (por ser el primer item) lo creamos
      if (!finalObj[currentProduct.segmentId]) {
        finalObj[currentProduct.segmentId] = {
          segmentId: currentProduct.segmentId,
          productGroupName: currentProduct.productGroupName,
          value: 0,
        };
      }
      // sumamos el valor del producto al valor del grupo
      finalObj[currentProduct.segmentId].value += parseFloat(currentProduct.totals?.listPrice) || 0;
      return finalObj;
    }, {});
    return Object.values(groupBySegmentId);
  };

  static getItemFullListPrice = (product: Product): number => {
    const listPrice = parseFloat(product?.price?.listPrice || 0);
    const shipping = parseFloat(product?.price?.shippingPrice || 0);
    const taxes = parseFloat(product?.price?.taxes || 0);
    return Math.round((listPrice + shipping + taxes) * 1000) / 1000;
  };

  static getTotalBoxes = (products: Product[]): number => {
    return products.reduce(
      (sum, prod) => sum + prod.proportionalQuantity,
      0,
    )
  };
}
