import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ProductTypes } from 'src/app/core/enums/product-types';
import { Cart } from 'src/app/core/models/cart.model';
import { CartService } from 'src/app/core/services/cart.service';
import {MissingBottlesModalComponent} from "../../../pages/new-order/modals/missing-bottles-modal/missing-bottles-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProductsService} from "../../../core/services/products.service";

@Component({
  selector: 'app-ka-missing-bottles-warning',
  templateUrl: './ka-missing-bottles-warning.component.html',
  styleUrls: ['./ka-missing-bottles-warning.component.scss']
})
export class KaMissingBottlesWarningComponent implements OnDestroy {
  @Input() link: boolean;
  private subscriptions = new Subscription();
  cart: Cart;
  ProductTypes = ProductTypes;
  ROOT_LANG = 'NEW_ORDER.MISSING_BOTTLES.';
  constructor(
    private store: Store<{ cart: Cart }>,
    public cartService: CartService,
    private ngbModalService: NgbModal,
    private productsService: ProductsService
  ) {
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
   }

  handleMissingBottles() {
    const openPackModal = this.ngbModalService.open(MissingBottlesModalComponent, { windowClass: 'ngbmodal-centered', size: 'lg' });
    openPackModal.result.then(
        (confirm) => { },
        (rejected) => { }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
