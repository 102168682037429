import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodBySegement } from 'src/app/core/models/payment-method-by-segment.model';
import { PaymentMethod } from 'src/app/core/models/payment-method.model';
import { SegmentInformation } from 'src/app/pages/new-order/steps/s6-mini-credit/components/how-to-pay-by-category/how-to-pay-by-category.component';

@Pipe({
  name: 'isPaymentMethodChecked',
})
export class IsPaymentMethodCheckedPipe implements PipeTransform {
  transform(
    selectedPaymentMethodsBySegment: PaymentMethodBySegement[],
    method: PaymentMethod,
    segmentInformation: SegmentInformation,
  ): boolean {
    const result = selectedPaymentMethodsBySegment.find(
      (pmBySegement) =>
        pmBySegement.paymentMethod.paymentMethodId === method.paymentMethodId &&
        pmBySegement.segmentId === segmentInformation.segment.id,
    );
    return result ? true : false;
  }
}
