<div *ngIf="(notificationObs | async) && !close" class="conteiner">
  <div class="close-btn" (click)="closeNotification()">&times;</div>
  <app-alert
    [type]="'dark'"
    [text]="ROOT | translate"
    [size]="12"
    [showLeftIcon]="true"
    [customIcon]="'alert-white-ex.svg'"
  ></app-alert>
</div>
