<div class="btn-quantity d-flex flex-row align-items-center mt-3 mb-3">
  <div class="quantity px-0 py-3 d-flex flex-row align-items-center justify-content-around bg-white text-center w-100">
    <span class="noselect" (click)="removeOneProductFromCart()" [ngClass]="{'disabled' : isSubunit ? pill - minSubunit < minSubunit : pill - 1 < 1}">
      <fa-icon icon="minus"></fa-icon>
    </span>
    <input 
      type="number"
      [disabled]="!pill"
      [ngModel]="pill" 
      (keydown)="validQuantLength($event)"
      (keyup)="validMaxQuantity($event)"
      (paste)="onQuantityPaste($event)" 
      (keyup.enter)="updateQuantity($event)" 
      (blur)="updateQuantity($event)"
      [ngClass]="{'disabled' : pill + minSubunit > maxOrderQuantity || !pill }"
    />
    <span class="noselect" (click)="addOneProductToCart()"
      [ngClass]="{'disabled' : pill + minSubunit > maxOrderQuantity || !pill }"
      #maxQuantityTooltip="ngbTooltip"
      [ngbTooltip]="pill + minSubunit > maxOrderQuantity
                ? (ROOT_LANG + 'TOOLTIP_MAXORDER'| translate: { productsMaxQuantityAllowed: maxOrderQuantity })
                : ''"
      tooltipClass="tooltip-product-max-qty" placement="top" triggers="hover" closeDelay="3000">
      <fa-icon icon="plus"></fa-icon>
    </span>
  </div>
</div>
