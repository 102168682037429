import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';
import { ExternalIntegration } from 'src/app/pages/external-integration/enums/external-integration.enum';
import { ModalsService } from '../services/modals.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalAccessToB2BGuard implements CanActivate {
  private userService: UserService = inject(UserService);
  private modalService: ModalsService = inject(ModalsService);

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (
      this.userService.user?.origin === ExternalIntegration.KOBOSS &&
      this.userService.user?.authValue
    ) {
      this.userService.cleanUserInfoStore();
      this.modalService.openSessionExpiredModal();
    }
    return true;
  }
}