<label class="label">{{ config.label }}</label>

<textarea
  #textArea
  class="text-area"
  [name]="config.input.name"
  [id]="config.input.id"
  [minLength]="config.input.minLength"
  [maxLength]="config.input.maxLength"
  [disabled]="disabled"
  [value]="val"
  (input)="onChange(textArea.value); onTouch(textArea.value)"
  (click)="onTouch(textArea.value)"
></textarea>
