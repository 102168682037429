<div class="modal-header">
  <h3 
    id="modal-basic-title"
    class="modal-title mt-4" 
    >
    {{ ROOT_LANG + 'TITLE' | translate }}
  </h3>
  <button 
    type="button" 
    class="btn-close" 
    data-bs-dismiss="modal" 
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
  </button>
</div>

<div class="modal-body">
  <pdf-viewer 
    [src]="iframeSource.changingThisBreaksApplicationSecurity"
    [render-text]="true"
    [original-size]="false"
    [fit-to-page]="true"
    [zoom]="zoomTo"
    (after-load-complete)="iframeLoad($event)"
    (dblclick)="zoom($event)"
  ></pdf-viewer>
</div>

<div 
  class="modal-footer row d-none d-md-flex pb-4 px-5" 
  *ngIf="!data?.hideActions"
  >
  <div class="col-4"></div>
  <div class="col-3 text-end">
    <button 
      id="tyc-refuse-modal" 
      type="button" 
      class="btn-square border-0 w-auto mx-auto" 
      (click)="reject()"
      >
      {{ ROOT_LANG + 'REFUSE' | translate }}
    </button>
  </div>
  <div class="col-4">
    <button 
      id="tyc-confirm-modal" 
      class="btn-square-black w-100" 
      (click)="confirm()"
      >
      {{ ROOT_LANG + 'CONFIRM' | translate }}
    </button>
  </div>
</div>
<div 
  class="modal-footer row d-md-none pb-4 px-5" 
  *ngIf="!data?.hideActions"
  >
  <div class="col-12 mb-3">
    <button 
      id="tyc-confirm-modal" 
      class="btn-square-black w-100" 
      (click)="confirm()"
      >
      {{ ROOT_LANG + 'CONFIRM' | translate }}
    </button>
  </div>
  <div class="col-12 text-center">
    <button 
      id="tyc-refuse-modal" 
      type="button" 
      class="btn-square border-0 w-auto mx-auto" 
      (click)="reject()"
      >
      {{ ROOT_LANG + 'REFUSE' | translate }}
    </button>
  </div>
</div>