import { createAction, props } from '@ngrx/store';
import { UserInfo } from '../../models/user-info.model';
import { UserExternalData } from '../../models/user-external-info.model';

export const loadUser = createAction('[User] loadUser', props<{ user: UserInfo }>());

export const loadRole = createAction('[User] loadRole', props<{ role: string }>());
export const loadJwt = createAction('[User] loadJwt', props<{ jwt: string }>());
export const loadRefreshJwt = createAction('[User] loadRefreshJwt', props<{ refreshJwt: string }>());
export const loadCognitoUserName = createAction('[User] loadCognitoUserName', props<{ cognitoUserName: string }>());
export const loadUuid = createAction('[User] loadUuid', props<{ uuid: string }>());

export const logout = createAction('[User] logout');
export const logoutSuccess = createAction('[User] logoutSuccess');
export const logoutError = createAction('[User] logoutError');

export const loginUser = createAction('[User] loginUser', props<{ cognitoUsername: string, loginEvent: string, isSocial: boolean }>());
export const loginUserSuccess = createAction('[User] loginUserSuccess');
export const loginUserError = createAction('[User] loginUserError');

export const updateUser = createAction('[User] updateUser', props<{ cognitoUsername: string }>());
export const updateUserSuccess = createAction('[User] updateUserSuccess', props<{ user: UserInfo }>());
export const updateUserError = createAction('[User] updateUserError');

export const loginIntegrationUser = createAction('[User] loginIntegrationUser', props<{ userSession: any }>());
export const loginIntegrationUserSuccess = createAction('[User] loginIntegrationUserSuccess');
export const loginIntegrationUserError = createAction('[User] loginIntegrationUserError');

export const getIntegrationUserInfo = createAction('[User] getIntegrationUserInfo', props<{ userId: any }>());
export const getIntegrationUserInfoSuccess = createAction('[User] getIntegrationUserInfoSuccess');
export const getIntegrationUserInfoError = createAction('[User] getIntegrationUserInfoError');

export const isSocial = createAction('[User] isSocial', props<{ isSocial: boolean }>());

export const setDeviceInfo = createAction('[User] setDeviceInfo', props<{ devicePlatform: string, device: string }>());

export const loadDeviceInfo = createAction('[User] loadDeviceInfo');
export const loadDeviceInfoSuccess = createAction('[User] loadDeviceInfoSuccess');
export const loadDeviceInfoError = createAction('[User] loadDeviceInfoError');

export const loginExternalIntegrationUser = createAction('[User] loginExternalIntegrationUser', props<{ uuid: string }>());
export const loginExternalIntegrationUserSuccess = createAction('[User] loginExternalIntegrationUserSuccess');
export const loginExternalIntegrationUserError = createAction('[User] loginExternalIntegrationUserError');

export const setExternalIntegrationUserInfo = createAction('[User] setExternalIntegrationUserInfo', props<{ authType: string, authValue: string, origin: string }>());

export const getExternalIntegrationUserInfo = createAction('[User] getExternalIntegrationUserInfo', props<{ clientId: string, authType: string, authValue: string }>());
export const getExternalIntegrationUserInfoSuccess = createAction('[User] getExternalIntegrationUserInfoSuccess');
export const getExternalIntegrationUserInfoError = createAction('[User] getExternalIntegrationUserInfoError');

export const createUserSession = createAction('[User] createUserSession', props<{ externalData: UserExternalData }>());
export const createUserSessionSuccess = createAction('[User] createUserSessionSuccess');
export const createUserSessionError = createAction('[User] createUserSessionError');

export const cleanUserInformation = createAction('[User] cleanUserInformation');