import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UserLocal } from 'src/app/core/models/user-local.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tyc-modal',
  templateUrl: './tyc-modal.component.html',
  styleUrls: ['./tyc-modal.component.scss'],
})
export class TycModalComponent implements OnInit, OnDestroy {
  readonly ROOT_LANG = 'LOGIN.SIGN_UP.LEGALS.TYC_MODAL.';
  @Input() data: any;
  
  userLocal: UserLocal;
  iframeSource: any;
  pdfUrl = '../../../../../assets/docs/tyc/';
  showIframe = true;
  reqPdfCounter = 0;
  iframeLoaded = false;
  iframeReloadInterval;
  zoomTo = 1;
  
  private subscriptions = new Subscription();

  constructor(
    private ngxSpinner: NgxSpinnerService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private store: Store<{ userLocal: UserLocal }>,
    private sanitizer: DomSanitizer
  ) {
    this.subscriptions.add(
      this.store.select('userLocal').subscribe((userLocal) => (this.userLocal = userLocal))
    );
  }

  ngOnInit(): void {
    let completeUrl = "";
    if(this.data?.combos){          
      completeUrl = `${this.pdfUrl}combos_TyC_${this.data.country}.pdf`;
    }else{
      completeUrl = `${this.pdfUrl}001_${this.userLocal.geoCountryCode}_${this.userLocal.organizationId}_term_condition.pdf#toolbar=0`;
    }    
    this.iframeSource = this.sanitizer.bypassSecurityTrustResourceUrl(completeUrl);
    this.ngxSpinner.show();
    this.iframeReloadInterval = setInterval(() => this.retryLoadIframe(), 2500);
  }

  retryLoadIframe(): void {
    if (this.iframeLoaded) {
      clearInterval(this.iframeReloadInterval);
      return;
    }
    this.reqPdfCounter = this.reqPdfCounter + 1;
    if (this.reqPdfCounter === 6) {
      this.toastr.error('Ocurrió un error al cargar los términos y condiciones. Intente nuevamente.');
      this.ngxSpinner.hide();
      clearInterval(this.iframeReloadInterval);
      return;
    }
    this.showIframe = false;
    setTimeout(() => (this.showIframe = true), 100);
  }

  iframeLoad(e): void {
    this.ngxSpinner.hide();
    this.iframeLoaded = true;
  }

  zoom(e) {
    this.zoomTo = (this.zoomTo === 1) ? 1.50 : 1;
  }

  confirm(): void {
    this.activeModal.close(true);
  }

  reject(): void {
    this.activeModal.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
