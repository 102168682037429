<div class="modal-body d-flex">
  <div class="w-100">
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="confirm()"
    ></button>
    <img
      class="w-100 rounded-lg"
      [src]="imageUrl | toThumbnail: true"
      alt=""
      loading="lazy"
      (error)="onImgError()"
    />
  </div>
</div>