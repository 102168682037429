import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-ka-input-form',
  templateUrl: './ka-input-form.component.html',
  styleUrls: ['./ka-input-form.component.scss']
})
export class KaInputFormComponent implements OnInit {
  @Input() inputId      = '';
  @Input() inputLabel   = '';
  @Input() autocomplete = '';
  @Input() customClass  = '';
  @Input() placeholder  = '';
  @Input() tooltipShow  = '';
  @Input() tooltipHide  = '';

  @Input() control: UntypedFormControl;

  @ViewChild('svgShowPassword', {static: false}) svgShowPassword: ElementRef;
  @ViewChild('svgHidePassword', {static: false}) svgHidePassword: ElementRef;

  showPassword: boolean = false;
  showPasswordConfirm: boolean = false;

  ngOnInit(): void {
    this.showPassword = false;
    this.showPasswordConfirm = false;
  }

  onFocus(event) {
    event.target.value = event.target.value.trim();
    setTimeout(_=> {
      if(this.svgShowPassword) {
        this.svgShowPassword.nativeElement.style.borderColor = '#329fff';
      }
      if(this.svgHidePassword) {
        this.svgHidePassword.nativeElement.style.borderColor = '#329fff';
      }
    },50);
  }

  onBlur() {
    setTimeout(_=> {
      if(this.svgShowPassword) {
        this.svgShowPassword.nativeElement.style.borderColor = '#c4c4c4';
      }
      if(this.svgHidePassword) {
        this.svgHidePassword.nativeElement.style.borderColor = '#c4c4c4';
      }
    },50);
  }

  switchShowPassword() {
    this.showPasswordConfirm = !this.showPasswordConfirm ? true: false;
    this.showPassword = !this.showPassword ? true: false;
  }

  isMobile(): boolean {
    return screen.width < 576;
  }

}
