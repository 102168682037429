import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';

const DELIVERY_PLAN = 'NEW_ORDER.DELIVERY_PLAN.';

@Pipe({
  standalone: true,
  name: 'boxes',
  pure: true,
})
export class BoxesPipe implements PipeTransform {

  constructor(private translationService: TranslationService) { }

  transform(value: number): string {
    if (!value) {
      return;
    }

    return value > 1
      ? `${this.translationService.get(DELIVERY_PLAN + 'BOXES', { amount: value })}`
      : `${this.translationService.get(DELIVERY_PLAN + 'BOX', { amount: value })}`;
  }

}
