import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';

@Pipe({
  name: 'isAllSuggestedOnDraft',
})
export class IsAllSuggestedOnDraftPipe implements PipeTransform {
  transform(suggestedProducts: Product[], cartProducts: Product[]): boolean {
    if (
      cartProducts === undefined ||
      cartProducts?.length === 0 ||
      cartProducts === null
    )
      return false;
    const suggestedWithOutNoAvailables = suggestedProducts.filter((p) => p.availability);
    let result = true;
    for (let i = 0; i < suggestedWithOutNoAvailables.length; i++) {
      let productToAdd = false;
      for (let j = 0; j < cartProducts.length && !productToAdd; j++) {
        productToAdd =
          productToAdd ||
          (suggestedWithOutNoAvailables[i].productId === cartProducts[j].productId &&
            suggestedWithOutNoAvailables[i].quantitySelected <= cartProducts[j].quantity);
      }
      result = result && productToAdd;
    }

    return result;
  }
}
