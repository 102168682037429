import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/core/models/cart.model';
import { Product } from 'src/app/core/models/product.model';
import * as CartActions from 'src/app/core/state/actions/cart.actions';


@Component({
  selector: 'app-ka-delete-product',
  templateUrl: './ka-delete-product.component.html',
  styleUrls: ['./ka-delete-product.component.scss']
})
export class KaDeleteProductComponent {
  @Input() product: Product;
  
  constructor(
    private store: Store<{ cart: Cart}>
   ) { }

  showConfirmDeleteProduct(): void {
    this.store.dispatch(CartActions.updateConfirmDeleteProduct({ product: this.product }));
  }
  
}
