import { Pipe, PipeTransform } from '@angular/core';
import { DiscountScale } from '../../core/models/discount-scale.model';
import { Discount } from '../../core/models/discount.model';

@Pipe({
  name: 'getTotalFinalPriceCC',
})
export class GetTotalFinalPriceCCPipe implements PipeTransform {
  transform(
    currentScale: DiscountScale,
    scales: DiscountScale[],
    product: Discount,
    lastScale: DiscountScale
  ): number {
    if (scales?.length) {
      currentScale = scales?.find((scale) => {
        return (
          product.quantitySelected >= scale.min &&
          product.quantitySelected <= scale.max
        );
      });
    }

    if (currentScale && lastScale) {
      const maxScaleDisc = lastScale?.max;

      if (product.quantitySelected <= maxScaleDisc) {
        return currentScale
          ? product.quantitySelected * currentScale.price.finalPrice
          : product.quantitySelected * scales[0].price.finalPrice;
      }

      const discountPrice = maxScaleDisc * currentScale?.price?.finalPrice;
      const normalPrice =
        (product.quantitySelected - maxScaleDisc) *
        currentScale.price.finalPrice;

      return discountPrice + normalPrice;
    }
  }
}
