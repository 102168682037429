<ng-container *ngIf="isEmptyDeliveryDate">
  <div class="delivery-date-title">
    <span class="delivery-date-not-available">
      {{ 'HOME.MY_DELIVERY_DATES_SELECT_NOT_AVAILABLE' | translate }}
    </span>
  </div>
</ng-container>

<ng-container *ngIf="isOneDeliveryDate">
  <div class="delivery-date-title">
    <span>{{'HOME.MY_DELIVERY_DATES_SELECT' | translate}}</span>
  </div>
  <div class="delivery-date-text">
    {{currentVisitDate | weekdayDayMonthParser}}
  </div>
</ng-container>

<ng-container *ngIf="isMoreThanOneDeliveryDate">
  <div class="delivery-date-title">
    <span>{{'HOME.MY_DELIVERY_DATES_SELECT' | translate}}</span>
  </div>

  <div class="delivery-date-info">
    <div class="delivery-date-text">{{currentVisitDate | weekdayDayMonthParser}}</div>
    <svg class="delivery-date-icon" stroke-width="3.25">
      <use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-calendar-event'" />
    </svg>
  </div>
</ng-container>

