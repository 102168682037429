import { Injectable } from '@angular/core';
import { SCREEN_LG } from '../../constants/media-query.constant';

@Injectable({
  providedIn: 'root'
})
export class IsMobileService {
  get isMobile(): boolean {
    return screen.width < SCREEN_LG;
  }
}
