export class RegexList {
  // eslint-disable-next-line @typescript-eslint/quotes
  public static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static phoneRegex = new RegExp('^[0-9+-]+$');
  public static numberRegex = new RegExp('^[0-9]+$');
  public static nameRegex = new RegExp('^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ \'-]+$');
  // public static password = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`]).{8,}$/gm;
  public static password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]{}\(\)\?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  public static detectSpecialChar = /[^0-9a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ \-.,]/g;
}
