import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DefaultImages } from 'src/app/core/enums/default-images';
import { DiscountCalculationType } from 'src/app/core/enums/discount-calculation-type';
import { DiscountTypes } from 'src/app/core/enums/discount-types';
import { Cart } from 'src/app/core/models/cart.model';
import { Discount } from 'src/app/core/models/discount.model';
import { ProductPrice } from 'src/app/core/models/product-price.model';
import { CartService } from 'src/app/core/services/cart.service';
import { ModalsService } from 'src/app/core/services/modals.service';
import { VisitPlanService } from 'src/app/core/services/visit-plan.service';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import {
  upsertMultipleProducts,
  upsertProduct,
} from 'src/app/core/state/actions/cart.actions';
import {
  loadFrozenVisitDatesError,
  loadFrozenVisitDatesSuccess,
} from 'src/app/core/state/actions/client.actions';
import { ValidationUtils } from 'src/app/core/utils/validation-utils';
import { environment } from 'src/environments/environment';
import { OpenPackModalComponent } from '../../../pages/new-order/modals/open-pack-modal/open-pack-modal.component';
import { ScalePackModalComponent } from '../../../pages/new-order/modals/scale-pack-modal/scale-pack-modal.component';
import { ProductImageModalComponent } from '../../modals/product-image-modal/product-image-modal.component';
import { Product } from 'src/app/core/models/product.model';
import { ModalMobileFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { take } from 'rxjs/operators';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { ModalDesktopFrozenDeliveryDateService } from '../select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { UserInfo } from 'src/app/core/models/user-info.model';

interface DiscountScale {
  subUnitQuantity: number;
  min: number;
  max: number;
  price: ProductPrice;
  reward?: any;
}

@Component({
  selector: 'app-discount-product-card',
  templateUrl: './discount-product-card.component.html',
  styleUrls: ['./discount-product-card.component.scss'],
})
export class DiscountProductCardComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() discount?: Discount;
  @Input() style: string;
  @Input() carousel = false;
  homeStyle: any;
  user: UserInfo;
  notFoundImg = environment.WEB_DOMAIN + DefaultImages.DISCOUNT;
  readonly DiscountTypes = DiscountTypes;
  readonly CountryCodes = CountryCodes;
  currentScale: DiscountScale;
  currentScaleLabel: string;
  nextScale: DiscountScale;
  prevScale: DiscountScale;
  bom: string;
  isCurrentScaleLast: boolean;
  cart: Cart;
  bomInCart: Product;
  discountName: string;
  namesProducts: string[];
  scales: DiscountScale[];
  lastScale: DiscountScale;

  discountPill = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  discountPillType = false;
  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }

  constructor(
    private store: Store<{ cart: Cart; user: UserInfo }>,
    private ngbModalService: NgbModal,
    private cartService: CartService,
    private visitPlanService: VisitPlanService,
    private router: Router,
    private _modalMobileFrozenDeliveryDateService: ModalMobileFrozenDeliveryDateService,
    private _modalDesktopFrozenDeliveryDateService: ModalDesktopFrozenDeliveryDateService,
    private _isMobileService: IsMobileService,
  ) {
    this.cartService = cartService;
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;
      }),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  ngOnInit(): void {
    this.setMinorInput();
    this.updateScale();
    switch (this.discount.discountType) {
      case DiscountTypes.BOM:
        this.namesProducts = this.discount.requirements.map(
          (prod) => prod.quantity.toString() + ' ' + prod.name,
        );
        this.discountName = this.namesProducts.toString();
        this.discountName = this.discountName.replace(/,/gi, ' + ');
        this.discountPill = 'NEW_ORDER.DISCOUNTS.COMBO';
        this.discountPillType = true;
        this.bom = this.discount.discountType;
        this.searchBOMOnCart();
        break;
      case DiscountTypes.SCALE_AMOUNT:
        this.discountName = this.discount.requirements?.name;
        break;
      default:
        this.discountName = null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  searchBOMOnCart() {
    this.bomInCart = this.cart.discountProducts.find(
      (item) => item.productId === this.discount?.erpDiscountId,
    );
    if (!this.bomInCart && this.discount?.discountType === DiscountTypes.BOM) {
      this.bomInCart = {
        productId: this.discount.erpDiscountId,
        isBom: true,
        quantitySelected: 1,
        maxOrderQuantity: this.discount.maxOrderQuantity,
        maxOrderQuantityBox: this.discount.maxOrderQuantityBox,
      };
    }
  }

  setMinorInput() {
    if (
      this.discount.discountType === 'US' ||
      this.discount.discountType === 'PS'
    ) {
      const response = this.discount.requirements.scales.find((scale) => {
        return scale.reward && scale.reward.value > 0;
      });
      this.discount.quantitySelected = Math.max(Number(response?.min || 0), 1);
    }
  }

  addProduct(): void {
    this.discount.quantitySelected++;
    this.updateScale();
  }

  removeProduct(): void {
    if (this.discount.quantitySelected > 1) {
      this.discount.quantitySelected--;
      this.updateScale();
    }
  }

  updateScale(): any {
    if (!this.isQuantitySelectedValid()) return;

    this.currentScale = this.discount.requirements?.scales?.find((scale) => {
      return (
        this.discount.quantitySelected >= scale.min &&
        this.discount.quantitySelected <= scale.max
      );
    });

    if (this.currentScale) {
      this.getScaleLabel();
    }

    this.scales = this.discount?.requirements?.scales;
    this.lastScale = this.scales?.[this.scales?.length - 1];
  }

  getScaleLabel(): void {
    const scaleIndex = this.discount.requirements.scales.findIndex(
      (scale) => scale == this.currentScale,
    );
    this.nextScale = this.discount.requirements.scales[scaleIndex + 1];
    this.prevScale = this.discount.requirements.scales[scaleIndex - 1];
    this.isCurrentScaleLast = this.currentScale?.max === 9999999;
    const currentScaleHasDiscount =
      !!this.currentScale?.reward?.value || !!this.currentScale?.reward;
    const nextScaleHasDiscount = !!this.nextScale?.reward?.value;

    if (this.isCurrentScaleLast) {
      if (currentScaleHasDiscount) {
        this.currentScaleLabel =
          this.discount.calculationType === DiscountCalculationType.AMOUNT
            ? 'DISCOUNT_LABEL_UNLIMITED_AMOUNT'
            : 'DISCOUNT_LABEL_UNLIMITED';
        return;
      }
    }

    if (!currentScaleHasDiscount) {
      this.currentScaleLabel =
        this.discount.calculationType === DiscountCalculationType.AMOUNT
          ? 'NO_DISCOUNT_LABEL_2'
          : 'NO_DISCOUNT_LABEL_1';
      return;
    }

    if (
      this.discount.quantitySelected < this.currentScale?.max - 1 ||
      !nextScaleHasDiscount
    ) {
      this.currentScaleLabel =
        this.discount.calculationType === DiscountCalculationType.AMOUNT
          ? 'DISCOUNT_LABEL_UNIT_2'
          : 'DISCOUNT_LABEL_UNIT';
    } else {
      this.currentScaleLabel = 'PRE_DISCOUNT';
    }
  }

  addProductToCart(discount: Discount): void {
    if (
      Array.isArray(discount.requirements) &&
      discount.requirements.some((d) => d.deliveryType === 'deliveryfrozen') &&
      !this.cart.hasDeliveryFrozenProducts
    ) {
      this.deliveryFrozenActions(discount);
    } else if (
      Array.isArray(discount.requirements) &&
      discount.requirements.some(
        (d) =>
          Array.isArray(d.products) &&
          d.products.some((p) => p.deliveryType === 'deliveryfrozen'),
      ) &&
      !this.cart.hasDeliveryFrozenProducts
    ) {
      this.deliveryFrozenActions(discount);
    } else if (
      !Array.isArray(discount.requirements) &&
      discount.requirements.scales.some(
        (d) => d.deliveryType === 'deliveryfrozen',
      ) &&
      !this.cart.hasDeliveryFrozenProducts
    ) {
      this.deliveryFrozenActions(discount);
    } else {
      this.processProductToCart(discount);
    }
    this.updateScale();
  }

  processProductToCart(discount: Discount) {
    switch (discount.discountType) {
      case DiscountTypes.CLOSED:
        if (!this.isQuantitySelectedValid()) return;
        this.handleClosedDiscount();
        break;
      case DiscountTypes.BOM:
        this.handleBOMDiscount();
        break;
      case DiscountTypes.UNIT_SCALE:
      case DiscountTypes.SCALE_AMOUNT:
        if (!this.isQuantitySelectedValid()) return;
        this.handleUnitScaleOrAmountScaleDiscount();
        break;
      case DiscountTypes.PACK_SCALE:
        this.handlePackScaleDiscount();
        break;
      case DiscountTypes.OPEN:
      case DiscountTypes.PERCENTAGE:
      case DiscountTypes.AMOUNT:
      case DiscountTypes.FIXED:
        discount.config.class === 'close'
          ? this.handleClosedDiscount()
          : this.handleOpenPackDiscount();
        break;
      default:
        break;
    }
  }

  deliveryFrozenActions(discount: any): void {
    this.visitPlanService.getClientVisitPlan(true).subscribe(
      async (res) => {
        this.store.dispatch(
          CartActions.updateFrozenVisitDate({ date: res.data[0].visitDate }),
        );
        this.store.dispatch(
          loadFrozenVisitDatesSuccess({ frozenVisitDates: res.data }),
        );
        await this.setOperationDate();
        if (this.isMobile) {
          this._modalMobileFrozenDeliveryDateService.openModal();
          this._modalMobileFrozenDeliveryDateService.isCloseModal$
            .pipe(take(1))
            .subscribe({
              next: (isClose) => {
                if (isClose) {
                  this.processProductToCart(discount);
                }
              },
            });
        } else {
          this._modalDesktopFrozenDeliveryDateService
            .openModal()
            .result.then(() => {
              this.processProductToCart(discount);
            });
        }
      },
      () => {
        this.isMobile
          ? this._modalMobileFrozenDeliveryDateService.openModal()
          : this._modalDesktopFrozenDeliveryDateService.openModal();
        this.store.dispatch(loadFrozenVisitDatesError());
      },
    );
  }

  setOperationDate(): Promise<any> {
    return this.visitPlanService.setOperationDate('deliveryfrozen').toPromise();
  }

  handleClosedDiscount(): void {
    const productsPack = [];
    this.discount.requirements?.forEach((product) => {
      productsPack.push({
        ...product,
        quantitySelected: product.quantity * this.discount.quantitySelected,
      });
    });
    this.store.dispatch(upsertMultipleProducts({ products: productsPack }));
    this.cartService.updateDeliveryProducts();
  }

  handleUnitScaleOrAmountScaleDiscount(): void {
    const cartProduct = {
      ...this.discount.requirements,
      ...this.currentScale,
      quantitySelected: this.discount.quantitySelected,
    };
    this.store.dispatch(upsertProduct({ product: cartProduct }));
    this.cartService.updateDeliveryProducts();
  }

  handleOpenPackDiscount(): void {
    const openPackModal = this.ngbModalService.open(OpenPackModalComponent, {
      windowClass: 'ngbmodal-centered',
      size: 'lg',
    });
    openPackModal.componentInstance.discount = this.discount;
  }

  handlePackScaleDiscount(): void {
    const scalePackModal = this.ngbModalService.open(ScalePackModalComponent, {
      windowClass: 'ngbmodal-centered',
      size: 'lg',
    });
    scalePackModal.componentInstance.discount = this.discount;
  }

  handleBOMDiscount() {
    const product: Product = {
      ...this.discount,
      productId: this.discount.erpDiscountId,
      quantitySelected: this.discount.quantitySelected,
      isBom: true,
      name: this.discountName,
    };
    this.store.dispatch(upsertProduct({ product }));
    this.cartService.updateDeliveryProducts();
  }

  validQuantLength(event): void {
    const validKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (validKeys.some((validKey) => validKey === event.key)) return;
    if (this.discount.quantitySelected > 99 || !/^[0-9]$/.test(event.key))
      event.preventDefault();
  }

  onQuantityPaste(event): void {
    ValidationUtils.validRegexOnPaste(/^[0-9]+$/, event);
  }

  isQuantitySelectedValid(): boolean {
    return (
      this.discount?.quantitySelected > 0 &&
      Number.isInteger(this.discount.quantitySelected)
    );
  }

  onImgError(): void {
    this.discount.image = this.notFoundImg;
  }

  openImage(): void {
    if (this.router.url != '/main/home') {
      const imageModal = this.ngbModalService.open(ProductImageModalComponent, {
        windowClass: 'ngbmodal-centered',
        size: 'md',
        backdrop: true,
      });
      imageModal.componentInstance.imageUrl = this.discount.image;
    }
  }
}
