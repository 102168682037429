import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { VisitDate } from '../models/client.model';
import { getCartVisitDate } from '../state/reducers/cart.reducer';
import { getClientVisitDates } from '../state/reducers/client.reducer';
import { DateUtility } from '../utils/app-date-utils';
import { loadDeliveryDates, updateDeliveryDate } from '../state/actions/cart.actions';

export const SMART_DELIVERY = 'smartDelivery';

@Injectable({
  providedIn: 'root'
})
export class VisitDatesService {
  clientVisitDates: VisitDate[];
  currentVisitDate: string | Date;
  get clientCartStream$(): Observable<[string | Date, VisitDate[]]> {
    return combineLatest(
      this.store.select(getCartVisitDate),
      this.store.select(getClientVisitDates),
    );
  }
  get deliveryDates(): VisitDate[] {
    return this.clientVisitDates.filter((date) => !date?.offRoute && !date?.source);
  }
  get deliveryDatesWithSmartDeliveryDates(): VisitDate[] {
    const SMART_DATES = this.clientVisitDates.filter(date => date?.source === SMART_DELIVERY);
    const MIN_DELIVERY_DATE = new Date(this.deliveryDates[0]?.visitDate);

    const filteredSmartDates = SMART_DATES.filter(date => new Date(date.visitDate) >= MIN_DELIVERY_DATE);

    const allDates = this.deliveryDates.concat(filteredSmartDates);
    return allDates.sort((a, b) => new Date(a.visitDate).getTime() - new Date(b.visitDate).getTime());
  }
  get deliveryDatesOffRoute(): VisitDate[] {
    return this.clientVisitDates.filter((date) => date?.offRoute && !date?.source);
  }
  get smartDeliveryDates(): VisitDate[] {
    const minDeliveryDate = this.deliveryDates[0]?.visitDate;

    return this.clientVisitDates
      .filter((date) => date?.source === SMART_DELIVERY)
      .filter((date) => new Date(date.visitDate) < new Date(minDeliveryDate));
  }

  constructor(private store: Store) { }

  getVisitDatesByClient(): Subscription {
    return this.clientCartStream$.subscribe({
      next: ([
        cartVisitDate,
        clientVisitDates,
      ]) => {
        this.setVisitDates(clientVisitDates, cartVisitDate);
      },
    })
  }

  private setVisitDates(
    clientVisitDates: VisitDate[],
    cartVisitDate: string | Date
  ): void {
    this.clientVisitDates = clientVisitDates;
    this.currentVisitDate = this.getCurrentVisitDate(cartVisitDate);
  }

  loadDeliveryDates(): void {
    this.store.dispatch(loadDeliveryDates());
  }

  private getCurrentVisitDate(cartVisitDate: string | Date): string | Date {
    if (!cartVisitDate) {
      return this.clientVisitDates[0]?.visitDate;
    }

    const isCartCurrentDateValid = this.clientVisitDates.some(visitDateObj =>
      DateUtility.isSameDay(visitDateObj.visitDate, cartVisitDate)
    );

    if (this.clientVisitDates.length !== 0) {
      return isCartCurrentDateValid
        ? cartVisitDate
        : this.clientVisitDates[0].visitDate;
    }
  }

  updateCurrentVisitDateBySelectedDate(selectedVisitDate: VisitDate): void {
    this.store.dispatch(updateDeliveryDate({ date: selectedVisitDate }));
  }

  isSameDeliveryDate(deliveryDate: VisitDate): boolean {
    return DateUtility.isSameDay(deliveryDate.visitDate, this.currentVisitDate)
  }

  isFirstDeliveryDate(): boolean {
    const visitDateIndex = this.clientVisitDates?.findIndex((date) => {
      return DateUtility.isSameDay(date.visitDate, this.currentVisitDate);
    });
    return visitDateIndex === 0;
  }
}
