import { Pipe, PipeTransform } from '@angular/core';

import { env } from 'src/app/app.component';

import { Order } from 'src/app/core/models/order.model';
import { ORDERS_DEVOLUTION } from '../model/my-orders.model';

@Pipe({
  name: 'invalidDetailOrder',
})
export class InvalidDetailOrderPipe implements PipeTransform {
  private order: Order;
  private isAvailableThirdPartyOrderDetail: boolean;

  transform(order: Order, thirdPartyDetailOrder: boolean): boolean {
    this.order = order;
    this.isAvailableThirdPartyOrderDetail = thirdPartyDetailOrder;

    return !this.hasEnabledDetailOrder();
  }

  private hasEnabledDetailOrder(): boolean {
    if (this.isThirdPartyOrder() && this.isThirdPartyFeatureOrderOff()) {
      return false;
    }

    if (this.isThirdPartyOrder() && this.isDateOrderMoreThanTwoDays()) {
      return false;
    }

    if (
      this.isExternalSourceChannelOrder() &&
      this.isReturnedOrder() &&
      this.isDevolutionOrder()
    ) {
      return false;
    }

    return true;
  }

  private isThirdPartyOrder(): boolean {
    return !this.order.orderId;
  }

  private isThirdPartyFeatureOrderOff(): boolean {
    return !this.isAvailableThirdPartyOrderDetail;
  }

  private isDateOrderMoreThanTwoDays(): boolean {
    const TWO_DAYS_IN_HOURS = 48;
    const currentDate = new Date();
    const deliveryDate = new Date(this.order.deliveryDate);
    const dateSinceCreationInHours = this.getHoursSinceCreation(deliveryDate, currentDate);

    return dateSinceCreationInHours > TWO_DAYS_IN_HOURS;
  }

  private getHoursSinceCreation(deliveryDate: Date, currentDate: Date): number {
    return (deliveryDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);
  }

  private isReturnedOrder(): boolean {
    return this.order.status === 'RETURNED';
  }

  private isExternalSourceChannelOrder(): boolean {
    return this.order.sourceChannel !== 'B2B';
  }

  private isDevolutionOrder(): boolean {
    return this.order.documentType === ORDERS_DEVOLUTION[env.getConfigByCountry().key];
  }
}
