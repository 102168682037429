<div *ngIf="showButton" class="category_container">
  <div class="category">
    <img
      class="category_img"
      [class.selected]="selected"
      [src]="image"
      (click)="goToCategoryEvent()"
      (error)="handleError()"
    />
  </div>
  <p class="category_text">{{ text }}</p>
</div>
