<div *ngIf="!product.enabledToSellBySubUnit || isHomePage" class="mb-3">
    <span class="subunits-input subunits p-4 bg-grey w-100 text-start d-block">{{product.unitMeasure}}</span>
</div>
<app-ka-dropdown
    *ngIf="product.enabledToSellBySubUnit && !isHomePage"
    class="w-100 w-lg-auto mt-3 mb-3 subunits dropdown-subunits"
    [filter]="subUnits"
    [onlyForSubunits]="true"
    [product]="product"
    (selectOption)="subunitSelected($event)">
</app-ka-dropdown>

