import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-temp-pass-expired-modal',
  templateUrl: './temp-pass-expired-modal.component.html',
})
export class TempPassExpiredModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  confirm(): void {
    this.activeModal.close();
  }
}
