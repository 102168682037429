import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';

@Pipe({
  standalone: true,
  name: 'weekdayDayMonthParser',
  pure: true,
})
export class WeekdayDayMonthParserPipe implements PipeTransform {
  constructor(private translationService: TranslationService) { }
  
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return '';
    }

    const languageEnum = this.translationService.getCurrentLanguage();
    const dayOfWeekFormatter = new Intl.DateTimeFormat(languageEnum, { weekday: 'long' });
    const dayFormatter = new Intl.DateTimeFormat(languageEnum, { day: 'numeric' });
    const monthFormatter = new Intl.DateTimeFormat(languageEnum, { month: 'short' });

    const dayOfWeek = this.capitalizeFirstLetter(dayOfWeekFormatter.format(date));
    const day = dayFormatter.format(date);
    const month = this.capitalizeFirstLetter(monthFormatter.format(date));

    return `${dayOfWeek} ${day} ${month}`;
  }

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}