import { Pipe, PipeTransform } from '@angular/core';
import { DefaultImages } from '../../core/enums/default-images';
import { ConnectionSpeedService } from '../../core/services/connection-speed.service';
import { ConnectionType } from 'src/app/core/enums/connection-type.enum';

@Pipe({
  name: 'toThumbnail',
})
export class ToThumbnailPipe implements PipeTransform {
  private readonly ConnectionType = ConnectionType;

  constructor(private connectionSpeedService: ConnectionSpeedService) {}

  transform(src: string, zoom = false): string {
    if (
      src.includes(DefaultImages.DISCOUNT) ||
      src.includes(DefaultImages.PRODUCT)
    ) {
      return src;
    }

    const networkSpeed = this.connectionSpeedService.getConnectionSpeed();
    const suffix = networkSpeed === this.ConnectionType.LOW ? '-LOW' : '';

    return zoom
      ? `${src.substring(0, src.lastIndexOf('.'))}${suffix}.webp`
      : `${src.substring(0, src.lastIndexOf('.'))}_thumbnail${suffix}.webp`;
  }
}
