import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

import { RadioGroup } from '@components/radio/directives/radio-group.directive';
import { RadioComponent } from '@components/radio/radio.component';
import { env } from 'src/app/app.component';

import { Client, VisitDate } from 'src/app/core/models/client.model';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { WeekdayDayMonthParserPipe } from '../../pipes/week-day-day-month.pipe';

import { ComponentsModule } from '@components/components.module';

import { CurrencyPipe } from '../../pipes/currency.pipe';
import { BoxesPipe } from '../../pipes/boxes.pipe';
import { DateUtility } from 'src/app/core/utils/app-date-utils';
import { AppUtils } from 'src/app/core/utils/app-utils';

import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { ClientService } from 'src/app/core/services/client.service';

export const SMART_DELIVERY = 'smartDelivery';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RadioComponent,
    RadioGroup,
    ReactiveFormsModule,
    WeekdayDayMonthParserPipe,
    TranslateModule,
    BoxesPipe,
    ComponentsModule,
  ],
  providers: [
    CurrencyPipe,
  ],
  selector: 'app-options-delivery-date',
  templateUrl: './options-delivery-date.component.html',
  styleUrls: ['./options-delivery-date.component.scss'],
})
export class OptionsDeliveryDateComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription;
  get deliveryDates(): VisitDate[] {
    return this._visitDateService.deliveryDatesWithSmartDeliveryDates;
  }
  get deliveryDatesOffRoute(): VisitDate[] {
    return this._visitDateService.deliveryDatesOffRoute;
  }
  get smartDeliveryDates(): VisitDate[] {
    return this._visitDateService.smartDeliveryDates;
  }
  get currentVisitDate(): string | Date {
    return this._visitDateService.currentVisitDate;
  }
  get client$(): Observable<Client> {
    return this._clientService.getClientUntilChanged$();
  }
  get hasClientVisitDates(): boolean {
    return this.deliveryDates?.length > 0;
  }
  get hasClientVisitDatesOffRoutes(): boolean {
    return this.deliveryDatesOffRoute?.length > 0;
  }
  get hasClientVisitDatesSmartDelivery(): boolean {
    return this.smartDeliveryDates?.length > 0;
  }
  get labelAmountMinRoute(): Observable<string> {
    return this._clientService.getClientAmountMinRoute();
  }
  @Output() selectedVisitDate = new EventEmitter<VisitDate>();
  control: FormControl = this._formBuilder.control('');
  deliveryDatesToShow: VisitDate[] = [];
  smartDeliveryDatesToShow: VisitDate[] = [];

  constructor(
    private _visitDateService: VisitDatesService,
    private _formBuilder: FormBuilder,
    private _clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.getVisitDatesByClient();
    this.setUpForm();
  }

  private getVisitDatesByClient(): void {
    this._subscription.add(this._visitDateService.getVisitDatesByClient());
  }

  private setUpForm(): void {
    this._subscription.add(
      this._visitDateService.clientCartStream$.subscribe({
        next: () => {
          this.control = this._formBuilder.control(this.getInitialValue());
          this.updateSelectVisitDate();

          if (this.isVisitDateOneLength()) {
            this.control.disable();
          }
        },
      })
    );
  }

  private getInitialValue(): VisitDate {
    let initialControlValue: VisitDate;

    this._visitDateService.clientVisitDates?.forEach((date) => {
      if (DateUtility.isSameDay(date.visitDate, this.currentVisitDate)) {
        initialControlValue = date;
      }
    });

    return initialControlValue
      ? initialControlValue
      : this._visitDateService.clientVisitDates[0];
  }

  private updateSelectVisitDate(): void {
    this._subscription.add(
      this.control.valueChanges.pipe(
        startWith(this.control.value),
        distinctUntilChanged((prev: VisitDate, curr: VisitDate) => {
          return prev.visitDate === curr.visitDate;
        }),
      ).subscribe({
        next: (value) => this.selectedVisitDate.emit(value),
      })
    );
  }

  private isVisitDateOneLength(): boolean {
    return this._visitDateService.clientVisitDates.length === 1;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
