import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HeadDocumentDescription } from '../enums/head-document-description';
import { CountryCodes } from '../enums/country-codes.enum';

@Injectable({
  providedIn: 'root'
})
export class HeadDocumentService {
  constructor(private titlePlatformService: Title) {}

  setTitleByCountry(country: CountryCodes) {
    const title = HeadDocumentDescription[country] ?? HeadDocumentDescription.CL;
    this.titlePlatformService.setTitle(title);
  }
}