import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { env } from 'src/app/app.component';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { PaymentMethodBySegement } from 'src/app/core/models/payment-method-by-segment.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { CartService } from 'src/app/core/services/cart.service';
import { FEATURES } from 'src/environments/utils/env.model';

@Component({
  selector: 'app-ka-delivery-details',
  templateUrl: './ka-delivery-details.component.html',
  styleUrls: ['./ka-delivery-details.component.scss'],
})
export class KaDeliveryDetailsComponent implements OnInit, OnDestroy {
  @Input() step;
  cart: Cart;
  client: Client;
  user: UserInfo;
  showFrozenDates: boolean;
  private subscriptions = new Subscription();
  readonly ROOT_LANG = 'NEW_ORDER.ORDER_DETAIL.';
  readonly ROOT_LANG_1 = 'MY_WALLET.AVAILABLE_CREDITS.';
  isMultiplePaymentMethodAvailable = false;
  accumulatedPaymentMethods: PaymentMethodBySegement[] = [];

  constructor(
    private store: Store<{ cart: Cart; client: Client; user: UserInfo }>,
    public cartService: CartService,
  ) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;
        this.initPaymentMethods();
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.loadAvailableFeatures();
  }

  ngOnInit(): void {
    this.showFrozenDates = this.frozenDates();
  }

  frozenDates(): boolean {
    return this.user.countryId === 'CL' && this.cart.hasDeliveryFrozenProducts;
  }

  loadAvailableFeatures() {
    this.isMultiplePaymentMethodAvailable = env.isFeatureAvailable(
      FEATURES.PAYMENT_METHODS_BY_CATEGORY,
    );
  }

  private initPaymentMethods() {
    if (this.cart.multiplePaymentMethodsBySegment) {
      this.accumulatedPaymentMethods = Object.values(
        this.cart.multiplePaymentMethodsBySegment?.reduce(
          (acc, paymentMethodBySegment) => {
            if (!acc[paymentMethodBySegment.paymentMethod.erpPaymentMethodId]) {
              acc[paymentMethodBySegment.paymentMethod.erpPaymentMethodId] = {
                ...paymentMethodBySegment,
              };
            } else {
              acc[
                paymentMethodBySegment.paymentMethod.erpPaymentMethodId
              ].spent += paymentMethodBySegment.spent;
            }
            return acc;
          },
          {},
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
