export enum OrderStatus {
  initiated = 'initiated',
  discarded = 'discarded',
  created = 'created',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',

  REGISTERED = 'REGISTERED',
  PREPARING = 'PREPARING',
  TRANSIT = 'TRANSIT',
  DELIVERED_CLT = 'DELIVERED_CLT',
  RETURNED = 'RETURNED',
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
  PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
  ORDER_NOT_PROCESSED = 'ORDER_NOT_PROCESSED',
  CANCELLATION_REQUEST = 'CANCELLATION_REQUEST',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
