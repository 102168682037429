import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';

import { ModalMobileDeliveryDateService } from './services/modal-mobile-delivery-date.service';
import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { SelectDeliveryDateInfoService } from './services/select-delivery-date-info.service';
import { ModalDesktopDeliveryDateService } from './services/modal-desktop-delivery-date.service';
import { ClientService } from 'src/app/core/services/client.service';

import { Client, VisitDate } from 'src/app/core/models/client.model';
import { OptionsDeliveryDateComponent } from '../options-delivery-date/options-delivery-date.component';
import { ButtonComponent } from '@components/button/button.component';

@Component({
  standalone: true,
  imports: [
    ComponentsModule,
    TranslateModule,
    OptionsDeliveryDateComponent,
    CommonModule,
    ButtonComponent,
  ],
  selector: 'app-select-delivery-date',
  templateUrl: './select-delivery-date.component.html',
  styleUrls: ['./select-delivery-date.component.scss']
})
export class SelectDeliveryDateComponent implements OnDestroy {
  private selectedVisitDate: VisitDate;
  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }
  get title(): string {
    return this._selectDeliveryDateInfoService.getTitle();
  }
  get subtitle(): string {
    return this._selectDeliveryDateInfoService.getSubTitle();
  }
  get showCloseItems(): boolean {
    return this._modalMobileDeliveryDateService.canCloseWithShadow
      && this._modalDesktopDeliveryDateService.backdrop === true;
  }
  get client$(): Observable<Client> {
    return this.clientService.getClientUntilChanged$();
  }
  get labelAmountMinRoute(): Observable<string> {
    return this.clientService.getClientAmountMinRoute();
  }
  get smartDeliveryDates(): VisitDate[] {
    return this._visitDateService.smartDeliveryDates;
  }
  @Output() closeModalDesktopEvent = new EventEmitter<void>;

  constructor(
    private _visitDateService: VisitDatesService,
    private _isMobileService: IsMobileService,
    private _modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
    private _modalDesktopDeliveryDateService: ModalDesktopDeliveryDateService,
    private _selectDeliveryDateInfoService: SelectDeliveryDateInfoService,
    private clientService: ClientService,
  ) { }

  updateCurrentVisitDate(): void {
    if (!this.selectedVisitDate) {
      return;
    }
    if (this._visitDateService.isSameDeliveryDate(this.selectedVisitDate)) {
      this.closeModal();
      return;
    }

    this.closeModal();
    this._visitDateService.updateCurrentVisitDateBySelectedDate(this.selectedVisitDate);
  }

  closeModal(): void {
    this.isMobile
      ? this._modalMobileDeliveryDateService.closeModal()
      : this.closeModalDesktopEvent.emit();
  }

  updateSelectedDate(selectedVisitDate: VisitDate): void {
    this.selectedVisitDate = selectedVisitDate;
  }

  ngOnDestroy(): void {
    this.isMobile
      ? this._modalMobileDeliveryDateService.resetCanCloseWithShadow()
      : null;
    this._selectDeliveryDateInfoService.resetTexts();
  }
}
