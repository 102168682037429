import { Component, Input } from '@angular/core';
import { Product } from 'src/app/core/models/product.model';

@Component({
  selector: 'app-ka-add-to-cart-button-label',
  templateUrl: './ka-add-to-cart-button-label.component.html',
  styleUrls: ['./ka-add-to-cart-button-label.component.scss']
})
export class KaAddToCartButtonLabelComponent {
  @Input() selectedProductOnCart: Product;
  @Input() product: Product;
  readonly ROOT_LANG = 'NEW_ORDER.BUTTON_ADD.';
}
