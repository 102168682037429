import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { RADIO_GROUP, RadioGroup } from './directives/radio-group.directive';
import { CommonModule } from '@angular/common';

let nextUniqueId = 0;

export class MatRadioChange {
  constructor(
    public source: RadioComponent,
    public value: any,
  ) { }
}

@Component({
  standalone: true,
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  imports: [CommonModule],
  host: {
    '[attr.id]': 'id',
    '[class.radio-checked]': 'checked',
    '(focus)': '_inputElement.nativeElement.focus()',
  },
})
export class RadioComponent implements OnInit, OnDestroy {
  private _uniqueId: string = `radio-${++nextUniqueId}`;
  private _value: any = null;
  private _checked: boolean = false;
  private _disabled: boolean;
  private _required: boolean;
  private _removeUniqueSelectionListener: () => void = () => { };
  radioGroup: RadioGroup;

  @Input() id: string = this._uniqueId;
  @Input() name: string;
  @Input()customWidthLabel? = false;
  @Output() radioClickDispatch : EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly change: EventEmitter<MatRadioChange> = new EventEmitter<MatRadioChange>();
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (this._checked !== value) {
      this._checked = value;
      if (value && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (!value && this.radioGroup && this.radioGroup.value === this.value) {
        this.radioGroup.selected = null;
      }

      if (value) {
        this._radioDispatcher.notify(this.id, this.name);
      }
      this._changeDetector.markForCheck();
    }
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup !== null) {
        if (!this.checked) {
          this.checked = this.radioGroup.value === value;
        }
        if (this.checked) {
          this.radioGroup.selected = this;
        }
      }
    }
  }

  @Input()
  get disabled(): boolean {
    return this._disabled || (this.radioGroup !== null && this.radioGroup.disabled);
  }
  set disabled(value: boolean) {
    this._setDisabled(value);
  }

  @Input()
  get required(): boolean {
    return this._required || (this.radioGroup && this.radioGroup.required);
  }
  set required(value: boolean) {
    this._required = value;
  }

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _radioDispatcher: UniqueSelectionDispatcher,
    @Optional() @Inject(RADIO_GROUP) radioGroup: RadioGroup,
  ) {
    this.radioGroup = radioGroup;
  }

  ngOnInit() {
    if (this.radioGroup) {
      this.checked = this.radioGroup.value === this._value;

      if (this.checked) {
        this.radioGroup.selected = this;
      }

      this.name = this.radioGroup.name;
    }

    this._removeUniqueSelectionListener = this._radioDispatcher.listen((id, name) => {
      if (id !== this.id && name === this.name) {
        this.checked = false;
      }
    });
  }

  protected _setDisabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this._changeDetector.markForCheck();
    }
  }

  _markForCheck() {
    this._changeDetector.markForCheck();
  }

  _onInputInteraction(event: Event) {
    event.stopPropagation();

    if (!this.checked && !this.disabled) {
      const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
      this.checked = true;
      this._emitChangeEvent();

      if (this.radioGroup) {
        this.radioGroup._controlValueAccessorChangeFn(this.value);
        if (groupValueChanged) {
          this.radioGroup._emitChangeEvent();
        }
      }
    }
  }

  _onTouchTargetClick(event: Event) {
    this._onInputInteraction(event);

    if (!this.disabled) {
      this._inputElement.nativeElement.focus();
    }
  }

  radioClick(){
    this.radioClickDispatch.emit();
  }

  private _emitChangeEvent(): void {
    this.change.emit(new MatRadioChange(this, this._value));
  }

  ngOnDestroy() {
    this._removeUniqueSelectionListener();
  }
}
