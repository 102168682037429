<div>
  <div class="modal-header border-top-red">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button 
      type="button" 
      class="btn-close" 
      data-bs-dismiss="modal" 
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
    </button>
  </div>

  <div class="modal-body px-5 pb-5">
    <div class="d-flex justify-content-center align-items-center">
      <img class="not-found-img mb-3 w-50 p-4" src="/assets/media/emojis/welcome.png" alt="" />
    </div>

    <h1 class="title">{{ ROOT_LANG + 'TITLE' | translate }}</h1>

    <p class="mt-5 text">
      {{ ROOT_LANG + 'TEXT.PART_1' | translate }}
      <span class="mid-strong">{{ ROOT_LANG + 'TEXT.PART_2' | translate }}</span> 
      {{ ROOT_LANG + 'TEXT.PART_3' | translate }}
      <a href="https://wa.me/{{ ROOT_LANG + 'WHATSAPP' | translate }}" target="_blank">{{ ROOT_LANG + 'WHATSAPP' | translate }}</a>
    </p>
  
  </div>

  <div class="modal-footer d-flex justify-content-center">
    <button
      id="ppd-block-modal"
      type="button"
      class="btn btn-black w-50 border-0"
      (click)="activeModal.dismiss()"
    >
    {{ ROOT_LANG + 'BUTTON' | translate }}
    </button>
  </div>
</div>
