import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-footer-fixed',
  templateUrl: './footer-fixed.component.html',
  styleUrls: ['./footer-fixed.component.scss'],
})
export class FooterFixedComponent implements AfterViewInit, OnDestroy {
  @ViewChild('footer') footer: ElementRef<HTMLDivElement>;
  @Output() footerHeightEvent = new EventEmitter<number>();

  ngOnDestroy(): void {
    this.footerHeightEvent.emit(0);
  }

  ngAfterViewInit(): void {
    this.calculateFooterHeight();
    fromEvent(window, 'resize')
      .pipe(debounceTime(250))
      .subscribe(() => this.calculateFooterHeight());
  }

  private calculateFooterHeight(): void {
    const footerHeight = this.footer.nativeElement.offsetHeight;
    this.footerHeightEvent.emit(footerHeight);
  }
}
