import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/core/models/cart.model';
import { updateFrozenVisitDate, updateVisitDateSuccess } from 'src/app/core/state/actions/cart.actions';
import { getCartFrozenVisitDate } from 'src/app/core/state/reducers/cart.reducer';
import { Client } from 'src/app/core/models/client.model';
import { getClientVisitDates, getClientFrozenVisitDates } from 'src/app/core/state/reducers/client.reducer';
import { Subscription } from 'rxjs';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
@Component({
  selector: 'app-ka-select-delivery-date',
  templateUrl: './ka-select-delivery-date.component.html',
  styleUrls: ['./ka-select-delivery-date.component.scss'],
})
export class KaSelectDeliveryDateComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() titleTranslate: string;
  @Input() visitType: string;
  cart: Cart;
  visitDates: any;
  selectedDate: any;

  constructor(private store: Store<{ cart: Cart; client: Client }>) {
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => {
      this.cart = cart;
      if (this.visitType !== 'deliveryfrozen' && cart.visitDate) {
        this.selectedDate = cart.visitDate;
      }
      if (this.visitType === 'deliveryfrozen' && cart.frozenVisitDate) {
        this.selectedDate = cart.frozenVisitDate;
      }
    }));
  }

  ngOnInit(): void {
    if (this.visitType !== 'deliveryfrozen') {
      this.subscriptions.add(this.store.select(getClientVisitDates).subscribe((visitDates) => {
        this.visitDates = visitDates;
        this.getDeliveryDates();
      }));
    }

    if (this.visitType === 'deliveryfrozen') {
      this.subscriptions.add(this.store.select(getClientFrozenVisitDates).subscribe((visitDates) => {
        this.visitDates = visitDates;
      }));

      this.subscriptions.add(
        this.store.select(getCartFrozenVisitDate).subscribe((newVisitDate) => {
          this.selectedDate = newVisitDate ? new Date(newVisitDate).toISOString() : this.visitDates[0].visitDate;
        })
      );
      
      this.store.dispatch(updateFrozenVisitDate({ date: this.selectedDate }));
    }
  }

  getDeliveryDates(): void {
    if (this.visitType !== 'deliveryfrozen') {
      let visitDate = !!this.visitDates.length ? this.visitDates[0].visitDate : '';
      this.selectedDate = this.cart.visitDate ? new Date(this.cart.visitDate).toISOString() : visitDate;
      if (this.visitDates.length === 0) this.selectedDate = '';
    }
  }

  selectDate(date: string) {
    this.selectedDate = date;
    const visitDatePicked = this.visitDates.find((d) => date.split('T')[0]  === d.visitDate.split('T')[0] );

    if (this.visitType !== 'deliveryfrozen') {
      this.store.dispatch(updateVisitDateSuccess({ date }));
      this.store.dispatch(CartActions.updateOffRoute({ offRoute: visitDatePicked.offRoute}));
    } else {
      this.store.dispatch(updateFrozenVisitDate({ date }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
