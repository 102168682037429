import { Component } from '@angular/core';

@Component({
  selector: 'app-other-device-login-warning',
  templateUrl: './other-device-login-warning.component.html'
})

export class OtherDeviceLoginWarningComponent {

  reloadPage() {
    window.top.location.reload();
  }

}
