// days.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'pluralizeDays',
})
export class DaysPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(start: Date, end: Date): string {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const millisecondsInOneDay = 24 * 60 * 60 * 1000;

    const differenceInDays = Math.floor(
      differenceInMilliseconds / millisecondsInOneDay,
    );

    return differenceInDays === 1
      ? this.translateService.instant('DAY', { day: differenceInDays })
      : differenceInDays === 0
        ? this.translateService.instant(
            'MY_TICKETS.TICKET_DETAIL.CHECKING_TICKET_TIME',
          )
        : this.translateService.instant('DAYS', { day: differenceInDays });
  }
}
