<div class="buttons-conteiner">
  <button *ngIf="!suggested && !hideButton" class="slider-button left" (click)="scrollLeft()">
    <img
      class="left-arrow"
      src="../../../../assets/icons/carousel-arrow.svg"
      alt=""
      srcset=""
    />
  </button>
  <button
    *ngIf="!suggested && !hideButton"
    class="slider-button right"
    (click)="scrollRight()"
  >
    <img src="../../../../assets/icons/carousel-arrow.svg" alt="" srcset="" />
  </button>
</div>
<div
  #container
  class="scroll-container"
  (mousedown)="mouseDownHandler($event)"
  (touchstart)="mouseDownHandler($event)"
  (mousemove)="mouseMoveHandler($event)"
  (touchmove)="mouseMoveHandler($event)"
  (mouseup)="mouseUpHandler()"
  (touchend)="mouseUpHandler()"
>
  <button
    *ngIf="suggested && !hideButton"
    class="slider-button left"
    [ngStyle]="{ position: 'absolute', top: '50%' }"
    (click)="scrollLeft()"
  >
    <img
      class="left-arrow"
      src="../../../../assets/icons/carousel-arrow.svg"
      alt=""
      srcset=""
    />
  </button>
  <div class="horizontal-scroll" [ngStyle]="{'white-space': whitespace ? 'nowrap' : ''}">
    <ng-content></ng-content>
  </div>
  <button
    *ngIf="suggested && !hideButton"
    class="slider-button right"
    [ngStyle]="{ position: 'absolute', top: '50%' }"
    (click)="scrollRight()"
  >
    <img src="../../../../assets/icons/carousel-arrow.svg" alt="" srcset="" />
  </button>
</div>
