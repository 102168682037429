import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductTypes } from 'src/app/core/enums/product-types';
import { QuickAccessButtonModel } from 'src/app/core/models/quick-access-button.models';

@Component({
  selector: 'app-ka-quick-access-button',
  templateUrl: './ka-quick-access-button.component.html',
  styleUrls: ['./ka-quick-access-button.component.scss'],
})
export class KaQuickAccessButtonComponent  {
  @Input() quickAccess: QuickAccessButtonModel;
  @Input() disabled = false;
  readonly ROOT = 'HOME.QUICK_ACCESS.';

  constructor(private router: Router) {
  }

  navigateTo(linked: string): void {
    if (this.quickAccess.filter == '') {
      const link = this.router.url.includes('mcc')
        ? '/mcc' + linked + '/'
        : '/main' +  linked + '/';
    this.router.navigate([link] , {queryParams: {grupo: this.quickAccess.filter}} );
    } else {
        let queryParamasType = null;
      const link = this.router.url.includes('mcc')
        ? '/mcc' + linked + '/'
        : '/main' + linked + '/';
        if (this.quickAccess.filter == ProductTypes.SUGGESTED) {
             queryParamasType = {filtro: this.quickAccess.filter};
        } else {
            queryParamasType = {grupo: this.quickAccess.filter};
        }
      this.router.navigate([ link ], {queryParams: queryParamasType }); 
    }
  }
}
