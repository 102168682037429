import { Component, Input, OnInit } from '@angular/core';
import { HistoryExchange } from 'src/app/core/models/history-exchange.model';

@Component({
  selector: 'app-promos-panel',
  templateUrl: './promos-panel.component.html',
  styleUrls: ['./promos-panel.component.scss'],
})
export class PromosPanelComponent {
  LANG_ROOT = 'DIGITAL_PROMOTIONS.HISTORY_EXCHANGE_CARD.';
  @Input() exchange: HistoryExchange;
  @Input() promotionId: number;

  isExpanded = false;

  panelOpened() {
    this.isExpanded = true;
  }

  panelClosed() {
    this.isExpanded = false;
  }
}
