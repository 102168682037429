import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-input',
  template: `
    <label for="{{id}}" *ngIf="label">{{label}}</label>
    <input
      [formControl]="control"
      [ngClass]="{ 'ka-icon-padding': icon }"
      type="{{typeInput || 'text'}}"
      id="{{id}}"
      (input)="onChange(value)"
      (focusout)="onTouched()"
    >
    <div
      class="ka-icon"
      [ngClass]="{ 'ka-icon-without-label': !label }"
      *ngIf="icon"
      (click)="handleClickIcon()"
      >
      <svg>
        <use attr.xlink:href="{{'./assets/icons/tabler-sprite.svg#' + icon}}"/>
      </svg>
    </div>
  `,
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() label!: string;
  @Input() typeInput!: string;
  @Input() id!: string;
  @Input() icon!: string;
  @Input() minLength!: string;
  @Input() maxLength!: string;
  @Output() clickIcon: EventEmitter<void> = new EventEmitter<void>();
  control!: AbstractControl;
  onChange: any = () => { };
  onTouched: any = () => { };
  private _disabled = false;

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.control.disable() : this.control.enable();
  }

  get value(): string | number {
    return this.control.value;
  }

  set value(value: string | number) {
    this.control.setValue(value);
  }

  constructor(
    fb: FormBuilder,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.control = fb.control('');
  }

  ngOnInit(): void {
    if (this.ngControl.control != null) {
      this.control = this.ngControl.control;
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleClickIcon(): void {
    if (!this._disabled) {
      this.clickIcon.emit();
    }
  }
}
