<div [class]="data.customClass">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button 
      *ngIf="!data.hideCloseButton" 
      type="button" 
      class="btn-close" 
      data-bs-dismiss="modal" 
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="data.image" class="d-flex justify-content-center align-items-center">
      <img class="not-found-img mb-3 w-50 p-4" [src]="'/assets/media/emojis/' + data.image" alt="" />
    </div>
    <h3 *ngIf="data.text" class="title mt-2">{{ data.text | translate }}{{ data.type | translate }}</h3>

    <p *ngIf="data.description" class="description mb-3">{{ data.description | translate }}</p>

    <div *ngIf="data.showAlert" class="d-flex flex-row justify-content-start align-items-center">
      <app-alert [type]="'warning'" [text]="data.alertText | translate"></app-alert>
    </div>
  </div>

  <div *ngIf="showFooterButtons" class="modal-footer d-flex justify-content-center">
    <button
      *ngIf="data.confirmBtnLabel"
      [id]="data.id + '-confirm-modal'"
      class="btn-square-black"
      [ngClass]="{ 'btn-wide': data.btnWide }"
      (click)="confirm()"
    >
      {{ data.confirmBtnLabel | translate }}
    </button>
  </div>
</div>
