import { Pipe, PipeTransform } from '@angular/core';
import { Cart } from '../../core/models/cart.model';

@Pipe({
  name: 'enabledNart',
})
export class EnabledNartPipe implements PipeTransform {
  transform(cart: Cart): boolean {
    return !cart.hasDeliveryNotFrozenProducts || cart.minPurchaseReached;
  }
}
