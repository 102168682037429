<div class="suggested-button w-100 p-4 card d-flex flex-column flex-md-row align-items-end align-items-md-center justify-content-between" *ngIf="lastOrder">
  <div class="d-flex flex-row align-items-center suggested-main">
    <div class="suggested-img mr-8">
      <svg><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-' + ordersService.getIconName(lastOrder?.status)" /></svg>
    </div>
    <div class="suggested-text d-flex flex-column align-items-start justify-content-start">
      <p class="title strong mb-2">{{ 'HOME.MY_ORDER.LABEL' | translate }} #{{ lastOrder.orderId }}</p>
      <p class="subtitle mb-2">
        {{ 'HOME.MY_ORDER.DATE' | translate }}: <span class="mid-strong">{{ lastOrder.deliveryDate | kaDate }}</span>
      </p>
      <p class="subtitle mb-2">
        {{ 'HOME.MY_ORDER.TOTALS' | translate }}: <span class="mid-strong">{{ lastOrder.amount | kaCurrency }}</span>
      </p>
      <p class="subtitle mb-2">
        {{ ROOT_LANG + 'SALES_CHANNEL' | translate }}<span class="mid-strong">{{ 'SOURCE_CHANNELS.' + lastOrder.sourceChannel | translate: { Default: lastOrder.sourceChannel } }}</span>
      </p>
      <p class="subtitle mb-2 mb-lg-0">
        {{ ROOT_LANG + 'PAYMENT_METHOD' | translate }}<span class="mid-strong">{{ lastOrder.PaymentMethod.name }}</span>
      </p>
      <div class="order-status d-block d-lg-none mt-2">
        <app-ka-status-pill [status]="lastOrder.status"></app-ka-status-pill>
      </div>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <app-ka-status-pill [status]="lastOrder.status"></app-ka-status-pill>
  </div>
  <div *ngIf="lastOrder.orderId" class="suggested-cta d-flex flex-row align-items-center flex-end mt-4 mt-md-0" (click)="goOrderDetail()">
    <span>{{ ROOT_LANG + 'SHOW_DETAILS' | translate }}</span>
    <svg><use [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-chevron-right'" /></svg>
  </div>
</div>
