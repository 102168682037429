import { Directive, Renderer2, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appNewLabel]',
})
export class NewLabelDirective implements OnInit {
  readonly LABEL = 'HOME.CATEGORIES_CAROUSEL.NEW';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.createLabel();
  }

  private createLabel() {
    const wrapper = this.renderer.createElement('div');
    const newLabel = this.translateService.instant(this.LABEL);
    const text = this.renderer.createText(newLabel);

    const styles = getComputedStyle(document.documentElement);
    const backgroundColor = styles.getPropertyValue('--light-green').trim();
    const color = styles.getPropertyValue('--dark-green').trim();

    this.renderer.setStyle(wrapper, 'position', 'absolute');
    this.renderer.setStyle(wrapper, 'bottom', '0');
    this.renderer.setStyle(wrapper, 'left', '50%');
    this.renderer.setStyle(wrapper, 'transform', 'translateX(-50%)');
    this.renderer.setStyle(wrapper, 'background-color', backgroundColor);
    this.renderer.setStyle(wrapper, 'color', color);
    this.renderer.setStyle(wrapper, 'padding', '1px 12px');
    this.renderer.setStyle(wrapper, 'border-radius', '15px');
    this.renderer.setStyle(wrapper, 'font-weight', 'bold');
    this.renderer.setStyle(wrapper, 'text-align', 'center');
    this.renderer.setStyle(wrapper, 'font-size', '16px');
    this.renderer.setStyle(wrapper, 'font-family', 'Gotham Bold');

    this.renderer.addClass(wrapper, 'fadeIn');

    this.renderer.appendChild(wrapper, text);
    this.renderer.appendChild(this.el.nativeElement, wrapper);
  }
}
