<div class="modal-body pt-5 p-3">
  <div class="d-flex justify-content-center align-items-center">
    <img class="not-found-img mb-3 w-50 p-4" src="/assets/media/emojis/hand-up.png" alt="" />
  </div>
  <h2 class="mb-4 modal-body__title">{{ LANG_ROOT + 'TITLE' | translate }}</h2>
  <div class="mt-4 warn d-flex align-items-center justify-content-center w-100">
    <p class="w-50 text-center">{{ LANG_ROOT + 'SPAN1' | translate }}</p>
  </div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <div class="col-12 col-lg-6 mb-3 d-flex justify-content-center">
    <button id="continue_order_modal" class="btn-square-black" (click)="continueDraft()">{{ LANG_ROOT + 'CONFIRM' | translate }}</button>
  </div>
  <div class="col-12 col-lg-6 d-flex justify-content-center">
    <button id="delete_order_modal" type="button" class="btn-square border-0 w-100 mx-auto" (click)="deleteDraft()">
      {{ LANG_ROOT + 'DISCART' | translate }}
    </button>
  </div>
</div>
