<div class="select-date d-flex flex-column justify-content-between align-items-center">
  <p *ngIf="titleTranslate" class="label fw-bold">{{ titleTranslate | translate }}</p>

  <div *ngIf="visitDates" class="select-group w-100">
    <div class="px-0 w-100 d-inline-block" ngbDropdown>
      <button type="button" class="btn px-1 py-0 w-100 d-flex flex-row align-items-center justify-content-between" id="dropdownDates" ngbDropdownToggle>
        <span class="selected-date">{{ selectedDate | kaDate }}</span>
        <svg><use [ngClass]="{'d-none' : visitDates.length < 2 }" [attr.xlink:href]="'./assets/icons/tabler-sprite.svg#tabler-chevron-down'" /></svg>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownDates" [ngClass]="{'d-none' : visitDates.length < 2 }">
        <ng-container *ngFor="let date of visitDates; first as isFirst">
          <button *ngIf="date.visitDate !== selectedDate" ngbDropdownItem (click)="selectDate(date.visitDate)">
            {{ date.visitDate | kaDate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <p *ngIf="!visitDates || visitDates.length === 0" class="mid-strong text-danger small text-center mt-3">
    {{ 'HOME.MY_DELIVERY_DATES_SELECT_NOT_AVAILABLE' | translate }}
  </p>
</div>

