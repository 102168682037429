<div
  *ngIf="selectedClient?.data?.drafts[0]?.items.length > 0"
  [matBadge]="
    selectedClient?.data?.drafts[0]?.items.length | notificationMaxProduct
  "
  matBadgeSize="small"
  matBadgeColor="warn"
  class="cart-notification"
  (click)="goPortfolio(true)"
>
  <img class="cart-icon" [src]="'./assets/icons/cart.svg'" alt="" />
</div>
<div
  class="cart-notification"
  *ngIf="!(selectedClient?.data?.drafts[0]?.items.length > 0)"
  (click)="goPortfolio(false)"
>
  <img class="cart-icon" [src]="'./assets/icons/cart.svg'" alt="" />
</div>
