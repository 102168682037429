<ng-container *ngIf="commerceInfo && selectedClient?.erpClientId">
  <!-- Un solo cliente -->
  <ng-container *ngIf="isSingleClient">
    <div class="icon-commerce">
      <img src="assets/icons/commerce.svg" alt="Icono de tienda" />
      <div>
        <p class="commerce-info">{{ commerceInfo }}</p>
        <p class="commerce-erp">{{ selectedClient.erpClientId }}</p>
      </div>
    </div>
  </ng-container>

  <!-- Múltiples clientes en móvil -->
  <ng-container *ngIf="isMobile && !isSingleClient">
    <div class="icon-commerce" (click)="openSelectCommerceModal()">
      <img src="assets/icons/commerce.svg" alt="Icono de tienda" />
      <div>
        <p class="commerce-info">
          {{ commerceInfo }}
        </p>
        <p class="commerce-erp">
          {{ selectedClient.erpClientId }}
        </p>
      </div>
      <svg class="icon-arrow">
        <use
          xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
        />
      </svg>
    </div>
  </ng-container>

  <!-- Múltiples clientes en escritorio -->
  <ng-container *ngIf="!isMobile && !isSingleClient">
    <div class="dropdown-container">
      <button class="selected-address" (click)="toggleDropdown()">
        <img src="assets/icons/commerce.svg" alt="Icono de tienda" />
        <div>
          <p class="commerce-info">{{ commerceInfo }}</p>
          <p class="commerce-erp">{{ selectedClient.erpClientId }}</p>
        </div>
        <svg class="icon-arrow">
          <use
            xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down"
          />
        </svg>
      </button>
      <div class="dropdown-list" *ngIf="isDropdownOpen">
        <ng-container *ngFor="let client of clients">
          <div
            class="dropdown-item"
            *ngIf="client.clientId !== selectedClient.clientId"
            (click)="selectClient(client)"
          >
            <span>{{ client.commerceInfo }}</span>
            <svg class="icon-right-arrow">
              <use
                xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-right"
              />
            </svg>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
