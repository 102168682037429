import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { ParserUtils } from 'src/app/core/utils/parser-utils';

import { UserService } from 'src/app/core/services/user.service';
import { PrincipalMenuService } from 'src/app/core/services/principal-menu.service';
import { SubmenuService } from '../ka-sidebar/services/submenu.service';
import { SidebarLoyaltyService } from '../ka-sidebar/services/sidebar-loyalty.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';

@Component({
  selector: 'app-ka-menu-items',
  templateUrl: './ka-menu-items.component.html',
  styleUrls: ['./ka-menu-items.component.scss'],
})
export class KaMenuItemsComponent implements OnInit, OnDestroy {
  sidebarOptions: any[] = [];
  isNewOrderSelected = false;

  client: Client;
  cart: Cart;
  user: UserInfo;

  private subscriptions = new Subscription();
  get isMobile(): boolean {
    return this.isMobileService.isMobile;
  }

  readonly ParserUtils = ParserUtils;

  constructor(
    private router: Router, 
    private store: Store<{ user: UserInfo; client: Client; cart: Cart }>,
    private userService: UserService,
    private principalMenuService: PrincipalMenuService,
    private sidebarLoyaltyService: SidebarLoyaltyService,
    private subMenuService: SubmenuService,
    private isMobileService: IsMobileService,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
        this.sidebarOptions.forEach((opt) => {
          if (opt.icon === 'shopping-cart') opt.disabled = this.client.hasLockOrder || this.client.data.visitDates.length === 0;
        });
      })
    );
    this.subscriptions.add(this.store.select('cart').subscribe((cart) => (this.cart = cart)));
    this.subscriptions.add(this.store.select('user').subscribe((user) => (this.user = user)));
  }

  ngOnInit(): void {
    this.sidebarOptions = this.principalMenuService.getPrincipalMenuItems(this.isMobile);
    this.updateNavOptions(this.router.url.split('/')[2]);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects.split('/')[2];
        this.updateNavOptions(currentRoute);
      }
    });

    this.sidebarLoyaltyService.setupLoyaltyOptions(this.sidebarOptions);
    this.sidebarLoyaltyService.showItemMenuDigitalPromotions(this.sidebarOptions);
  }

  openCloseSubMenu(option: any): void {
    this.subMenuService.openCloseItem(option);
  }

  goToIframe(url: string):void {
    this.sidebarLoyaltyService.saveURLintoStore(url);
  }

  updateNavOptions(currentRoute): void {
    this.isNewOrderSelected = currentRoute === 'nuevo-pedido';
    this.sidebarOptions.forEach((opt) => {
      opt.active = opt.routerLink?.split('/')[0] === currentRoute;
    });
  }

  optionSelected(optSelected): void {
    if (optSelected.disabled) return;
    
    if (!optSelected.external) {
      this.sidebarOptions.forEach((opt) => {
        opt.active = opt.langKey === optSelected.langKey;
      });
      this.isNewOrderSelected = optSelected.langKey === 'NEW_ORDER';
      this.router.navigate(['/main/' + optSelected.routerLink.replace(':clientId', this.client.clientId)]);
    } else {
      window.open(optSelected.routerLink, "_self");
    }
  }

  isDeadlineToday(): boolean {
    return moment(this.cart.invoiceDeadline).format('YYYYMMDD') === moment().format('YYYYMMDD');
  }

  isFirstDeliveryDate(): boolean {
    const visitDateIndex = this.client.data.visitDates.findIndex((date) => {
      return moment(date.visitDate).isSame(moment(this.cart.visitDate));
    });
    return visitDateIndex === 0;
  }

  signOut(): void {
    this.sidebarLoyaltyService.subscription?.unsubscribe();
    this.userService.signOut();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
