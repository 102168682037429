import { CountryConfig, FEATURES } from '../utils/env.model';

export const configAR: CountryConfig = {
  awsUserPoolId: 'us-east-1_0erMNnujF',
  awsClientId: '6pbp318higu14dh30hufj8bell',
  awsClientIdMCC: '2jg0p3gci9t4k1gn36d43cm3us',
  awsAuthFlow: 'USER_SRP_AUTH',
  oauthDomain: 'app.auth.qa.miportalb2b.com',
  identityPoolId: 'us-east-1:7870b244-240f-41b5-9a86-f1cc66c1e6bf',
  key: 'AR',
  label: 'Argentina',
  lang: 'es',
  organizationId: '3046',
  cpgId: '001',
  phoneRegex: new RegExp('^[0-9]{10,11}$'),
  homeStyle: 2,
  fiscalIdRegex: [new RegExp('^[0-9]{10,11}$')],
  maxlengthFiscalId: 11,
  minPurchase: 4000,
  hasOffRoute: false,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  digitalPaymentsUrl: 'https://qa-pagos.miandina.cl', 
  availableFeatures: [
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_BUTTON_CAROUSEL,
    FEATURES.DIGITAL_PROMOTIONS,
    FEATURES.SCANNER_QR,
    FEATURES.NEWSLETTERS
  ],
};

export const configBR: CountryConfig = {
  awsUserPoolId: 'us-east-1_0erMNnujF',
  awsClientId: '6pbp318higu14dh30hufj8bell',
  awsAuthFlow: 'USER_SRP_AUTH',
  oauthDomain: 'app.auth.qa.miportalb2b.com',
  identityPoolId: 'us-east-1:7870b244-240f-41b5-9a86-f1cc66c1e6bf',
  key: 'BR',
  label: 'Brasil',
  lang: 'pt',
  organizationId: '3048',
  cpgId: '001',
  phoneRegex: [new RegExp('^[0-9]{8,10}$')],
  homeStyle: 1,
  fiscalIdRegex: [
    new RegExp(
      '^([0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}|[0-9]{2}.?[0-9]{3}.?[0-9]{3}/?[0-9]{4}-?[0-9]{2})$',
    ),
  ],
  maxlengthFiscalId: 14,
  minPurchase: 150,
  hasOffRoute: false,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  surveyId: '10e27b6d-bdea-448c-96a6-467fc2cf1c98',
  digitalPaymentsUrl: 'https://qa-pagos.miandina.cl', 
  availableFeatures: [
    FEATURES.SOCIAL_LOGIN,
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_BUTTON_CAROUSEL,
    FEATURES.DIGITAL_PAYMENTS,
    FEATURES.MY_WALLET,
    FEATURES.NEWSLETTERS
  ],
};

export const configCL: CountryConfig = {
  awsUserPoolId: 'us-east-1_0erMNnujF',
  awsClientId: '6pbp318higu14dh30hufj8bell',
  awsAuthFlow: 'USER_SRP_AUTH',
  awsClientIdMCC: '2jg0p3gci9t4k1gn36d43cm3us',
  oauthDomain: 'app.auth.qa.miportalb2b.com',
  identityPoolId: 'us-east-1:7870b244-240f-41b5-9a86-f1cc66c1e6bf',
  key: 'CL',
  label: 'Chile',
  lang: 'es',
  organizationId: '3043',
  cpgId: '001',
  phoneRegex: new RegExp('^[0-9]{9}$'),
  homeStyle: 2,
  fiscalIdRegex: 'RUT',
  maxlengthFiscalId: 10,
  hasOffRoute: false,
  minPurchase: 0,
  minPurchaseFrozen: 0,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  digitalPaymentsUrl: 'https://qa-pagos.miandina.cl', 
  availableFeatures: [
    FEATURES.DOWNLOAD_INVOICE,
    FEATURES.CRM,
    FEATURES.SOCIAL_LOGIN,
    FEATURES.THANOS_ORDER_DETAIL,
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_ICON_CAROUSEL,
    FEATURES.DIGITAL_PAYMENTS,
    FEATURES.MY_WALLET,
    FEATURES.UPDATE_MIN_PURCHASE,
    FEATURES.PAYMENT_METHODS_BY_CATEGORY_ORDER_DETAIL,
    FEATURES.STOCK_DIGITAL,
    FEATURES.PAYMENT_METHODS_BY_CATEGORY,
    FEATURES.NEWSLETTERS
  ],
};

export const configPY: CountryConfig = {
  awsUserPoolId: 'us-east-1_nKyVnTW7b',
  awsClientId: '6p3230lrpenpea3g2lvq5guldt',
  awsAuthFlow: 'USER_PASSWORD_AUTH',
  oauthDomain: 'qa-mcc-py.auth.us-east-1.amazoncognito.com',
  identityPoolId: 'us-east-1:9f942abc-3bfb-474f-89dc-220b8c2fea22',
  key: 'PY',
  label: 'Paraguay',
  lang: 'es',
  minPurchase: 100000,
  hasOffRoute: true,
  organizationId: '3049',
  cpgId: '001',
  phoneRegex: new RegExp('^[0-9]{8,10}$'),
  homeStyle: 1,
  fiscalIdRegex: [
    new RegExp('^[0-9]{6,10}[-]{1}[0-9a-zA-Z]{1}$'),
    new RegExp('^[0-9]{6,8}$'),
  ],
  maxlengthFiscalId: 10,
  productsPerPagePortfolio: 30,
  visitPlanLength: 7,
  digitalPaymentsUrl: 'https://qa-pagos.miandina.cl', 
  availableFeatures: [
    FEATURES.CATEGORY_CAROUSEL,
    FEATURES.CATEGORY_ICON_CAROUSEL,
    FEATURES.DOWNLOAD_INVOICE,
    FEATURES.SOCIAL_LOGIN,
    FEATURES.DIGITAL_PAYMENTS,
    FEATURES.MY_WALLET,
    FEATURES.LOYALTY,
    FEATURES.DIGITAL_PROMOTIONS,
    FEATURES.SCANNER_QR,
    FEATURES.NEWSLETTERS,
    FEATURES.COMMERCE_FANTASY_NAME,
  ],
};
