<div ngbDropdown class="select-field-filter menu-filter-clicked" 
     [ngClass]="{ 'bg-white mr-0 mb-3': onlyForSubunits }"
     [id]="filter.key" #filters (click)="calculateDistanceFromBottom()">
  <div *ngIf="!selected" class="dropdown">
    <button class="btn btn-outline-primary" ngbDropdownToggle class="dropdown-header dropdown-elem menu-filter-clicked">
      <span class="menu-filter-clicked" *ngIf="onlyForSubunits">{{filter[1].value}}</span>
      <span class="menu-filter-clicked" *ngIf="!onlyForSubunits">{{ROOT_LANG + filter.key | uppercase | translate}}</span>
    </button>
  </div>
  <div class="dropdown-menu" ngbDropdownMenu *ngIf="onlyForSubunits">
    <button *ngFor="let filterOption of filter" ngbDropdownItem class="dropdown-elem menu-filter-clicked" (click)="updateFilter(filterOption)">
      <span class="filter-options menu-filter-clicked">{{ filterOption.value }}</span>
    </button>
  </div>
  <div class="dropdown-menu w-100" ngbDropdownMenu *ngIf="!onlyForSubunits && filter.value.length > 0"
       [ngStyle]="isMobile ? 
       {'height.px': distanceFromBottom, 'max-height': 'unset', 'border': '1px solid #f7f7f7', 'background': '#f7f7f7' } : 
       ''">
    <button *ngFor="let filterOption of filter.value" ngbDropdownItem class="dropdown-elem menu-filter-clicked" (click)="updateFilter(filterOption)">
      <span class="filter-options menu-filter-clicked">{{ filterOption }}</span>
    </button>
  </div>
  <div *ngIf="selected" class="filter-option-selected">
    <div ngbDropdownToggle class="filter-selected menu-filter-clicked dropdown">
      <div class="menu-filter-clicked filter-selected-category">{{ selected }}</div>
      <div class="d-flex align-items-center">
        <svg (click)="updateFilter('')" class="svg-img menu-filter-clicked" *ngIf="!onlyForSubunits">
          <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-x" />
        </svg>
        <svg class="svg-img menu-filter-clicked" *ngIf="onlyForSubunits">
          <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-chevron-down" />
        </svg>
      </div>
    </div>
  </div>
</div>
