<button
  class="quickbutton"
  (click)="navigateTo(quickAccess.addresNavigator)"
  appBestOption
  [isBestOption]="quickAccess.isBestOption"
  [ngClass]="{ disabled: disabled }"
  [disabled]="disabled"
>
  <svg [ngClass]="{ disabled: disabled }" class="img">
    <use attr.xlink:href="{{ quickAccess.image }}" />
  </svg>
  <span>
    {{ quickAccess.title | translate }}
  </span>
</button>
