<ng-container *ngIf="client$ | async as client">
  <radio-group [formControl]="control">
    <ng-container *ngIf="hasClientVisitDatesSmartDelivery">
      <div class="select-date-options-container">
        <div class="select-date-options-title smart-title">
          {{'NEW_ORDER.DELIVERY_PLAN.SMART_DELIVERY_TITLE' | translate}}
        </div>
        <div class="select-date-options-subtitle">
          {{ 'NEW_ORDER.DELIVERY_PLAN.SMART_DELIVERY_SUBTITLE' | translate }}
        </div>
        <div class="mb-16"></div>

        <div class="radio-container">
          <ng-container *ngFor="let date of smartDeliveryDates" >
            <div class="radio-option">
              <app-radio [value]="date">
                {{date.visitDate | weekdayDayMonthParser}}
              </app-radio>
            </div>
          </ng-container>
        </div>
      </div>      
    </ng-container>

    <ng-container *ngIf="hasClientVisitDates">
      <div 
        class="select-date-options-container"
        [ngClass]="{
          'paddint-top-zero' : hasClientVisitDatesSmartDelivery
        }">
        <div class="select-date-options-title">
          {{'NEW_ORDER.DELIVERY_PLAN.NAME' | translate}}
        </div>
        <div 
          *ngIf="client.amountMinRoute > 0 && !hasClientVisitDatesSmartDelivery"
          class="select-date-options-subtitle">
          {{'NEW_ORDER.DELIVERY_PLAN.MINIMUM_AMOUNT' | translate: { 
            amount: (labelAmountMinRoute | async) 
          } }}
        </div>
        <div class="mb-16"></div>
        
        <div class="radio-container">
          <ng-container *ngFor="let date of deliveryDates">
            <div class="radio-option">
              <app-radio [value]="date">
                {{date.visitDate | weekdayDayMonthParser}}
              </app-radio>
          
              <app-label 
                *ngIf="date?.source === 'smartDelivery'" 
                [text]="'NEW_ORDER.DELIVERY_PLAN.ADITIONAL_SD_DATE' | translate" 
                [class]="'success'">
              </app-label>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="hasClientVisitDatesOffRoutes">
      <div 
        class="select-date-options-container"
        [ngClass]="{'paddint-top-zero' : hasClientVisitDates}">
        <div class="select-date-options-title">
          {{'NEW_ORDER.DELIVERY_PLAN.OUT_OF_ROUTE' | translate}}
        </div>
        <div 
          *ngIf="client.quantityMinOffRoute > 0"
          class="select-date-options-subtitle">
          {{ client.quantityMinOffRoute | boxes }}
        </div>
        <div class="mb-16"></div>
        
        <div class="radio-container">
          <ng-container *ngFor="let date of deliveryDatesOffRoute">
            <div class="radio-option">
              <app-radio [value]="date">
                {{date.visitDate | weekdayDayMonthParser}}
              </app-radio>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </radio-group>
</ng-container>
