export const urlMapping: { [key: string]: string } = {
  '/1': '/delivery-date',
  '/2': '/portfolio',
  '/3': '/my-order-mobile',
  '/4': '/detail-order',
  '/5': '/payment-method',
  '/6': '/order-summary',
};

export function transformUrl(url: string): string {
  for (const key in urlMapping) {
    if (url.includes(key)) {
      return url.replace(key, urlMapping[key]);
    }
  }
  return url;
}
