<div class="ka-sidebar">
  <div class="image">
    <img class="rounded-circle img-fluid w-100" src="./assets/media/comercio.png" alt="" />
  </div>
  <div class="user-info">
    <h2 class="title-h2">{{ user.firstName }} {{ user.lastName }}</h2>
    <p class="role">{{ 'HOME.CLIENT.ROL.' + user.role | translate: { Default: user.role } }}</p>
  </div>

  <app-ka-block-message></app-ka-block-message>
  
  <div class="user-data">
    <p>
      <span class="mid-strong">{{ 'HOME.CLIENT.LABEL' | translate }}</span
      ><br />
      {{ selectedClient.erpClientId }}
    </p>
    <p>
      <span class="mid-strong">{{ 'HOME.CLIENT.ID' | translate }}</span
      ><br />
      {{ selectedClient.fiscalId }}
    </p>
    <p>
      <span class="mid-strong">{{ 'HOME.CLIENT.PHONE' | translate }}</span
      ><br />
      {{ user.cellphone }}
    </p>
    <p>
      <span class="mid-strong">{{ 'HOME.CLIENT.EMAIL' | translate }}</span
      ><br />
      {{ user.email }}
    </p>
  </div>

  <div class="user-actions">
    <!-- <button class="call-to-action px-0 my-2" id="manage-other-users">
      <svg><use xlink:href="./assets/icons/tabler-sprite.svg#tabler-users" /></svg>
      {{ 'HOME.MY_ACCOUNT.MANAGE' | translate }}
    </button> -->
    <button routerLink="/main/mi-perfil/password/editar" class="call-to-action px-0 my-2 d-flex flex-row align-items-center" id="change-password">
      <svg class="mr-8"><use xlink:href="./assets/icons/tabler-sprite.svg#tabler-lock" /></svg>
      <span>{{ 'HOME.MY_ACCOUNT.CHANGE_PASSWORD' | translate }}</span>
    </button>
    <!-- <button class="call-to-action px-0 my-2" id="edit-contact-details">
      <svg><use xlink:href="./assets/icons/tabler-sprite.svg#tabler-edit" /></svg>
      {{ 'HOME.MY_ACCOUNT.EDIT' | translate }}
    </button> -->
  </div>
</div>
