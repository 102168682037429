import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { Lock } from 'src/app/core/models/lock.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import { Locks } from '../enums/locks.enum';
import { ModalsService } from './modals.service';
import { SourceChannels } from '../enums/source-channels';

@Injectable({
  providedIn: 'root',
})
export class SelectClientService implements OnDestroy {
  private subscriptions = new Subscription();
  readonly SourceChannels = SourceChannels;
  clients: Client[];
  selectedClient: Client;
  user: UserInfo;

  constructor(
    private store: Store<{ user: UserInfo; client: Client}>,
    private modalService: ModalsService
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
        this.clients = user.clients;
      })
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
      })
    );
  }

  selectClient(client): void {
    if (!client.clientId) {
      client = this.clients.find((selectedClient) => selectedClient.clientId == client);
    }
    this.checkLocks(client);
    (this.user.origin === this.SourceChannels.KOBOSS) 
      ? this.store.dispatch(ClientActions.updateExternalClientInfo({ client })) 
      : this.store.dispatch(ClientActions.updateClient({ client })); 
  }

  checkLocks(client) {
    if (client.locks?.some(lock => lock.description === Locks.CREDIT_LOCK)){
      this.modalService.openClientOrderBlockedModal();
      this.store.dispatch(ClientActions.updateHasCreditLock({ hasCreditLock: true }));
    }
    
    if (client.locks?.some((lock: Lock)=> lock.description === Locks.MAINTENANCE)){
      this.modalService.openMaintenanceModal();
      return;
    }
    if (client.locks?.some((lock: Lock)=> lock.description === Locks.PPD_USER)) {
      this.modalService.openPPDBlockModal();
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
